export enum TextCase {
  Auto = "auto",
  Upper = "upper",
  Lower = "lower",
}

export enum CoverObjectType {
  Text = "text",
  Image = "image",
  Rectangle = "rectangle",
  Ellipse = "ellipse",
  SVG = "svg",
  Group = "group",
  Snippet = "snippet",
  MaskGroup = "mask-group",
}

export enum FillType {
  Solid = "solid",
  Gradient = "gradient",
}
