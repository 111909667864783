import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { CoverConceptualGeneration, CoverConceptualGenerationDataDto } from "../models/cover-conceptual-generation";
import { CoverConceptualGenerationStep2DataDto } from "../models/cover-conceptual-generation-2";
import { TextGenerationDataDto } from "../models/text-generation";

@Injectable({
  providedIn: "root",
})
export class TextGenerationApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  upgradeTextGeneration(data: TextGenerationDataDto) {
    return this.post<any>("text-generation/upgrade-text", data);
  }

  loadLastCoverConceptualGeneration(coverId: number) {
    return this.get<CoverConceptualGeneration | undefined>(`text-generation/cover-conceptual/${coverId}`);
  }

  coverConceptualGeneration(data: CoverConceptualGenerationDataDto) {
    return this.post("text-generation/cover-conceptual", data);
  }

  coverConceptualGenerationStep2(data: CoverConceptualGenerationStep2DataDto) {
    return this.post("text-generation/cover-conceptual-test", data);
  }
}
