import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, RoutesRecognized } from "@angular/router";
import { AuthService } from "@metranpage/auth";
import { CreateProjectType, NewProjectService } from "@metranpage/book";
import { CompanyService } from "@metranpage/company";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { AnalyticsService, LoadingService, NotificationsPopUpService, RePlainService } from "@metranpage/core";
import { COMPANY_UUID } from "@metranpage/core-api";
import { ConfigService } from "@metranpage/core-interfaces";
import { FormatService } from "@metranpage/format-data";
import { PromoMenuButtonsService } from "@metranpage/home";
import { AVAILABLE_LANGUAGES, I18nService, LangSpec } from "@metranpage/i18n";
import { PricingService } from "@metranpage/pricing";
import { PromocodeEnterResult } from "@metranpage/pricing-data";
import { ThemeService } from "@metranpage/theme";
import { ConnectTelegramService, RewardsService, UserService } from "@metranpage/user";
import { RewardsStore, User, UserApiService, UserBalance, UserRewardOneTime, UserStore } from "@metranpage/user-data";
import { UserVerificationOverlayServiceInterface } from "@metranpage/user-interfaces";
import { Observable, Subject, Subscription, filter, map, timer } from "rxjs";
import { WelcomeWebinarModalService } from "./views/welcome-modal-webinar/welcome-webinar-modal.service";
import { UserActionMessage, WelcomeModalService } from "./views/welcome-modal/welcome-modal.service";

@Component({
  selector: "m-root",
  templateUrl: "./app.page.html",
  styleUrls: ["./app.page.scss"],
  animations: [fadeInOutOnEnterLeave],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppPage implements OnInit {
  protected user?: User;
  protected userBalance?: UserBalance;
  protected shouldShowTopBar = false;

  protected isLoading = false;
  protected loadingDescription: string | undefined = "";

  protected promocodeEvent = new Subject<PromocodeEnterResult | undefined>();

  userActionMessage$!: Observable<UserActionMessage>;
  userActionMessageRead = false;

  isWebinarModalVisible = false;
  webinarModalName = "webinar-1-view";

  protected isAccountConnectTelegramModalVisible = false;

  protected rewardsOneTime: UserRewardOneTime[] = [];

  protected isSelectNewProjectTypeModalVisible = false;

  sub: Subscription = new Subscription();

  constructor(
    private readonly api: UserApiService,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly themeService: ThemeService,
    private readonly i18nService: I18nService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly rePlainService: RePlainService,
    private readonly pricingService: PricingService,
    private readonly analytics: AnalyticsService,
    @Inject(AVAILABLE_LANGUAGES) private readonly availableLanguages: LangSpec[],
    @Inject("UserVerificationOverlayService")
    protected readonly userVerificationOverlayService: UserVerificationOverlayServiceInterface,
    private readonly cdr: ChangeDetectorRef,
    readonly welcomeModalService: WelcomeModalService,
    readonly userStore: UserStore,
    readonly titleService: Title,
    readonly companyService: CompanyService,
    readonly formatService: FormatService,
    readonly connectTelegramService: ConnectTelegramService,
    private readonly notificationService: NotificationsPopUpService,
    readonly rewardsService: RewardsService,
    @Inject(COMPANY_UUID) private companyUuid: string,
    @Inject("ConfigService")
    protected readonly configService: ConfigService,
    rewardsStore: RewardsStore,
    readonly promoMenuButtonsService: PromoMenuButtonsService,
    readonly welcomeWebinarModalService: WelcomeWebinarModalService,
    readonly newProjectService: NewProjectService,
  ) {
    this.analytics.event("app_loaded");
    titleService.setTitle($localize`:@@app-name:`);
    // userService.refreshUser();

    userStore.getUserObservable().subscribe((user) => {
      if (!user) {
        return;
      }
      this.user = user;
      this.analytics.trackUser(user, companyUuid);
      formatService.loadFormats();
      rewardsService.loadRewards();
    });
    userStore.getBalanceObservable().subscribe((balance) => {
      this.userBalance = balance;
    });

    this.promocodeEvent = this.pricingService.promocodeEvent$;

    companyService.refreshCompany();

    this.userActionMessage$ = welcomeModalService.userActionMessages$.pipe(
      filter((m) => m.length > 0),
      map((m) => m[0]),
    );

    this.sub.add(
      this.connectTelegramService.onShowConnectModal$.pipe().subscribe(() => {
        const rewardsOneTime = rewardsStore.getRewardsOneTime();
        const subscribeToTelegramChannelReward = rewardsOneTime.find(
          (r) => r.reward.type === "subscribe-to-telegram-channel",
        );

        if (
          !subscribeToTelegramChannelReward ||
          this.user?.telegramProfile?.isChannelConnected ||
          subscribeToTelegramChannelReward?.isGotGift
        ) {
          return;
        }

        this.showAccountConnectTelegramModal();
      }),
    );

    this.sub.add(
      this.connectTelegramService.onSubscribeToChannel$.pipe().subscribe(() => {
        this.onSubscribeToChannelTelegramClick();
      }),
    );

    this.sub.add(
      this.connectTelegramService.onCheckSubscribeToChannel$.pipe().subscribe(() => {
        this.onCheckSubscribeToChannelTelegramClick();
      }),
    );

    this.sub.add(
      rewardsStore.getRewardsOneTimeObservable().subscribe((rewards) => {
        this.rewardsOneTime = rewards;
      }),
    );

    this.sub.add(
      welcomeWebinarModalService.webinarModalVisibility$.subscribe((isVisible) => {
        this.isWebinarModalVisible = isVisible;
      }),
    );

    this.sub.add(
      promoMenuButtonsService.webinarModalVisibility$.subscribe((isVisible) => {
        this.isWebinarModalVisible = isVisible;
      }),
    );

    this.sub.add(
      newProjectService.selectProjectTypeEvent$.pipe().subscribe(() => {
        this.showSelectNewProjectTypeModal();
      }),
    );
  }

  ngOnInit(): void {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        // if (!this.user) {
        //   // this.shouldShowTopBar = false;
        //   // return;
        // }

        const routeData = data.state.root.firstChild?.data;
        if (routeData && "showTopBar" in routeData) {
          this.shouldShowTopBar = routeData.showTopBar;
        } else {
          this.shouldShowTopBar = true;
        }

        /* const inIframe = () => window.self !== window.top;
        console.log("inIframe", inIframe());
        if (inIframe()) {
          this.shouldShowTopBar = false;
        } */
      }
    });

    this.rePlainService.replainStart();
    this.cdr.markForCheck();
  }

  async ngAfterContentInit() {
    this.loadingService.fullPageLoadingState$.pipe().subscribe((state) => {
      this.isLoading = state.isActive;
      this.loadingDescription = state.description;
      this.cdr.detectChanges();
    });
  }

  async onThemeToggle() {
    this.themeService.toggleTheme();

    const isDarkThemeEnable = this.themeService.getTheme() === "dark";
    if (this.user) {
      await this.api.updateUser({
        darkTheme: isDarkThemeEnable,
      });
    }
    this.cdr.markForCheck();
  }

  async onLanguageChange(lang: string) {
    if (this.user) {
      await this.api.updateUser({
        language: lang,
      });
    }

    this.i18nService.saveLocale(lang);
    window.location.reload();
  }

  onLogoutClick() {
    this.authService.logout();
    this.router.navigate(["/"]);

    timer(250).subscribe(() => {
      window.location.reload();
    });
  }

  async onModalActionClick(message: UserActionMessage, isActionClicked: boolean) {
    this.userActionMessageRead = true;
    if (isActionClicked) {
      if (message.navigateTo) {
        this.router.navigate([message.navigateTo], { queryParams: message.queryParams });
      }
      if (message.navigateToUrl) {
        window.open(message.navigateToUrl, "_blank");
      }
    }
    await this.userService.updateUserAction({ name: message.name, status: "complete" });
  }

  protected isLanguageSwitchAvailable() {
    return this.availableLanguages.length > 1;
  }

  protected async onPromocodeEnter(promocode: string) {
    const result = await this.pricingService.activatePromocode(promocode);
    this.promocodeEvent.next(result);
    this.analytics.event("promocode", { promocode, status: result.status, description: result.description });
    this.cdr.detectChanges();
  }

  protected onConnectTelegramClick() {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = this.connectTelegramService.connectTelegramProfile();

    this.loadingService.stopLoading();
  }

  protected onSubscribeToChannelTelegramClick() {
    this.connectTelegramService.subscribeToChannelTelegram();
  }

  protected async onCheckSubscribeToChannelTelegramClick() {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.connectTelegramService.checkSubscriptionToChannel();
    if (result.isSubscribed) {
      this.hideAccountConnectTelegramModal();
    }

    this.loadingService.stopLoading();
  }

  protected async showAccountConnectTelegramModal() {
    await this.connectTelegramService.createToken();

    this.isAccountConnectTelegramModalVisible = true;
    this.cdr.markForCheck();
  }

  protected hideAccountConnectTelegramModal() {
    this.isAccountConnectTelegramModalVisible = false;
    this.cdr.markForCheck();
  }

  protected getSubscribeToTelegramChannelReward() {
    return this.rewardsService.getSubscribeToTelegramChannelReward(this.rewardsOneTime);
  }

  async onWebinarModalActionClick(message: UserActionMessage, isActionClicked: boolean) {
    if (isActionClicked) {
      if (message.navigateTo) {
        this.router.navigate([message.navigateTo]);
      }
      if (message.navigateToUrl) {
        window.open(message.navigateToUrl, "_blank");
      }
    }
    this.isWebinarModalVisible = false;
    await this.userService.updateUserAction({ name: message.name, status: "complete" });
  }

  protected async onSelectNewProjectType(createProjectType: CreateProjectType) {
    this.closeSelectNewProjectTypeModal();
    this.newProjectService.showNewProjectModal({ projectType: createProjectType });
  }

  protected showSelectNewProjectTypeModal() {
    this.isSelectNewProjectTypeModalVisible = true;
  }

  protected closeSelectNewProjectTypeModal() {
    this.isSelectNewProjectTypeModalVisible = false;
  }
}
