import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { CompanyStore } from "@metranpage/company";
import { fadeInOutOnEnterLeave, slideInOutVertical } from "@metranpage/components";
import {
  AnalyticsService,
  IS_IMAGES_GENERATION_AVAILABLE,
  IS_PURCHASES_AVAILABLE,
  filterUndefined,
} from "@metranpage/core";
import { ConfigService, Social } from "@metranpage/core-interfaces";
import { I18nService } from "@metranpage/i18n";
import { OnboardingService } from "@metranpage/onboarding";
import { Theme, ThemeService } from "@metranpage/theme";
import { User, UserBalance } from "@metranpage/user-data";
import { Subscription, filter } from "rxjs";

@Component({
  selector: "m-app-bar-menu-mobile",
  templateUrl: "./app-bar-menu-mobile.view.html",
  styleUrls: ["./app-bar-menu-mobile.view.scss"],
  animations: [fadeInOutOnEnterLeave, slideInOutVertical],
})
export class AppBarMenuMobileView implements OnDestroy {
  @Input()
  user?: User;
  @Input()
  userBalance!: UserBalance;
  @Input()
  isLanguageSwitchAvailable = true;
  @Input()
  hideNavbarLogo = false;

  @Output()
  onThemeToggle = new EventEmitter<void>();
  @Output()
  onLanguageChange = new EventEmitter<string>();
  @Output()
  onLogoutClick = new EventEmitter<void>();
  @Output()
  onPromocodeEnter = new EventEmitter<string>();

  protected isMenuOpened = false;
  protected isOnboardingAvailable = false;
  protected isAboutGoldCreditsVisible = false;
  protected isPromocodeModalVisible = false;

  knowledgeBaseUrl?: string;
  isLogoutButtonAvailable = true;

  protected socials: Social[] = [];

  protected isDarkThemeEnable = false;
  protected language?: string;

  sub: Subscription = new Subscription();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly onboardingService: OnboardingService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    @Inject(IS_PURCHASES_AVAILABLE) protected readonly isPurchasesAvailable: boolean,
    @Inject(IS_IMAGES_GENERATION_AVAILABLE)
    protected readonly isImagesGenerationAvailable: boolean,
    private readonly analytics: AnalyticsService,
    companyStore: CompanyStore,
    @Inject("ConfigService")
    readonly configService: ConfigService,
    readonly themeService: ThemeService,
    private readonly i18nService: I18nService,
  ) {
    const routeData = this.route.snapshot.root.firstChild?.data ?? {};
    this.isOnboardingAvailable = routeData && "hasOnboarding" in routeData;

    this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe(async () => {
      const routeData = this.router.routerState.snapshot.root.firstChild!.data;
      this.isOnboardingAvailable = routeData && "hasOnboarding" in routeData;
    });

    this.sub.add(
      companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.knowledgeBaseUrl = company.knowledgeBaseUrl;
          this.isLogoutButtonAvailable = company.isLogoutButtonAvailable;
        }),
    );

    this.socials = configService.getConfig().company.socials || [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.updateTheme();
      this.updateLanguage();
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected updateTheme() {
    if (this.user) {
      this.isDarkThemeEnable = this.user.darkTheme;
      const theme: Theme = this.isDarkThemeEnable ? "dark" : "light";
      this.themeService.setTheme(theme);
    } else {
      const theme = this.themeService.getTheme();
      this.isDarkThemeEnable = theme === "dark";
    }
  }

  protected updateLanguage() {
    if (this.user) {
      this.language = this.user.language;
    } else {
      this.language = this.i18nService.getLocale();
    }
  }

  protected toggleMenu() {
    this.isMenuOpened = !this.isMenuOpened;
  }

  protected closeMenu() {
    this.isMenuOpened = false;
  }

  protected onThemeSwitchToggle() {
    this.track("burger-theme");

    this.isDarkThemeEnable = !this.isDarkThemeEnable;

    this.onThemeToggle.emit();
  }

  protected onLanguageChangeRequested(targetLang: string) {
    this.track("burger-language", { targetLang });

    this.onLanguageChange.emit(targetLang);
    this.closeMenu();
  }

  protected onLogoutButtonClick() {
    this.onLogoutClick.emit();
    this.closeMenu();
  }

  // fontSize(value: number) {
  //   const symbolsCount = value.toString().length;
  //   if (symbolsCount <= 3) {
  //     return "14px";
  //   }
  //   const size = Math.round(40 / symbolsCount);
  //   if (size > 14) {
  //     return "14px";
  //   }
  //   return `${size}px`;
  // }

  async startOnboarding(): Promise<void> {
    this.track("burger-tutorial");

    this.closeMenu();
    this.onboardingService.onStartOnboarding(true);
  }

  openKnowledgeBase(url: string) {
    this.track("burger-knowledge-base");

    this.closeMenu();
    window.open(url, "_blank");
  }

  onTokenSimpleAddClick() {
    this.closeMenu();
    this.router.navigate(["/payments/subscription"]);
  }

  onTokenGoldInfoShow() {
    this.closeMenu();
    this.isAboutGoldCreditsVisible = true;
  }

  onTokenGoldInfoClose() {
    this.isAboutGoldCreditsVisible = false;
  }

  showPromocodeModal() {
    this.track("burger-promocode");

    this.closeMenu();
    this.isPromocodeModalVisible = true;
  }

  hidePromocodeModal() {
    this.isPromocodeModalVisible = false;
  }

  protected promocodeEnter(promocode: string) {
    this.onPromocodeEnter.emit(promocode);
  }

  protected getUserBalance() {
    return this.userBalance.credits;
  }

  protected track(eventName: string, payload: any = {}) {
    this.analytics.event(eventName, payload);
  }

  @HostListener("document:click", ["$event"])
  protected onClickOutside(event: Event) {
    if (this.elementRef.nativeElement.contains(event.target)) {
      // click in component
    } else {
      if (this.isMenuOpened) {
        this.closeMenu();
      }
    }
  }

  @HostListener("window:keydown.escape")
  protected handleKeyDown() {
    this.closeMenu();
  }
}
