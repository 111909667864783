import { Expose, Type, TypeHelpOptions, TypeOptions } from "class-transformer";
import { CoverObject } from "./cover-object";
import { CoverObjectType } from "./enums";
import { MaskGroupObject } from "./mask-group-object";
import { GroupObject } from "./group-object";
import { SvgObject } from "./svg-object";
import { EllipseObject, RectangleObject } from "./shape-object";
import { ImageObject } from "./image-object";
import { TextObject } from "./text-object";

// NOTE: this is not working, if used in other files. You should manually duplicate it to groups, etc
const ObjectsTypeOptions: TypeOptions = {
  discriminator: {
    property: "__type",
    subTypes: [
      { value: TextObject, name: CoverObjectType.Text },
      { value: ImageObject, name: CoverObjectType.Image },
      { value: RectangleObject, name: CoverObjectType.Rectangle },
      { value: EllipseObject, name: CoverObjectType.Ellipse },
      { value: SvgObject, name: CoverObjectType.SVG },
      { value: GroupObject, name: CoverObjectType.Group },
      { value: MaskGroupObject, name: CoverObjectType.MaskGroup },
    ],
  },
  keepDiscriminatorProperty: true,
};


// NOTE: you cannot use this function on arrays, it will return one class for entire array
export const CoverObjectTypeFn = (opts?: TypeHelpOptions)=> {
  console.log(opts);
  if (!opts) {
    return CoverObject;
  }
  const { object } = opts;
  switch (object[opts.property].__type) {
    case CoverObjectType.Text:
      return TextObject;
    case CoverObjectType.Image:
      return ImageObject;
    case CoverObjectType.Rectangle:
      return RectangleObject;
    case CoverObjectType.Ellipse:
      return EllipseObject;
    case CoverObjectType.SVG:
      return SvgObject;
    case CoverObjectType.Group:
      return GroupObject;
    case CoverObjectType.MaskGroup:
      return MaskGroupObject;
    default:
      return CoverObject;
  }
};

export class CoverObjectContainer {
  @Type(() => CoverObject, ObjectsTypeOptions)
  // @Type(CoverObjectTypeFn)
  @Expose()
  objects!: CoverObject[];
}
