import { Expose } from "class-transformer";
import { CoverObject } from "./cover-object";
import { CoverObjectType } from "./enums";

export class ImageObject extends CoverObject {
    @Expose() imageUrl?: string;
    @Expose() fileId?: string;
    @Expose() isBackgroundRemoved?: boolean;
    @Expose() needRescale?: boolean;

    constructor() {
        super();
        this.__type = CoverObjectType.Image;
    }

    override getDefaultName(): string {
        return "Image";
    }
}

