import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { CompanyImageGenerationWatermarkSettings } from "../../models/company";

export type State = {
  watermarkSettings: CompanyImageGenerationWatermarkSettings | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyImageGenerationWatermarkSettingsStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      watermarkSettings: undefined,
    };
  }

  getWatermarkSettingsObservable() {
    return this.getFieldObservable("watermarkSettings");
  }

  setWatermarkSettings(watermarkSettings: CompanyImageGenerationWatermarkSettings) {
    this.update((state) => ({ ...state, watermarkSettings }));
  }
}
