import { Expose, Type } from "class-transformer";
import { CoverObject } from "./cover-object";
import { CoverObjectType } from "./enums";
import { TextObject } from "./text-object";
import { ImageObject } from "./image-object";
import { EllipseObject, RectangleObject } from "./shape-object";
import { SvgObject } from "./svg-object";
import { GroupAlike, GroupObject } from "./group-object";

export class MaskGroupObject extends CoverObject implements GroupAlike {
  @Type(() => CoverObject, {
    discriminator: {
      property: "__type",
      subTypes: [
        { value: TextObject, name: CoverObjectType.Text },
        { value: ImageObject, name: CoverObjectType.Image },
        { value: RectangleObject, name: CoverObjectType.Rectangle },
        { value: EllipseObject, name: CoverObjectType.Ellipse },
        { value: SvgObject, name: CoverObjectType.SVG },
        { value: GroupObject, name: CoverObjectType.Group },
        { value: MaskGroupObject, name: CoverObjectType.MaskGroup },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @Expose()
  objects!: CoverObject[];

  // @Type(() => CoverObject, {
  //   discriminator: {
  //     property: "__type",
  //     subTypes: [
  //       { value: TextObject, name: CoverObjectType.Text },
  //       { value: ImageObject, name: CoverObjectType.Image },
  //       { value: RectangleObject, name: CoverObjectType.Rectangle },
  //       { value: EllipseObject, name: CoverObjectType.Ellipse },
  //       { value: SvgObject, name: CoverObjectType.SVG },
  //       { value: GroupObject, name: CoverObjectType.Group },
  //     ],
  //   },
  //   keepDiscriminatorProperty: true,
  // })
  // @Expose()
  // mask!: CoverObject[];

  groupId?: string;

  constructor() {
    super();
    this.__type = CoverObjectType.MaskGroup;
  }

  override getDefaultName(): string {
    return "Mask";
  }
}
