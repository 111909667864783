import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { CompanyImageGenerationWatermarkSettings, ImageGenerationWatermarkSettingsDataDto } from "../../models/company";

@Injectable({
  providedIn: "root",
})
export class AdminCompanyImageGenerationWatermarkSettingsApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getImageGenerationWatermarkSettings() {
    return this.get<CompanyImageGenerationWatermarkSettings>("admin/company-image-generation-watermark-settings");
  }

  async updateImageGenerationWatermarkSettings(file: File | undefined, data: ImageGenerationWatermarkSettingsDataDto) {
    const formData: FormData = new FormData();

    if (file) {
      formData.append("file", file, file.name);
    }

    for (const [key, value] of Object.entries(data)) {
      if (key === "fontFile") {
        continue;
      }
      if (typeof value !== "string") {
        formData.append(key, JSON.stringify(value));
        continue;
      }
      formData.append(key, value);
    }

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post<CompanyImageGenerationWatermarkSettings>(
      "admin/company-image-generation-watermark-settings",
      formData,
      {
        headers,
      },
    );
  }

  getUrlForImage(settingsId: number, imagePath: string) {
    return this.absoluteUrl(`admin/company-image-generation-watermark-settings/${settingsId}/previews/${imagePath}`);
  }
}
