<div class="rowset">
  @if (isMaskSelected) {
    <m-button
      color-theme="danger"
      button-style="secondary"
      size="full-width"
      icon-position="left"
      (click)="removeMask()"
      icon="/assets/icons/cover/mask.svg"
      i18n="@@cover-editor.delete-mask"
    >
      cover-editor.delete-mask
    </m-button>
  } @else {
    <m-button
      color-theme="primary"
      button-style="secondary"
      size="full-width"
      icon-position="left"
      (click)="createMask()"
      icon="/assets/icons/cover/mask.svg"
      i18n="@@cover-editor.create-mask"
    >
      cover-editor.create-mask
    </m-button>
  }
</div>
