import { Routes } from "@metranpage/core";
import { AdminHostPage } from "./pages/admin-host.page";
import { AdminCompanyApiPage } from "./pages/api/api.page";
import { AdminCompanyPage } from "./pages/company/company.page";
import { AdminCoverSnippetsPage } from "./pages/cover-snippets/cover-snippets.page";
import { AdminCoverTemplatesPageComponent } from "./pages/cover-templates/cover-templates.page";
import { AdminCompanyEditionNoticeSettingsPage } from "./pages/edition-notice-settings/edition-notice-settings.page";
import { AdminCompanyFontPage } from "./pages/font/font.page";
import { AdminCompanyFontsPage } from "./pages/fonts/fonts.page";
import { AdminFormatPage } from "./pages/format/format.page";
import { AdminFormatsPage } from "./pages/formats/formats.page";
import { AdminCompanyImageGenerationPriceSettingsPage } from "./pages/image-generation-price-settings/image-generation-price-settings.page";
import { AdminCompanyImageGenerationWatermarkSettingsPage } from "./pages/image-generation-watermark-settings/image-generation-watermark-settings.page";
import { AdminMaintenancePage } from "./pages/maintenance/maintenance.page";
import { AdminModerationCoversPage } from "./pages/moderation-covers/moderation-covers.page";
import { AdminModerationImagesPage } from "./pages/moderation-images/moderation-images.page";
import { AdminNonEditableTemplatePage } from "./pages/non-editable-template/non-editable-template.page";
import { AdminNonEditableTemplatesPage } from "./pages/non-editable-templates/non-editable-templates.page";
import { AdminCompanyPaymentsPage } from "./pages/payments-settings/payments.page";
import { AdminCompanyPrintSettingsPage } from "./pages/print-settings/print-settings.page";
import { AdminPromocodePage } from "./pages/promocode/promocode.page";
import { AdminPromocodesPage } from "./pages/promocodes/promocodes.page";
import { AdminReferalLinkStatsPage } from "./pages/referal-link-stats/referal-link-stats.page";
import { AdminReferalLinkPage } from "./pages/referal-link/referal-link.page";
import { AdminReferalLinksPage } from "./pages/referal-links/referal-links.page";
import { AdminRewardDailyPage } from "./pages/rewards/reward-daily/reward-daily.page";
import { AdminRewardOneTimePage } from "./pages/rewards/reward-onetime/reward-onetime.page";
import { AdminRewardsDailyPage } from "./pages/rewards/rewards-daily/rewards-daily.page";
import { AdminRewardsOneTimePage } from "./pages/rewards/rewards-onetime/rewards-onetime.page";
import { AdminCompanyS3SettingsPage } from "./pages/s3-settings/s3-settings.page";
import { SocialNetworkCoversPage } from "./pages/social-network-covers/social-network-covers.page";
import { AdminTariffPage } from "./pages/tariff/tariff.page";
import { AdminTariffsPage } from "./pages/tariffs/tariffs.page";
import { AdminCompanyTelegramSettingsPage } from "./pages/telegram-settings/telegram-settings.page";
import { AdminTemplatePage } from "./pages/template/template.page";
import { AdminTemplatesPage } from "./pages/templates/templates.page";
import { AdminCompanyUnisenderSettingsPage } from "./pages/unisender-settings/unisender-settings.page";
import { AdminUsersPage } from "./pages/users/users.page";

export const adminRoutes: Routes = [
  {
    path: "admin",
    component: AdminHostPage,
    data: {
      showTopBar: true,
      showMobileWarning: true,
      breadcrumbs: {
        titleKey: "admin",
      },
      hideRePlain: true,
    },
    children: [
      {
        path: "company",
        component: AdminCompanyPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },

      {
        path: "users",
        component: AdminUsersPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },

      {
        path: "templates",
        component: AdminTemplatesPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "templates/:templateId",
        component: AdminTemplatePage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "non-editable-templates",
        component: AdminNonEditableTemplatesPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "non-editable-templates/:nonEditableTemplateId",
        component: AdminNonEditableTemplatePage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },

      {
        path: "tariffs",
        component: AdminTariffsPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "tariffs/:tariffId",
        component: AdminTariffPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },

      {
        path: "promocodes",
        component: AdminPromocodesPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "promocodes/:promocodeId",
        component: AdminPromocodePage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "social-network-covers",
        component: SocialNetworkCoversPage,
      },

      {
        path: "referal-links",
        component: AdminReferalLinksPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "referal-links/:referalLinkId",
        component: AdminReferalLinkPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "referal-links/:referalLinkId/stats",
        component: AdminReferalLinkStatsPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "fonts",
        component: AdminCompanyFontsPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "fonts/:fontId",
        component: AdminCompanyFontPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "api",
        component: AdminCompanyApiPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "s3",
        component: AdminCompanyS3SettingsPage,
      },
      {
        path: "unisender",
        component: AdminCompanyUnisenderSettingsPage,
      },
      {
        path: "payments",
        component: AdminCompanyPaymentsPage,
      },
      {
        path: "print-settings",
        component: AdminCompanyPrintSettingsPage,
      },
      {
        path: "image-generation-price",
        component: AdminCompanyImageGenerationPriceSettingsPage,
      },
      {
        path: "image-generation-watermark",
        component: AdminCompanyImageGenerationWatermarkSettingsPage,
      },
      {
        path: "edition-notice-settings",
        component: AdminCompanyEditionNoticeSettingsPage,
      },
      {
        path: "moderation/images",
        component: AdminModerationImagesPage,
      },
      {
        path: "moderation/covers",
        component: AdminModerationCoversPage,
      },

      {
        path: "formats",
        component: AdminFormatsPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "formats/:formatId",
        component: AdminFormatPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "cover-templates",
        component: AdminCoverTemplatesPageComponent,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "cover-snippets",
        component: AdminCoverSnippetsPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "telegram-settings",
        component: AdminCompanyTelegramSettingsPage,
      },
      {
        path: "rewards-onetime",
        component: AdminRewardsOneTimePage,
      },
      {
        path: "rewards-onetime/:rewardOneTimeId",
        component: AdminRewardOneTimePage,
      },
      {
        path: "rewards-daily",
        component: AdminRewardsDailyPage,
      },
      {
        path: "rewards-daily/:rewardDailyId",
        component: AdminRewardDailyPage,
      },
      {
        path: "settings-maintenance",
        component: AdminMaintenancePage,
      },
    ],
  },
];
