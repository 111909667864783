import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Router } from "@angular/router";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { AnalyticsService } from "@metranpage/core";
import { GeneratedImage, PublishedImageStore } from "@metranpage/image-generation";
import { UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";
import { HomeService } from "../../../../services/home.service";

@Component({
  selector: "m-community-image-details-actions-view",
  templateUrl: "image-details-actions.view.html",
  styleUrls: ["image-details-actions.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class CommunityImageDetailsActionsView implements OnDestroy {
  @Input()
  image!: GeneratedImage;
  @Input()
  canUnpublish = false;

  @Output()
  unpublish = new EventEmitter<void>();

  private sub: Subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly publishedImageStore: PublishedImageStore,
    private readonly userStore: UserStore,
    private readonly homeService: HomeService,
    private readonly analytics: AnalyticsService,
  ) {}

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  protected onFaceSwapPublishedImage() {
    if (!this.userStore.getUser()) {
      this.homeService.showLoginModal();
      return;
    }

    this.analytics.event("face-swap-published-image-generation");

    this.publishedImageStore.setPublishedImageForFaceSwap(this.image);
    this.router.navigateByUrl("/image-generation");
  }

  protected onCopyPublishedImageSettings() {
    if (!this.userStore.getUser()) {
      this.homeService.showLoginModal();
      return;
    }

    this.analytics.event("copy-image-generation");

    this.publishedImageStore.setPublishedImageSettings(this.image?.generationSettings);
    this.router.navigateByUrl("/image-generation");
  }

  protected onShowUnpublishImageModal() {
    this.unpublish.emit();
  }
}
