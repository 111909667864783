import { CdkDrag, CdkDragMove, CdkDragRelease, CdkDragStart, CdkDropList } from "@angular/cdk/drag-drop";
import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { CoverObject, GroupObject } from "@metranpage/book-data";

@Injectable({
  providedIn: "root",
})
export class DragDropService {
  registered: CdkDropList[] = [];

  dragStartedContainerId: string | undefined = undefined;
  currentDropContainerId: string | undefined = undefined;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  register(dropList: CdkDropList) {
    this.registered.push(dropList);
  }

  onDragStarted(event: CdkDragStart<CoverObject[]>) {
    for (const o of event.source.dropContainer.data) {
      if (o instanceof GroupObject) {
        // it should not be dropContainer data, as user can drag several different groups and we should not allow nest group into group
      }
    }
    this.dragStartedContainerId = event.source.dropContainer.id;
  }

  onDragMoved(event: CdkDragMove<CoverObject[]>) {
    const elementFromPoint = this.document.elementFromPoint(event.pointerPosition.x, event.pointerPosition.y);

    if (!elementFromPoint) {
      this.currentDropContainerId = undefined;
      return;
    }

    const dropList = elementFromPoint.classList.contains("cdk-drop-list")
      ? elementFromPoint
      : elementFromPoint.closest(".cdk-drop-list");

    if (!dropList) {
      this.currentDropContainerId = undefined;
      return;
    }

    // console.log(dropList?.id);
    this.currentDropContainerId = dropList.id;
  }

  dragReleased(_event: CdkDragRelease) {
    // this.connectedTo$.next([this.dragStartedContainerId!]);
  }

  isDropAllowed(_drag: CdkDrag, drop: CdkDropList) {
    // console.log(this.currentDropContainerId, drop.id);
    if (!this.currentDropContainerId) {
      return true;
    }

    return this.currentDropContainerId === drop.id;
  }
}
