import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { FileDropComponent, LoadingService, NotificationsPopUpService, filterUndefined } from "@metranpage/core";
import { NgxFileDropEntry } from "ngx-file-drop";
import { Subscription } from "rxjs";
import { CompanyImageGenerationWatermarkSettings, ImageGenerationWatermarkSettingsDataDto } from "../../models/company";
import { AdminCompanyImageGenerationWatermarkSettingsService } from "../../services/image-generation-watermark-settings/companies-image-generation-watermark-settings.service";
import { AdminCompanyImageGenerationWatermarkSettingsStore } from "../../services/image-generation-watermark-settings/companies-image-generation-watermark-settings.store";

const reFileExtensions = /(\.)(jpg|jpeg|jfif|pjpeg|pjp|png)$/gm;

@Component({
  selector: "m-admin-image-generation-watermark-settings-page",
  templateUrl: "./image-generation-watermark-settings.page.html",
  styleUrls: ["./image-generation-watermark-settings.page.scss"],
})
export class AdminCompanyImageGenerationWatermarkSettingsPage implements OnDestroy, OnInit {
  protected form!: FormGroup;

  @ViewChild("filedrop", { static: false })
  protected fileDropComponent!: FileDropComponent;

  protected imageFile?: File;

  protected imageUrl?: string;
  protected isPreviewHovered = false;

  private sub = new Subscription();

  constructor(
    private readonly service: AdminCompanyImageGenerationWatermarkSettingsService,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly store: AdminCompanyImageGenerationWatermarkSettingsStore,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.createForm();
  }

  async ngOnInit() {
    await this.service.loadImageGenerationWatermarkSettings();

    this.sub.add(
      this.store
        .getWatermarkSettingsObservable()
        .pipe(filterUndefined())
        .subscribe((settings) => {
          this.updateData(settings);
        }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  createForm() {
    this.form = new FormBuilder().group({
      isVisible: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      offsetBottom: new FormControl<number>(30, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      offsetRight: new FormControl<number>(30, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: File) => {
          if (!file.name.match(reFileExtensions)) {
            this.notificationService.error(
              $localize`:@@image-generation.style-reference.upload.request.error.file-extension:`,
            );
            return;
          }

          this.imageFile = file;
          this.showImagePreview(file);
          this.cdr.detectChanges();
        });
      }
    }
  }

  openFileSelector() {
    this.fileDropComponent.openFileSelector();
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.updateImageGenerationWatermarkSettings(
      this.imageFile,
      this.form.value as ImageGenerationWatermarkSettingsDataDto,
    );
    this.loadingService.stopLoading();

    if (result.status === "success") {
      // do nothing
    } else {
      // this.error.emit(result.error);
      this.notificationService.error("Cannot set settings");
      console.error("Cannot set settings");
    }
  }

  isDisabled() {
    return !this.form.valid;
  }

  protected updateData(settings: CompanyImageGenerationWatermarkSettings) {
    if (settings?.imageUrl) {
      this.imageUrl = this.service.getUrlForImage(settings.id, settings.imageUrl);
    }

    this.form.setValue({
      isVisible: settings?.isVisible || false,
      offsetBottom: settings?.offsetBottom || 30,
      offsetRight: settings?.offsetRight || 30,
    });

    this.cdr.markForCheck();
  }

  showImagePreview(file: File) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
      this.isPreviewHovered = false;
      this.cdr.detectChanges();
    };
    reader.readAsDataURL(file);
  }

  protected onDragOver(event: Event) {
    this.isPreviewHovered = true;
  }

  protected onMouseEnter(event: Event) {
    this.isPreviewHovered = true;
  }

  protected onMouseLeave(event: Event) {
    this.isPreviewHovered = false;
  }
}
