<m-modal-backdrop
  [closeButtonVisible]="closeButtonVisible"
  [closeOnBackDropClick]="closeOnBackDropClick"
  (close)="onCloseClick()"
>
  @if(mode === "select") {
  <m-ng-island [paddingSize]="0">
    <div class="tabs">
      @if(hasFaceReference) {
      <div
        class="tab"
        [ngClass]="{active: selectedTab === 1}"
        i18n="@@image-generation.character-reference.modal.tab-1"
        (click)="selectTab(1)"
      >
        image-generation.character-reference.modal.tab-1
      </div>
      }
      <!--  -->
      @if(hasCharacterReference) {
      <div
        class="tab"
        [ngClass]="{active: selectedTab === 2}"
        i18n="@@image-generation.character-reference.modal.tab-2"
        (click)="selectTab(2)"
      >
        image-generation.character-reference.modal.tab-2
      </div>
      }
    </div>

    <div class="items">
      @if(selectedTab === 1 && hasFaceReference) {
      <m-ng-scrollbars (onScrolled)="onScrolled('face')">
        <div class="cards">
          <div class="card no-background">
            <ng-container *ngTemplateOutlet="fileDropTemplate; context: { $implicit: 'face' }"></ng-container>
          </div>
          <div *ngFor="let item of faceReference; trackBy: trackById" class="card">
            <ng-container *ngTemplateOutlet="cardTemplate; context: { reference: item, type: 'face' }"></ng-container>
          </div>
        </div>
      </m-ng-scrollbars>
      }
      <!--  -->
      @if(selectedTab === 2 && hasCharacterReference) {
      <m-ng-scrollbars (onScrolled)="onScrolled('character')">
        <div class="cards">
          <div class="card no-background">
            <ng-container *ngTemplateOutlet="fileDropTemplate; context: { $implicit: 'character' }"></ng-container>
          </div>
          <div *ngFor="let item of characterReference; trackBy: trackById" class="card">
            <ng-container
              *ngTemplateOutlet="cardTemplate; context: { reference: item, type: 'character' }"
            ></ng-container>
          </div>
        </div>
      </m-ng-scrollbars>
      }
    </div>
  </m-ng-island>
  }
  <!--  -->
  @if(mode === "generation") {
  <m-ng-island [paddingSize]="0">
    <div class="container">
      <div class="title" i18n="@@image-generation.character-reference.modal.generation.title">
        image-generation.character-reference.modal.generation.title
      </div>

      <div class="divider-wrapper">
        <div class="divider-hor"></div>
      </div>

      <div class="items">
        <m-ng-scrollbars (onScrolled)="onScrolled('face')">
          <div class="cards">
            <div class="card no-background">
              <ng-container *ngTemplateOutlet="fileDropTemplate; context: { $implicit: 'face' }"></ng-container>
            </div>
            <div
              *ngFor="let item of faceReference; trackBy: trackById"
              class="card no-background"
              [ngClass]="{'active': selectedCharacterReference?.id === item.id}"
            >
              <ng-container *ngTemplateOutlet="cardTemplate; context: { reference: item, type: 'face' }"></ng-container>
            </div>
          </div>
        </m-ng-scrollbars>
      </div>

      <div class="divider-wrapper">
        <div class="divider-hor"></div>
      </div>

      <div class="footer">
        <m-info-block type="warning" [labels]="infoBlockData" [iconSize]="20" [singleIcon]="true" />

        <m-button
          button-style="primary"
          [is-disabled]="!faceSwapPrice || !selectedCharacterReference"
          (onClick)="onGenerationClick()"
        >
          <span i18n="@@image-generation.character-reference.modal.generation.face-swap-button">
            image-generation.character-reference.modal.generation.face-swap-button
          </span>
          <div class="price">
            <m-ng-icon class="token-icon" src="/assets/img/app-bar-menu/token-simple-{theme}.svg" [size]="20" />
            <span *ngIf="faceSwapPrice">{{ faceSwapPrice }}</span>
          </div>
        </m-button>
      </div>
    </div>
  </m-ng-island>
  }
</m-modal-backdrop>

<ng-template #fileDropTemplate let-type>
  <m-file-drop
    #filedrop
    accept="image/png, image/jpeg"
    [multiple]="false"
    (dropped)="dropped($event, type)"
    style="--custom-height: 100%"
  >
    <div class="file-drop-container">
      <div class="drop-placeholder" (click)="openFileSelector()">
        <m-icon src="upload-2.svg" size="original" color="--color-font-main"></m-icon>

        <div>
          <div class="description-text">
            <ng-container i18n="@@style-reference-upload-modal.upload-button.text-1">
              style-reference-upload-modal.upload-button.text-11
            </ng-container>
          </div>
          <div class="description-text">
            <ng-container i18n="@@style-reference-upload-modal.upload-button.text-2">
              style-reference-upload-modal.upload-button.text-2
            </ng-container>
            <span class="mark" i18n="@@style-reference-upload-modal.upload-button.text-2-upload">
              style-reference-upload-modal.upload-button.text-2-upload
            </span>
            <ng-container i18n="@@style-reference-upload-modal.upload-button.text-3">
              style-reference-upload-modal.upload-button.text-3
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </m-file-drop>
</ng-template>

<ng-template #cardTemplate let-reference="reference" let-type="type">
  <m-skeleton-image [url]="getImageUrl(reference)" width="200" height="200" unit="px" imgObjectFit="contain" />

  <div class="controlls-wrapper">
    <div class="controlls">
      <div class="button-wrapper">
        <m-button
          button-style="tertiary"
          size="fill"
          icon-position="left"
          icon="checkmark-02.svg"
          i18n="@@image-generation.character-reference.modal.card.select-button"
          (onClick)="selectCharacterReference(reference)"
        >
          image-generation.character-reference.modal.card.select-button
        </m-button>
      </div>

      <div class="divider"></div>

      <m-button
        button-style="tertiary"
        size="full-width"
        color-theme="danger"
        icon-position="left"
        icon="delete-02.svg"
        i18n="@@image-generation.character-reference.modal.delete-button"
        (onClick)="showConfiramtionDeleteModal(reference)"
      >
        image-generation.character-reference.modal.delete-button
      </m-button>
    </div>
  </div>

  <div *ngIf="selectedCharacterReference?.id === reference.id" class="active-selector">
    <m-ng-icon src="checkmark-01.svg" [size]="16" />
  </div>
</ng-template>

@if(characterReferencePreviewFile) {
<m-character-reference-preview-modal
  [file]="characterReferencePreviewFile"
  (onSave)="saveImageFile($event)"
  (onClose)="hideCharacterReferencePreviewModal()"
/>
}
<!--  -->
@if(characterReferenceForDelete) {
<m-confirmation-modal
  text="image-generation.character-reference.delete-confirmation"
  i18n-text="@@image-generation.character-reference.delete-confirmation"
  confirmText="dialog.delete-button"
  i18n-confirmText="@@dialog.delete-button"
  cancelText="dialog.cancel-button"
  i18n-cancelText="@@dialog.cancel-button"
  [closeButtonVisible]="true"
  (onConfirm)="deleteCharacterReference()"
  (onClose)="closeDeleteCharacterReferenceModal()"
/>
}
