import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  Book,
  CoverObjectType,
  CoverSnippet,
  CoverSnippetCategory,
  CoverSnippetCategoryObjects,
  CoverSnippetSubcategory,
  CoverSnippetSubcategoryObjects,
  PredefinedSvgObjects,
  SvgObjectCategory,
  UploadGeneratedImageRequestDto,
} from "@metranpage/book-data";
import { IS_IMAGES_GENERATION_AVAILABLE, LoadingService } from "@metranpage/core";
import { ScrollPosition } from "@metranpage/design-system";
import { SelectGeneratedImageData } from "@metranpage/image-generation";
import { CoverConceptualFormService } from "@metranpage/text-generation";
import { User } from "@metranpage/user-data";
import { NgxFileDropEntry } from "ngx-file-drop";
import { CoverSnippetDataService } from "../../services/cover-snippet/cover-snippet-data.service";
import { CoverService } from "../../services/cover/cover.service";

export type CreateCoverObject = {
  type: CoverObjectType;
  imageName?: string;
  svgData?: string;
  coverSnippet?: CoverSnippet;
};

type CoverSnippetCategoryScrollPositions = {
  category: CoverSnippetCategory;
  scrollPosition: ScrollPosition;
};

type CoverSnippetSubcategoryScrollPositions = {
  subcategory: CoverSnippetSubcategory;
  scrollPosition: ScrollPosition;
};

export type CoverSnippetScrollPositionState = {
  categoryScrollPositions: CoverSnippetCategoryScrollPositions[];
  subcategoryScrollPositions: CoverSnippetSubcategoryScrollPositions[];
};

@Component({
  selector: "m-cover-object-create",
  templateUrl: "./cover-object-create.component.html",
  styleUrls: ["./cover-object-create.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverObjectCreateComponent implements OnInit, OnChanges {
  @Input() user?: User;
  @Input() book!: Book;
  @Input() predefinedSvgObjects: PredefinedSvgObjects[] = [];
  @Input() coverSnippetCategoryObjects: CoverSnippetCategoryObjects[] = [];
  @Input() coverSnippetSidebarMaxWidth = 280;
  @Input() coverSnippetScrollPositionState?: CoverSnippetScrollPositionState;

  @Output() create = new EventEmitter<CreateCoverObject>();
  @Output() showTemplates = new EventEmitter();
  @Output() showConceptualAssistantMenu = new EventEmitter();
  @Output() scrollPositionChange = new EventEmitter<CoverSnippetScrollPositionState>();

  // TODO: delete after realise
  @Output() migrateObjectsToSnippets = new EventEmitter();
  // TODO: delete after Test concept
  @Output() generateCoverConceptTest = new EventEmitter();

  @ViewChild("fileUpload") fileUploadElement!: ElementRef;

  CoverObjectType = CoverObjectType;

  isShapeOverlayVisible = false;

  isImageGeneratorVisible = false;
  imageFileTypes = ".jpg,.jpeg,.png";

  predefinedSvgObjectsCategory: PredefinedSvgObjects | undefined = undefined;

  SvgObjectCategory = SvgObjectCategory;

  protected imageSize = {
    width: 148,
    height: 210,
  };

  protected defaultSidebarWidth = 280;
  protected coverSnippetSidebarWidth = 280;
  protected isSidebarExpanded = false;

  protected isCoverSnippetsOverlayVisible = false;
  protected selectedCoverSnippetCategory?: CoverSnippetCategory = undefined;
  protected selectedCoverSnippetCategoryObjects: CoverSnippetCategoryObjects | undefined = undefined;
  protected selectedCoverSnippetSubcategoryObjects: CoverSnippetSubcategoryObjects | undefined = undefined;

  protected scrollPosition?: ScrollPosition;
  protected categoryScrollPositions: CoverSnippetCategoryScrollPositions[] = [];
  protected subcategoryScrollPositions: CoverSnippetSubcategoryScrollPositions[] = [];

  protected formCoverConceptualStep2?: FormGroup;

  constructor(
    private readonly _coverService: CoverService,
    private readonly _loadingService: LoadingService,
    @Inject(IS_IMAGES_GENERATION_AVAILABLE)
    protected readonly isImagesGenerationAvailable: boolean,
    private readonly coverSnippetDataService: CoverSnippetDataService,
    protected readonly coverConceptualFormService: CoverConceptualFormService,
    protected readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.imageSize = {
      width: this.book.bookSettings?.width ?? 148,
      height: this.book.bookSettings?.height ?? 210,
    };

    this.restoreScrollPosition();

    this.initCoverConceptualStep2Form();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.coverSnippetSidebarMaxWidth) {
      this.updateSidebarWidth();
    }
  }

  createCoverObject(type: CoverObjectType, svgData?: any, coverSnippet?: CoverSnippet) {
    this.create.emit({ type, svgData, coverSnippet });
  }

  createCoverSnippetObject(type: CoverObjectType, coverSnippet?: CoverSnippet) {
    this.create.emit({ type, coverSnippet: coverSnippet });
  }

  async uploadImage(event: NgxFileDropEntry[]) {
    if (!event.length) {
      return;
    }
    const fileEntry = event[0].fileEntry as FileSystemFileEntry;
    fileEntry.file(async (file: File) => {
      this._loadingService.startLoading({ fullPage: true });
      const result = await this._coverService.uploadImage(this.book.id, file, "object");
      this._loadingService.stopLoading();
      this.create.emit({ type: CoverObjectType.Image, imageName: result.name });
    });
  }

  getCategoryCaption(category: SvgObjectCategory): string | undefined {
    if (category === SvgObjectCategory.BasicShapes) {
      return $localize`:@@cover-editor.object.create.object.basic-shapes.header.caption:`;
    }
    if (category === SvgObjectCategory.AgeConstraints) {
      return $localize`:@@cover-editor.object.create.object.age-constraints.header.caption:`;
    }
    if (category === SvgObjectCategory.Arrows) {
      return $localize`:@@cover-editor.object.create.object.arrows.header.caption:`;
    }
    if (category === SvgObjectCategory.Splashes) {
      return $localize`:@@cover-editor.object.create.object.splashes.header.caption:`;
    }
    return undefined;
  }

  async selectGeneratedImage(selectImageData: SelectGeneratedImageData) {
    this._loadingService.startLoading({ fullPage: true });
    this.isImageGeneratorVisible = false;
    const result = await this._coverService.uploadGeneratedObjectImage(<UploadGeneratedImageRequestDto>{
      bookId: this.book.id,
      generationId: selectImageData.generationId,
      src: selectImageData.imageUrl,
    });
    this._loadingService.stopLoading();
    this.create.emit({ type: CoverObjectType.Image, imageName: result.name });
  }

  showImageGenerator() {
    this.isImageGeneratorVisible = true;
  }

  hideImageGenerator() {
    this.isImageGeneratorVisible = false;
  }

  showTemplatesHandle() {
    this.showTemplates.emit();
  }

  onShowConceptualAssistantMenu() {
    this.showConceptualAssistantMenu.emit();
  }

  showShapesOverlay(show: boolean) {
    this.isShapeOverlayVisible = show;
  }

  // Snippets

  protected onCreateCoverObject(data: CreateCoverObject) {
    this.showCoverSnippetsSidebar(false);
    this.createCoverObject(data.type, data.svgData, data.coverSnippet);
  }

  protected onAddSnippetClick(coverSnippet: CoverSnippet) {
    this.showCoverSnippetsSidebar(false);
    this.createCoverSnippetObject(CoverObjectType.Snippet, coverSnippet);
  }

  protected toggleCoverSnippetsSidebar(category: CoverSnippetCategory) {
    if (!this.selectedCoverSnippetCategory || this.selectedCoverSnippetCategory === category) {
      this.showCoverSnippetsSidebar(!this.isCoverSnippetsOverlayVisible);
    }

    if (this.isCoverSnippetsOverlayVisible) {
      if (this.selectedCoverSnippetCategory !== category) {
        this.setCoverSnippetSubcategoryObjects(undefined);
      }

      this.selectedCoverSnippetCategory = category;
      this.selectedCoverSnippetCategoryObjects = this.coverSnippetCategoryObjects.find(
        (coverSnippetCategoryObject) => coverSnippetCategoryObject.category === category,
      );
    }

    this.updateScrollPosition();
  }

  clickOutsideCoverSnippetsSidebar(event: Event) {
    const coverObjectCreateButtonElement = (event.target as HTMLElement)?.closest("m-cover-object-create-button");
    if (coverObjectCreateButtonElement) {
      return;
    }
    this.showCoverSnippetsSidebar(false);
  }

  showCoverSnippetsSidebar(show: boolean) {
    this.isCoverSnippetsOverlayVisible = show;
    if (!this.isCoverSnippetsOverlayVisible) {
      this.selectedCoverSnippetCategory = undefined;
      this.setCoverSnippetSubcategoryObjects(undefined);
    }
  }

  protected setCoverSnippetSubcategoryObjects(
    coverSnippetSubcategoryObjects: CoverSnippetSubcategoryObjects | undefined,
  ) {
    this.selectedCoverSnippetSubcategoryObjects = coverSnippetSubcategoryObjects;
    this.isSidebarExpanded = !!this.selectedCoverSnippetSubcategoryObjects;
    this.updateSidebarWidth();

    this.updateScrollPosition();
  }

  protected updatePredefinedSvgObjectsCategory(category: PredefinedSvgObjects | undefined) {
    this.predefinedSvgObjectsCategory = category;
    this.isSidebarExpanded = !!this.predefinedSvgObjectsCategory;
    this.updateSidebarWidth();
  }

  private updateSidebarWidth() {
    let width = this.defaultSidebarWidth;
    if (this.isSidebarExpanded) {
      width = this.coverSnippetSidebarMaxWidth;
    }
    this.coverSnippetSidebarWidth = width;
  }

  getCoverSnippetCreateButtonTitle(category: CoverSnippetCategory): string {
    return this.coverSnippetDataService.getCoverSnippetCategoryTitle(category);
  }

  getCoverSnippetCreateButtonIcon(category: CoverSnippetCategory): string {
    return this.coverSnippetDataService.getCoverSnippetCategoryIcon(category);
  }

  hasCoverSnippetCreateButtonNewMark(category: CoverSnippetCategory): boolean {
    return this.coverSnippetDataService.hasCoverSnippetCategoryNewMark(category);
  }

  protected onScroll(scrollPosition: ScrollPosition) {
    this.updateCategoryScrollPositions(scrollPosition);
    this.updateSubcategoryScrollPositions(scrollPosition);

    this.scrollPositionChange.emit({
      categoryScrollPositions: this.categoryScrollPositions,
      subcategoryScrollPositions: this.subcategoryScrollPositions,
    });
  }

  protected updateCategoryScrollPositions(scrollPosition: ScrollPosition) {
    const category = this.selectedCoverSnippetCategoryObjects?.category;
    if (category && !this.selectedCoverSnippetSubcategoryObjects) {
      const categoryScrollPosition = this.categoryScrollPositions.find((sp) => sp.category === category);
      if (!categoryScrollPosition) {
        this.categoryScrollPositions.push({ category: category, scrollPosition });
      } else {
        categoryScrollPosition.scrollPosition = scrollPosition;
      }
    }
  }

  protected updateSubcategoryScrollPositions(scrollPosition: ScrollPosition) {
    const subcategory = this.selectedCoverSnippetSubcategoryObjects?.subcategory;
    if (subcategory) {
      const subcategoryScrollPosition = this.subcategoryScrollPositions.find((sp) => sp.subcategory === subcategory);
      if (!subcategoryScrollPosition) {
        this.subcategoryScrollPositions.push({ subcategory: subcategory, scrollPosition });
      } else {
        subcategoryScrollPosition.scrollPosition = scrollPosition;
      }
    }
  }

  protected restoreScrollPosition() {
    if (!this.coverSnippetScrollPositionState) {
      return;
    }

    this.categoryScrollPositions = this.coverSnippetScrollPositionState.categoryScrollPositions;
    this.subcategoryScrollPositions = this.coverSnippetScrollPositionState.subcategoryScrollPositions;
  }

  protected updateScrollPosition() {
    const category = this.selectedCoverSnippetCategory;
    const subcategory = this.selectedCoverSnippetSubcategoryObjects?.subcategory;

    if (!category && !subcategory) {
      this.scrollPosition = undefined;
      return;
    }

    if (!subcategory) {
      const category = this.selectedCoverSnippetCategoryObjects?.category;
      const categoryScrollPosition = this.categoryScrollPositions.find((sp) => sp.category === category);
      this.scrollPosition = categoryScrollPosition?.scrollPosition;
      return;
    }

    const subcategoryScrollPosition = this.subcategoryScrollPositions.find((sp) => sp.subcategory === subcategory);
    this.scrollPosition = subcategoryScrollPosition?.scrollPosition;
  }

  protected migrateSnippets() {
    this.migrateObjectsToSnippets.emit();
  }

  //Test

  protected initCoverConceptualStep2Form() {
    this.formCoverConceptualStep2 = this.coverConceptualFormService.initCoverConceptualStep2Form();
  }

  protected getGenreOptions() {
    return this.coverConceptualFormService.getGenreOptions();
  }

  protected onGenerateCoverConceptTest() {
    this.generateCoverConceptTest.emit();
  }
}
