import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BookCover } from "@metranpage/book-data";
import { UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";

@Component({
  selector: "m-community-cover-details-actions-view",
  templateUrl: "cover-details-actions.view.html",
  styleUrls: ["cover-details-actions.view.scss"],
})
export class CommunityCoverDetailsActionsView {
  @Input()
  cover!: BookCover;
  @Input()
  canUnpublish = false;

  @Output()
  unpublish = new EventEmitter<void>();

  sub: Subscription = new Subscription();

  constructor(private readonly userStore: UserStore) {}

  protected onCopyPublishedCoverSettings() {
    // this.publishedCoverStore.setPublishedCoverSettings(this.image?.generationSettings);
    // this.router.navigateByUrl("/image-generation");
  }

  protected onShowUnpublishCoverModal() {
    this.unpublish.emit();
  }
}
