@if(withIsland) {
<m-ng-island>
  <div class="container">
    <ng-container *ngTemplateOutlet="socialsTemplate"></ng-container>
  </div>
</m-ng-island>
} @else {
<ng-container *ngTemplateOutlet="socialsTemplate"></ng-container>
}

<ng-template #socialsTemplate>
  <a *ngFor="let social of socials" class="link" [href]="social.url" target="_blank" [title]="getTitle(social)">
    <m-ng-icon [src]="social.icon" [size]="28" class="icon" />
  </a>
</ng-template>
