import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MaskGroupObject } from "@metranpage/book-data";

@Component({
  selector: "m-cover-mask-settings",
  templateUrl: "./cover-mask-settings.view.html",
  styleUrls: ["./cover-mask-settings.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverMaskSettingsComponent {
  @Input() currentObject: MaskGroupObject | undefined;

  @Output() create = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  get isMaskSelected() {
    return !!this.currentObject;
  }

  createMask() {
    this.create.emit();
  }

  removeMask() {
    this.remove.emit();
  }

}
