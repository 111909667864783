<div class="wrapper" *ngIf="currentObject">
  <div class="section header-wrapper">
    <div class="caption">{{ caption }}</div>
    <m-ng-icon class="close-icon" src="cross-02.svg" [size]="20" (click)="onClose()" />
  </div>
  <div class="section main">
    <m-ng-scrollbars class="scroll-container">
      <div>
        <div class="rowset">
          <div class="caption" i18n="@@cover-editor.object.settings.alignment">
            cover-editor.object.settings.alignment
          </div>
          <m-cover-object-alignment (align)="onAlign($event)"></m-cover-object-alignment>
          <div class="border"></div>
        </div>
        <m-cover-position-object-settings
          (updatePosition)="onUpdatePosition($event)"
          [objectPosition]="objectPosition"
        ></m-cover-position-object-settings>
        <m-cover-shape-object-settings
          *ngIf="shapeObject"
          [currentObject]="shapeObject"
          [isGradientApplicable]="isGradientApplicable"
          (update)="onUpdate(shapeObject)"
        />
        <m-cover-text-object-settings
          *ngIf="textObject"
          [currentObject]="textObject"
          (update)="onUpdate(textObject)"
          (previewFontFamily)="onPreviewFontFamily($event)"
          (resetFontFamily)="onResetFontFamily()"
        />
        <m-cover-image-object-settings
          *ngIf="imageObject"
          [currentObject]="imageObject"
          [prices]="prices"
          [balance]="balance"
          (removeBackground)="onRemoveBackground($event)"
        />
        <m-cover-group-object-settings
          *ngIf="groupObject"
          [currentObject]="groupObject"
          (ungroup)="onungroupObjects()"
          (update)="onUpdate(groupObject)"
        />
        <m-cover-multiselect-settings
          *ngIf="multiselect"
          [objects]="multiselect"
          (group)="onGroupObjects()"
          (objectsUpdate)="onObjectsUpdate($event)"
        />

        <m-cover-mask-settings
          *ngIf="allowManageMask"
          [currentObject]="maskObject"
          (create)="onMaskCreated($event)"
          (remove)="onMaskRemoved($event)"
        />

        @if (user?.isAdmin) {
          <div class="rowset">
            <m-button
              button-style="primary"
              size="full-width"
              icon-position="left"
              icon="ungroup-01.svg"
              (click)="onCreateSnippet()"
              i18n="@@cover-editor.object.settings.group.button.create-snippet"
            >
              cover-editor.object.settings.group.button.create-snippet
            </m-button>
          </div>
        }
      </div>
    </m-ng-scrollbars>
  </div>
</div>
