import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { fadeInOutOnEnterLeave, slideInOutVertical } from "@metranpage/components";
import { ImageGenerationCharacterReference } from "../../models/image-generation-character-reference/image-generation-character-reference";
import { ImageGenerationCharacterReferenceService } from "../../services/image-generation-character-reference/image-generation-character-reference.service";

@Component({
  selector: "m-character-reference-button",
  templateUrl: "./character-reference-button.view.html",
  styleUrls: ["./character-reference-button.view.scss"],
  animations: [slideInOutVertical, fadeInOutOnEnterLeave],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CharacterReferenceButtonView {
  @Input()
  selectedTab = 1;
  @Input()
  advancedCharacterReference?: ImageGenerationCharacterReference;
  @Input()
  creativeCharacterReference?: ImageGenerationCharacterReference;

  @Output()
  onSelectCharacterReference = new EventEmitter();
  @Output()
  onRemoveCharacterReference = new EventEmitter();

  constructor(private readonly imageGenerationCharacterReferenceService: ImageGenerationCharacterReferenceService) {}

  protected onClick() {
    if (
      (this.selectedTab === 1 && this.advancedCharacterReference) ||
      (this.selectedTab === 2 && this.creativeCharacterReference)
    ) {
      this.removeCharacterReference();
      return;
    }
    this.selectCharacterReference();
  }

  protected selectCharacterReference() {
    this.onSelectCharacterReference.emit();
  }

  protected removeCharacterReference() {
    this.onRemoveCharacterReference.emit();
  }

  protected getCharacterReferenceImageUrl(imageGenerationCharacterReference: ImageGenerationCharacterReference) {
    return this.imageGenerationCharacterReferenceService.getUrlForImage(imageGenerationCharacterReference, true);
  }
}
