import { Expose } from "class-transformer";
import { CoverObjectType } from "./enums";
import { ObjectCreationSource, ObjectRole } from "./keys";

export abstract class CoverObject {
  @Expose() protected __type?: CoverObjectType;
  @Expose() id?: string;
  @Expose() name?: string;

  @Expose() x?: number;
  @Expose() y?: number;

  @Expose() width?: number;
  @Expose() height?: number;

  @Expose() opacity?: number;

  @Expose() zIndex = 0;

  @Expose() rotationAngle?: number;
  @Expose() scaleX?: number;
  @Expose() scaleY?: number;
  @Expose() skewX?: number;
  @Expose() skewY?: number;

  @Expose() isLocked?: boolean;
  @Expose() isVisible = true;
  @Expose() isMask = false;

  @Expose() creationSource: ObjectCreationSource = "user";
  @Expose() isNameModifiedByUser = false;

  abstract getDefaultName(): string;

  get role(): { role: ObjectRole; isMappedById: boolean } | undefined {
    if (this.id?.toLocaleLowerCase() === "title") {
      return { role: "title", isMappedById: true };
    }
    if (this.name?.toLocaleLowerCase() === "title") {
      return { role: "title", isMappedById: false };
    }
    if (this.id?.toLocaleLowerCase() === "author") {
      return { role: "author", isMappedById: true };
    }
    if (this.name?.toLocaleLowerCase() === "author") {
      return { role: "author", isMappedById: false };
    }
    if (this.id?.toLocaleLowerCase() === "subtitle") {
      return { role: "subtitle", isMappedById: true };
    }
    if (this.name?.toLocaleLowerCase() === "subtitle") {
      return { role: "subtitle", isMappedById: false };
    }
    return undefined;
  }
}
