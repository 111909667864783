import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { DataHelperService } from "@metranpage/core";
import { Canvas, FabricImage, Point, Rect, TBBox, TEvent, TPointerEvent } from "fabric";

@Component({
  selector: "m-character-reference-preview-modal",
  templateUrl: "./character-reference-preview-modal.view.html",
  styleUrls: ["./character-reference-preview-modal.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CharacterReferencePreviewModalView implements AfterViewInit {
  @Input()
  file!: File;
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;

  @Output()
  onSave = new EventEmitter<File>();
  @Output()
  onClose = new EventEmitter<void>();

  @ViewChild("canvasRef") canvasRef!: ElementRef;

  protected stage!: Canvas;

  protected cropZoneObject?: Rect;
  protected imageObject?: FabricImage;
  protected imageObjectBoundingRect?: TBBox;
  protected scale = 0.5;

  protected canvasMaxWidth = 350;
  protected canvasMaxHeight = 350;

  protected scalingProperties = {
    left: 0,
    top: 0,
    scaleX: 0,
    scaleY: 0,
  };

  constructor(
    private readonly dataHelperService: DataHelperService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngAfterViewInit() {
    this.initializeStage();
    this.addImage();
  }

  initializeStage() {
    const width = this.canvasMaxWidth;
    const height = this.canvasMaxHeight;

    this.stage = new Canvas(this.canvasRef.nativeElement, {
      width,
      height,
      uniformScaling: true,
      preserveObjectStacking: true,
      controlsAboveOverlay: true,
      stopContextMenu: true,
      selection: true,
      defaultCursor: "inherit",
    });
  }

  addImage() {
    const reader = new FileReader();
    reader.onload = (f) => {
      const url = f.target?.result as string;

      FabricImage.fromURL(url, {
        crossOrigin: "anonymous",
      }).then((image: FabricImage) => {
        if (image.width > image.height) {
          this.scale = this.canvasMaxWidth / image.width;
        } else {
          this.scale = this.canvasMaxHeight / image.height;
        }

        image.scale(this.scale);
        image.set("selectable", false);
        image.set("evented", false);

        this.imageObject = image;
        this.imageObjectBoundingRect = image.getBoundingRect();

        this.stage.add(image);

        this.stage.absolutePan(
          new Point({
            x: -(this.stage.width! - image.width! * this.scale) / 2,
            y: -(this.stage.height! - image.height! * this.scale) / 2,
          }),
        );

        this.addCropZone(image);

        this.stage.renderAll();
      });
    };
    reader.readAsDataURL(this.file);
  }

  addCropZone(image: FabricImage) {
    const rectSize = 150;

    const cropZoneObject = new Rect({
      // fill: "rgba(224, 35, 121, 0.16)",
      fill: "rgba(0, 0, 0, 0)",
      originX: "left",
      originY: "top",
      stroke: "rgba(224, 35, 121, 1)",
      strokeWidth: 1,
      opacity: 1,
      width: rectSize,
      height: rectSize,
      transparentCorners: false,
      cornerColor: "white",
      cornerStrokeColor: "rgba(224, 35, 121, 1)",
      cornerSize: 8,
      padding: 0,
      cornerStyle: "rect",
      // hasBorders: true,
      borderColor: "rgba(224, 35, 121, 1)",
      // borderDashArray: [5, 5],
      borderScaleFactor: 1,
    });

    cropZoneObject.controls.mtr.visible = false;
    cropZoneObject.controls.ml.visible = false;
    cropZoneObject.controls.mr.visible = false;
    cropZoneObject.controls.mt.visible = false;
    cropZoneObject.controls.mb.visible = false;

    this.stage.add(cropZoneObject);

    cropZoneObject.set({
      left: image.getScaledWidth() / 2 - rectSize / 2,
      top: image.getScaledHeight() / 2 - rectSize / 2,
    });
    cropZoneObject.setCoords();

    this.cropZoneObject = cropZoneObject;

    this.stage.setActiveObject(cropZoneObject);

    this.listenCropZoneObjectEvents();
  }

  listenCropZoneObjectEvents() {
    this.cropZoneObject?.on("moving", (event: TEvent<TPointerEvent>) => {
      if (!this.cropZoneObject || !this.imageObject) {
        return;
      }

      this.cropZoneObject.setCoords();

      if (
        this.cropZoneObject.getBoundingRect().top < this.imageObject.top ||
        this.cropZoneObject.getBoundingRect().left < this.imageObject.left
      ) {
        this.cropZoneObject.top = Math.max(
          this.cropZoneObject.top,
          this.cropZoneObject.top - this.cropZoneObject.getBoundingRect().top,
        );
        this.cropZoneObject.left = Math.max(
          this.cropZoneObject.left,
          this.cropZoneObject.left - this.cropZoneObject.getBoundingRect().left,
        );
      }

      if (
        this.cropZoneObject.getBoundingRect().top + this.cropZoneObject.getBoundingRect().height >
          this.imageObject.getScaledHeight() ||
        this.cropZoneObject.getBoundingRect().left + this.cropZoneObject.getBoundingRect().width >
          this.imageObject.getScaledWidth()
      ) {
        this.cropZoneObject.top = Math.min(
          this.cropZoneObject.top,
          this.imageObject.getScaledHeight() -
            this.cropZoneObject.getBoundingRect().height +
            this.cropZoneObject.top -
            this.cropZoneObject.getBoundingRect().top,
        );
        this.cropZoneObject.left = Math.min(
          this.cropZoneObject.left,
          this.imageObject.getScaledWidth() -
            this.cropZoneObject.getBoundingRect().width +
            this.cropZoneObject.left -
            this.cropZoneObject.getBoundingRect().left,
        );
      }

      this.stage.renderAll();
    });

    this.cropZoneObject?.on("scaling", (event: TEvent<TPointerEvent>) => {
      if (!this.cropZoneObject || !this.imageObject) {
        return;
      }

      const maxWidth = this.imageObject.getScaledWidth();
      const maxHeight = this.imageObject.getScaledHeight();

      if (this.cropZoneObject.left < this.imageObject.left) {
        this.cropZoneObject.left = this.scalingProperties.left;
        this.cropZoneObject.scaleX = this.scalingProperties.scaleX;
      } else {
        this.scalingProperties.left = this.cropZoneObject.left;
        this.scalingProperties.scaleX = this.cropZoneObject.scaleX;
      }

      if (this.scalingProperties.scaleX * this.cropZoneObject.width + this.cropZoneObject.left >= maxWidth) {
        this.cropZoneObject.scaleX = (maxWidth - this.scalingProperties.left) / this.cropZoneObject.width;
      } else {
        this.scalingProperties.scaleX = this.cropZoneObject.scaleX;
      }

      if (this.cropZoneObject.top < this.imageObject.top) {
        this.cropZoneObject.top = this.scalingProperties.top;
        this.cropZoneObject.scaleY = this.scalingProperties.scaleY;
      } else {
        this.scalingProperties.top = this.cropZoneObject.top;
        this.scalingProperties.scaleY = this.cropZoneObject.scaleY;
      }

      if (this.scalingProperties.scaleY * this.cropZoneObject.height + this.cropZoneObject.top >= maxHeight) {
        this.cropZoneObject.scaleY = (maxHeight - this.scalingProperties.top) / this.cropZoneObject.height;
      } else {
        this.scalingProperties.scaleY = this.cropZoneObject.scaleY;
      }

      if (this.scalingProperties.scaleX !== this.scalingProperties.scaleY) {
        const minValue = Math.min(this.scalingProperties.scaleX, this.scalingProperties.scaleY);
        this.scalingProperties.scaleX = minValue;
        this.scalingProperties.scaleY = minValue;
      }
      if (this.cropZoneObject.scaleX !== this.cropZoneObject.scaleY) {
        const minValue = Math.min(this.cropZoneObject.scaleX, this.cropZoneObject.scaleY);
        this.cropZoneObject.scaleX = minValue;
        this.cropZoneObject.scaleY = minValue;
      }

      this.stage.renderAll();
    });
  }

  cropImage() {
    if (!this.cropZoneObject || !this.imageObject) {
      return;
    }

    const viewportTransform = this.stage.viewportTransform;
    const canvasPoint = this.cropZoneObject.getXY().transform(viewportTransform);

    this.stage.remove(this.cropZoneObject);
    const base64 = this.stage.toDataURL({
      multiplier: 5,
      quality: 1,
      top: canvasPoint.y,
      left: canvasPoint.x,
      width: this.cropZoneObject.getScaledWidth(),
      height: this.cropZoneObject.getScaledHeight(),
      format: "png",
    });
    const blob = this.dataHelperService.base64ToBlob(base64, "image/png");
    const file = this.dataHelperService.blobToFile(blob, "user-character-reference.png");

    this.saveFile(file);
  }

  protected saveFile(file: File) {
    this.onSave.emit(file);
  }

  protected async onCloseClick() {
    this.onClose.emit();
  }

  protected onBackDropClick() {
    if (!this.closeOnBackDropClick) {
      return;
    }
    this.onCloseClick();
  }
}
