import { Component, HostBinding, HostListener, Input } from "@angular/core";
import { BookRouterService, EditSteps, ModalSteps } from "@metranpage/book";
import { Book } from "@metranpage/book-data";

@Component({
  selector: "m-last-project-card-edit",
  template: `
    <m-ng-icon [src]="iconSrc" [size]="28" />
    <span>{{ text }}</span>
  `,
  styles: [
    `
      :host {
        position: relative;
        display: flex;
        width: 100%;
        height: 48px;
        align-items: center;
        justify-content: flex-start;
        padding: 0 12px;
        border-radius: 10px;
        color: var(--text-text);
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-em);
        gap: 16px;
        line-height: 16.8px;
        text-align: left;
        cursor: pointer;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        m-ng-icon {
          --icon-color: var(--text-text);
        }

        &:hover {
          border-radius: 10px;
          background: var(--surface-on-island-2);
        }
      }
    `,
  ],
})
export class LastProjectCardEditView {
  @Input()
  book!: Book;
  @Input()
  text!: string;
  @Input()
  isEpub = false;
  @Input()
  step?: EditSteps | ModalSteps;
  @Input()
  iconSrc = "book-edit.svg";

  @HostBinding("class.disabled")
  @Input()
  isDisabled = false;

  constructor(private readonly bookRouterService: BookRouterService) {}

  @HostListener("click", ["$event.target"])
  onEditClick() {
    if (!this.step) {
      this.bookRouterService.navigateToLastBookEditStep(this.book);
      return;
    }
    this.bookRouterService.navigateToBookEditStep(this.book, this.step);
  }
}
