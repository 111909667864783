<m-ng-scrollbars [options]="{scrollbars: {autoHide: 'leave', autoHideDelay: 1500}}" (onScrolled)="onScrollEvent()">
  <div class="scroll-container">
    <m-promo-menu-buttons />

    <router-outlet></router-outlet>

    <m-ng-island [paddingSize]="0">
      <div class="container">
        <div class="header-group">
          <span class="title" i18n="@@home.community.title">home.community.title</span>

          <m-filter-switch
            [options]="filterOptions"
            [selectedOptionId]="selectedFilterOptionId"
            (onSelect)="onSelectFilterOption($event)"
          />

          <m-button
            *ngIf="objectType === 'covers'"
            button-style="secondary"
            icon="stars.svg"
            icon-position="left"
            (onClick)="onCreateCoverClick()"
          >
            <div class="premium-wrapper">
              <span text-left i18n="@@home.community.create-cover.button">home.community.create-cover.button</span>
              <m-premium-mark *ngIf="isPremiumLabelVisible" />
              <m-new-mark />
            </div>
          </m-button>
          <m-button
            *ngIf="objectType === 'images'"
            button-style="secondary"
            icon="stars.svg"
            icon-position="left"
            (onClick)="onGenerateImagesClick()"
          >
            <m-new-mark>
              <span text-left i18n="@@home.community.generate-images.button">
                home.community.generate-images.button
              </span>
            </m-new-mark>
          </m-button>
        </div>

        <div class="content">
          <ng-container [ngSwitch]="objectType">
            <m-published-objects-grid *ngSwitchCase="'images'" objectType="images" />
            <!-- this is intentional. If covers will have different grid - then this will be easier to replace -->
            <m-published-objects-grid *ngSwitchCase="'covers'" objectType="covers" />
          </ng-container>
        </div>
      </div>
    </m-ng-island>
  </div>
</m-ng-scrollbars>
