<div class="wrapper" *ngIf="user">
  <div class="cell-wrapper left">
    <ng-container [ngSwitch]="sidebarMode">
      <m-cover-object-create
        *ngSwitchCase="'object-create'"
        [user]="user"
        [book]="book"
        [coverSnippetCategoryObjects]="coverSnippetCategoryObjects"
        [predefinedSvgObjects]="predefinedSvgObjects"
        [coverSnippetSidebarMaxWidth]="coverSnippetSidebarMaxWidth"
        [coverSnippetScrollPositionState]="coverSnippetScrollPositionState"
        (create)="createObjectAsync($event)"
        (showTemplates)="showTemplates()"
        (showConceptualAssistantMenu)="showConceptualAssistantMenu()"
        (scrollPositionChange)="onScrollPositionChange($event)"
        (migrateObjectsToSnippets)="migrateObjectsToSnippets()"
        (generateCoverConceptTest)="generateCoverConceptTest()"
      ></m-cover-object-create>
      <m-cover-object-settings
        *ngSwitchCase="'object-settings'"
        [user]="user"
        [currentObject]="currentObject"
        [isGradientApplicable]="isGradientApplicable"
        [prices]="prices"
        [balance]="balance"
        [objectPosition]="objectPosition"
        (update)="updateObjectAsync($event)"
        (align)="alignObjects($event)"
        (close)="onObjectSettingsClose()"
        (previewFontFamily)="onPreviewFontFamily($event)"
        (resetFontFamily)="onResetFontFamily()"
        (ungroup)="ungroupObjectsAsync()"
        (removeBackground)="onRemoveBackgroundAsync($event)"
        (updatePosition)="updatePosition($event)"
        (createCoverSnippet)="onCreateCoverSnippetClick($event)"
        (maskCreated)="createMask()"
        (maskRemoved)="removeMask()"
      ></m-cover-object-settings>
      <m-cover-object-settings
        *ngSwitchCase="'multiselect-settings'"
        [user]="user"
        [currentObject]="selectedObjects"
        [objectPosition]="objectPosition"
        (align)="alignObjects($event)"
        (close)="onObjectSettingsClose()"
        (group)="groupObjectsAsync()"
        (objectsUpdate)="updateObjectsAsync($event)"
        (updatePosition)="updatePosition($event)"
        (createCoverSnippet)="onCreateCoverSnippetClick($event)"
      ></m-cover-object-settings>
      <m-cover-template-list
        *ngSwitchCase="'template-list'"
        (apply)="applyTemplateAsync($event)"
        (save)="toTemplateAsync()"
        (close)="hideTemplates()"
      ></m-cover-template-list>
      <m-cover-conceptual-assistant-generation-host
        *ngSwitchCase="'conceptual-assistant'"
        [coverConceptualGeneration]="coverConceptualGeneration"
        [cover]="cover"
        [isGenerationStarted]="isCoverConceptualGenerationStarted"
        (generateCoverConceptual)="onGenerateCoverConceptualAsync($event)"
        (createImageGeneration)="onCreateImageGeneration($event)"
        (applyFontsAndColors)="onApplyFontsAndColorsAsync($event)"
        (closeConceptualAssistant)="hideConceptualAssistant()"
      />
    </ng-container>
  </div>
  <div class="cell-wrapper center">
    <div class="canvas-wrapper">
      <div class="toolbar-wrapper left">
        <div class="block">
          <div class="button" (click)="toggleViewMode()">
            <m-icon size="s" [src]="viewModeIcon" color="fake"></m-icon>
          </div>
        </div>
      </div>
      <div class="toolbar-wrapper right">
        <div class="block">
          <div class="button" (click)="undoAsync()" [class.disabled]="!undoRedo.isUndoAvailable">
            <m-icon size="s" src="/assets/icons/undo-01.svg" color="fake"></m-icon>
          </div>
          <div class="button" (click)="redoAsync()" [class.disabled]="!undoRedo.isRedoAvailable">
            <m-icon size="s" src="/assets/icons/redo-01.svg" color="fake"></m-icon>
          </div>
        </div>
      </div>
      <div
        #wrapperRef
        class="inner-wrapper"
        [class.eyedropper]="isEyeDropperActive"
        [class.grab]="editingMode === 'pan'"
        [class.grabbing]="isPanning"
        (mouseover)="isMouseInsideCanvas = true"
        (mouseout)="isMouseInsideCanvas = false"
      >
        <canvas #canvasRef></canvas>
      </div>
    </div>
    <div class="bottom-section"></div>
  </div>
  <div class="cell-wrapper right">
    <div class="objects-wrapper">
      <m-cover-layers-section
        [objects]="objects"
        [currentObject]="currentObject"
        [selectedObjects]="selectedObjects"
        [user]="user"
        [updates]="updateCoverStates$"
        (current)="setCurrentObjects($event)"
        (reorder)="reorderObjectsAsync($event)"
        (update)="updateObjectAsync($event)"
        (objectsUpdate)="updateObjectsAsync($event)"
        (delete)="deleteObjectsAsync($event)"
        (clone)="cloneObjectAsync($event)"
      ></m-cover-layers-section>
    </div>
    <div class="bottom-section">
      <div class="footer-wrapper">
        <m-button
          button-style="secondary"
          icon="share.svg"
          icon-position="only"
          (onClick)="onShowShareCoverModalAsync()"
        ></m-button>
        <m-button
          button-style="primary"
          size="full-width"
          (click)="showCompletionModal()"
          i18n="@@cover-editor.sidebar.button.next"
        >
          cover-editor.sidebar.button.next
        </m-button>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="eyeDropperSelectedColor && editingMode === 'common' && isMouseInsideCanvas"
  class="eyedropper-color-wrapper"
  [style.backgroundColor]="eyeDropperSelectedColor.toCss()"
  [style.left.px]="eyeDropperPosition!.x"
  [style.top.px]="eyeDropperPosition!.y"
></div>

<m-cover-completion-modal
  *ngIf="isCompletionModalVisible"
  [book]="book"
  (close)="closeCompletionModal()"
  (download)="downloadCoverImage()"
></m-cover-completion-modal>

<!-- <m-cover-image-selection-modal
  *ngIf="isImageSelectionModalVisible"
  [book]="book"
  (close)="closeImageSelectionModal()"
  (create)="createObjectAsync($event); closeImageSelectionModal();"
></m-cover-image-selection-modal> -->

<m-cover-conceptual-assistant-generation-start-modal
  *ngIf="isCoverConceptualModalVisible"
  [cover]="cover"
  (generateCoverConceptual)="onGenerateCoverConceptualAsync($event)"
  (closeConceptualAssistant)="closeCoverConceptualModal()"
/>

<div class="image-generator-host" *ngIf="isImageGeneratorVisible">
  <m-image-generation-view
    [isModal]="true"
    [imageSize]="imageSize"
    (onClose)="hideImageGenerator()"
    (onSelectedImage)="selectGeneratedImageAsync($event)"
  ></m-image-generation-view>
</div>

<m-share-cover-modal
  *ngIf="isShareModalVisible && user"
  [user]="user"
  [isPublic]="cover.isPublic"
  [moderationRequest]="cover.moderationCoverRequest"
  (onPublish)="onPublishCoverClickAsync()"
  (onClose)="onCloseShareCoverModal()"
/>

<m-low-balance-modal
  *ngIf="isLowBalanceModalVisible && (tariffsForUpgrade$ | async) as tariffs"
  @fadeInOut
  [user]="user"
  [tariffs]="tariffs"
  [balanceData]="imageGenerationPaymentData"
  [activeSubscription]="activeSubscription"
  [isImageGeneration]="true"
  [imageGenerationMode]="imageGenerationMode"
  [userRewardOneTime]="getSubscribeToTelegramChannelReward()"
  (close)="closePricingModal()"
  (buySubscription)="onBuySubscription($event)"
/>

@if(isCoverSnippetPublishingModalVisible) {
<m-cover-snippet-publishing-modal (create)="createCoverSnippet($event)" (close)="closeCoverSnippetPublishingModal()" />
}
