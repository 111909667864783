import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  Color,
  CoverObject,
  ObjectsAlignment,
  TextCase,
  TextObject,
  TextboxShadowOptions,
} from "@metranpage/book-data";
import { SelectValue } from "@metranpage/components";
import * as _ from "lodash-es";
import { Observable, filter, map, tap } from "rxjs";
import { CoverFontsService } from "../../services/cover/cover-fonts.service";

@Component({
  selector: "m-cover-multiselect-settings",
  templateUrl: "./cover-multiselect-settings.component.html",
  styleUrls: ["./cover-multiselect-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverMultiselectSettingsComponent implements OnInit {
  @Input() set objects(value: CoverObject[]) {
    if (value && value.length > 0) {
      this._objects = value;
      if (this.onlyTextObjectTypes(value)) {
        this.updateFormGroupFromObject(value as TextObject[]);
        this.onlyTextObjects = true;
      }
    }
  }
  get objects() {
    return this._objects;
  }

  @Output() align = new EventEmitter<ObjectsAlignment>();
  @Output() group = new EventEmitter();
  @Output() objectsUpdate = new EventEmitter<CoverObject[]>();
  @Output() previewFontFamily = new EventEmitter<string>();
  @Output() resetFontFamily = new EventEmitter();

  fontOptions$!: Observable<SelectValue[]>;
  private _objects: CoverObject[] = [];
  onlyTextObjects = false;
  textObject!: TextObject;
  textCase = TextCase;
  withShadow = false;
  identicalShadowParams = false;
  color: Color = new Color(0, 0, 0, 0.2);
  currentObjectParams!: { [key: string]: any };
  formGroup!: FormGroup;

  constructor(
    private coverFontsService: CoverFontsService,
    private fb: FormBuilder,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.fontOptions$ = this.coverFontsService.fontsLoaded$.pipe(
      filter((fonts) => fonts.length > 0),
      map((fonts) => {
        return _.uniqBy(fonts.map((f) => <SelectValue>{ id: f.family, value: f.family }) ?? [], (v) => {
          return v.id;
        });
      }),
    );
  }

  private updatedTransparency(shadowParams: { [key: string]: number | string }): boolean {
    if (!this.currentObjectParams.shadow.transparency) {
      return true;
    }
    return this.currentObjectParams.shadow.transparency !== shadowParams.transparency;
  }

  private updateColorStringTransparency(colorString: string, transparency: string): string {
    const colorArray = this.colorArrayFromString(colorString);
    return `rgba(${colorArray[0]},${colorArray[1]},${colorArray[2]}, ${+transparency / 100})`;
  }

  private updateObjectsAndEmit(key: string, value: any): void {
    const newValue = JSON.parse(JSON.stringify(value));
    if (key === "shadow") {
      delete newValue.transparency;
    }
    this.objects.map((textObject) => {
      //@ts-ignore
      textObject[key] = newValue;
    });
    this.updateCurrentObjectParams();
    this.onObjectsUpdate(this.objects);
  }

  private updateCurrentObjectParams(): void {
    // for detect in valueChanges which params updated
    this.currentObjectParams = this.formGroup.value;
  }

  private getUpdatedParams(formValue: { [key: string]: any }): { [key: string]: any } | null {
    const updatedValues: { [key: string]: any } = {};
    for (const key in this.currentObjectParams) {
      if (JSON.stringify(this.currentObjectParams[key]) !== JSON.stringify(formValue[key])) {
        updatedValues[key] = formValue[key];
      }
    }

    return Object.keys(updatedValues).length ? updatedValues : null;
  }

  private onlyTextObjectTypes(value: CoverObject[]): boolean {
    this.onlyTextObjects = false;
    return !value.some((object) => !(object instanceof TextObject));
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      fontSize: "",
      lineHeight: "",
      letterSpacing: "",
      fontFamily: "",
      bold: false,
      italic: false,
      underline: false,
      textAlign: "",
      shadow: null,
      textCase: this.textCase.Auto,
    });

    this.formGroup.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((formValue) => {
          const updatedParams = this.getUpdatedParams(formValue);
          if (updatedParams) {
            const [key, value] = Object.entries(updatedParams)[0];
            if (key === "shadow" && value && this.currentObjectParams.shadow && this.updatedTransparency(value)) {
              this.updateCurrentObjectParams();
              return this.setShadowColor(this.updateColorStringTransparency(value.color, value.transparency));
            }

            this.updateObjectsAndEmit(key, value);
          }
        }),
      )
      .subscribe();
  }

  private updateFormGroupFromObject(textObjects: TextObject[]): void {
    if (!this.formGroup) {
      this.createForm();
    }

    Object.keys(this.formGroup.controls).map((ctrlName) => {
      const identicalParam = this.identicalParams(textObjects, ctrlName);
      // use "nonIdentical" because shadow can be null in several objects
      if (identicalParam !== "nonIdentical") {
        if (ctrlName === "shadow") {
          this.identicalShadowParams = true;
          if (identicalParam) {
            return this.toggleShadow(textObjects[0].shadow!);
          }
          return;
        }

        this.formGroup.controls[ctrlName].setValue(identicalParam);
      }
      if (ctrlName === "shadow") {
        this.withShadow = false;
        this.identicalShadowParams = false;
      }
    });

    this.updateCurrentObjectParams();
  }

  private identicalParams(objects: TextObject[], param: string): any {
    const verificationValue = objects[0][param as keyof TextObject];

    const nonIdentical = objects.some((object) => {
      if (verificationValue instanceof Object) {
        const res = JSON.stringify(object[param as keyof TextObject]) !== JSON.stringify(verificationValue);
        return res;
      }
      return object[param as keyof TextObject] !== verificationValue;
    });

    return nonIdentical ? "nonIdentical" : verificationValue;
  }

  private addShadowForm(textShadow?: TextboxShadowOptions): FormGroup {
    return this.fb.group({
      blur: textShadow?.blur || "20",
      offset: textShadow?.offset || "20",
      color: Color.toCss(this.color),
      direction: textShadow?.direction || "40",
      transparency: this.getTransparency(textShadow?.color) || "10",
    });
  }

  private getTransparency(colorString?: string): number {
    if (!colorString) {
      return 20;
    }

    const colorArray = this.colorArrayFromString(colorString);
    return +colorArray[colorArray.length - 1] * 100;
  }

  private setShadowColor(colorString: string): void {
    this.setShadowColorToPicker(colorString);
    this.updateShadowColorCtrl(this.color);
  }

  onAlign(alignment: ObjectsAlignment) {
    this.align.emit(alignment);
  }

  onGroupObjects() {
    this.group.emit();
  }

  onObjectsUpdate(objects: CoverObject[]) {
    this.objectsUpdate.emit(objects);
  }

  onPreviewFontFamily(fontFamily: string) {
    this.previewFontFamily.emit(fontFamily);
  }

  onResetFontFamily() {
    this.resetFontFamily.emit();
  }

  toggleParam(param: string, value?: string): void {
    const control = this.formGroup.controls[param];

    if (value) {
      control.setValue(value);
      return;
    }

    if (control) {
      control.setValue(!control.value);
    }
  }

  private setShadowColorToPicker(colorString: string): void {
    const colorArray = this.colorArrayFromString(colorString);
    this.color = new Color(+colorArray[0], +colorArray[1], +colorArray[2], +colorArray[3]);
  }

  private colorArrayFromString(colorString: string): string[] {
    return colorString.replace(/[a-z()]/g, "").split(",");
  }

  toggleShadow(textShadow?: TextboxShadowOptions): void {
    if (this.withShadow) {
      this.withShadow = false;
      this.formGroup.removeControl("shadow", { emitEvent: false });
      this.formGroup.addControl("shadow", this.fb.control(null));
      return;
    }

    this.formGroup.removeControl("shadow", { emitEvent: false });
    this.formGroup.addControl("shadow", this.addShadowForm(textShadow));
    if (textShadow?.color) {
      this.setShadowColor(textShadow.color);
    }

    this.withShadow = true;
  }

  updateShadowColorCtrl(color: Color) {
    color.r = Math.round(color.r);
    color.g = Math.round(color.g);
    color.b = Math.round(color.b);
    const newColorCss = Color.toCss(color);
    (this.formGroup.controls["shadow"] as FormGroup).controls["color"].setValue(newColorCss);
  }
}
