import { Component, HostBinding, Input } from "@angular/core";
import { Book } from "@metranpage/book-data";
import { BookRouterService, EditSteps, ModalSteps } from "../../services/book-router.service";

@Component({
  selector: "m-book-card-edit",
  template: `
    <div class="wrapper" (click)="onEditClick()">
      <m-ng-icon [src]="iconSrc" [size]="28" />
      <span>{{ text }}</span>
    </div>
  `,
  styles: [
    `
      .wrapper {        
        position: relative;
        display: flex;
        width: 100%;
        height: 48px;
        align-items: center;
        justify-content: flex-start;
        padding: 0 12px;
        border-radius: 10px;
        color: var(--text-text);
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-em);
        gap: 16px;
        line-height: 16.8px;
        text-align: left;
        cursor: pointer;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        m-ng-icon {
          --icon-color: var(--text-text);
        }

        &:hover {
          border-radius: 10px;
          background: var(--surface-on-island-2);
        }      
      }
    `,
  ],
})
export class BookCardEditView {
  @Input()
  book!: Book;
  @Input()
  text!: string;
  @Input()
  isEpub = false;
  @Input()
  step?: EditSteps | ModalSteps;
  @Input()
  iconSrc = "book-edit.svg";

  @HostBinding("class.disabled")
  @Input()
  isDisabled = false;

  constructor(private readonly bookRouterService: BookRouterService) {}

  async onEditClick() {
    if (!this.step) {
      this.bookRouterService.navigateToLastBookEditStep(this.book);
      return;
    }
    this.bookRouterService.navigateToBookEditStep(this.book, this.step);
  }
}
