import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SelectValue } from "@metranpage/components";

@Injectable({
  providedIn: "root",
})
export class CoverConceptualFormService {
  protected formCoverConceptualStep2!: FormGroup;

  initCoverConceptualStep2Form() {
    this.createCoverConceptualStep2Form();
    return this.getCoverConceptualStep2Form();
  }

  createCoverConceptualStep2Form() {
    this.formCoverConceptualStep2 = new FormGroup(
      {
        genre: new FormControl("", [Validators.required]),
      },
      // [genreValidator()],
    );
  }

  getCoverConceptualStep2Form() {
    return this.formCoverConceptualStep2;
  }

  getCoverConceptualStep2FormData() {
    return this.formCoverConceptualStep2.getRawValue();
  }

  getGenreOptions() {
    return this.genreOptions;
  }

  protected genreOptions: SelectValue[] = [
    {
      id: "",
      value: $localize`:@@cover-editor.conceptual-assistant-step-2.genre.without-genre:`,
    },
    {
      id: "fantastic",
      value: $localize`:@@cover-editor.conceptual-assistant-step-2.genre.fantastic:`,
    },
    {
      id: "detective",
      value: $localize`:@@cover-editor.conceptual-assistant-step-2.genre.detective:`,
    },
    {
      id: "horror",
      value: $localize`:@@cover-editor.conceptual-assistant-step-2.genre.horror:`,
    },
    {
      id: "comedy",
      value: $localize`:@@cover-editor.conceptual-assistant-step-2.genre.comedy:`,
    },
    {
      id: "tragedy",
      value: $localize`:@@cover-editor.conceptual-assistant-step-2.genre.tragedy:`,
    },
    {
      id: "romance",
      value: $localize`:@@cover-editor.conceptual-assistant-step-2.genre.romance:`,
    },
    {
      id: "adventure",
      value: $localize`:@@cover-editor.conceptual-assistant-step-2.genre.adventure:`,
    },
    {
      id: "mysticism",
      value: $localize`:@@cover-editor.conceptual-assistant-step-2.genre.mysticism:`,
    },
    {
      id: "historical-prose",
      value: $localize`:@@cover-editor.conceptual-assistant-step-2.genre.historical-prose:`,
    },
    {
      id: "social-prose",
      value: $localize`:@@cover-editor.conceptual-assistant-step-2.genre.social-prose:`,
    },
    {
      id: "fantasy",
      value: $localize`:@@cover-editor.conceptual-assistant-step-2.genre.fantasy:`,
    },
    {
      id: "drama",
      value: $localize`:@@cover-editor.conceptual-assistant-step-2.genre.drama:`,
    },
  ];
}
