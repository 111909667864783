<m-modal
  [paddingSize]="0"
  [closeButtonVisible]="closeButtonVisible"
  [closeOnBackDropClick]="closeOnBackDropClick"
  (close)="onCloseClick()"
>
  <div class="container">
    <div class="title" i18n="@@image-generation.character-reference.preview-modal.title">
      image-generation.character-reference.preview-modal.title
    </div>

    <div class="canvas-wrapper">
      <canvas class="canvas" #canvasRef></canvas>
    </div>

    <m-button
      button-style="primary"
      icon-position="left"
      icon="checkmark-02.svg"
      i18n="@@image-generation.character-reference.preview-modal.save-button"
      (onClick)="cropImage()"
    >
      image-generation.character-reference.preview-modal.save-button
    </m-button>
  </div>
</m-modal>
