import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { GeneratedImage } from "../models/generated-image";
import { ImageGeneration } from "../models/image-generation";

export type PublishedImagesState = {
  publishedImages: GeneratedImage[];
  publishedImagesPageCount: number;
  publishedImageSettings?: ImageGeneration;
  publishedImageForFaceSwap?: GeneratedImage;
};

@Injectable({
  providedIn: "root",
})
export class PublishedImageStore extends Store<PublishedImagesState> {
  protected override getInitialState(): PublishedImagesState {
    return {
      publishedImages: [],
      publishedImagesPageCount: 1,
      publishedImageSettings: undefined,
      publishedImageForFaceSwap: undefined,
    };
  }

  getPublishedImagesObservable() {
    return this.getFieldObservable("publishedImages");
  }

  getPublishedImages() {
    return this.getField("publishedImages");
  }

  getPublishedImagesPageCountObservable() {
    return this.getFieldObservable("publishedImagesPageCount");
  }

  getPublishedImageSettingsObservable() {
    return this.getFieldObservable("publishedImageSettings");
  }

  setPublishedImages(publishedImages: GeneratedImage[]) {
    this.update((state) => ({ ...state, publishedImages }));
  }

  setPublishedImagesPageCount(count: number) {
    this.update((state) => ({ ...state, publishedImagesPageCount: count }));
  }

  setPublishedImageSettings(publishedImageSettings: ImageGeneration | undefined) {
    this.update((state) => ({ ...state, publishedImageSettings }));
  }

  addPublishedImage(publishedImage: GeneratedImage) {
    this.update((state) => {
      const storePublishedImage = state.publishedImages.find((ig) => ig.id === publishedImage.id);
      if (storePublishedImage) {
        return state;
      }

      return {
        ...state,
        publishedImages: [publishedImage].concat(state.publishedImages),
      };
    });
  }

  addPublishedImagesToStart(publishedImages: GeneratedImage[]) {
    this.update((state) => {
      const newPublishedImages: GeneratedImage[] = [];
      for (const publishedImage of publishedImages) {
        const storePublishedImage = state.publishedImages.find((ig) => ig.id === publishedImage.id);
        if (storePublishedImage) {
          continue;
        }
        newPublishedImages.push(publishedImage);
      }

      return {
        ...state,
        publishedImages: newPublishedImages.concat(state.publishedImages),
      };
    });
  }

  addPublishedImagesToEnd(publishedImages: GeneratedImage[]) {
    this.update((state) => {
      const newPublishedImages: GeneratedImage[] = [];
      for (const publishedImage of publishedImages) {
        const storeImageGeneration = state.publishedImages.find((ig) => ig.id === publishedImage.id);
        if (storeImageGeneration) {
          continue;
        }
        newPublishedImages.push(publishedImage);
      }

      return {
        ...state,
        publishedImages: state.publishedImages.concat(newPublishedImages),
      };
    });
  }

  updatePublishedImage(updatedPublishedImage: GeneratedImage) {
    const publishedImages = this.getPublishedImages();
    const publishedImage = publishedImages.find((pi) => pi.id === updatedPublishedImage.id);
    if (publishedImage) {
      this.update((state) => {
        const updatedPublishedImages = state.publishedImages.map((g) => {
          if (g.id === updatedPublishedImage.id) {
            return updatedPublishedImage;
          }
          return g;
        });

        return {
          ...state,
          publishedImages: updatedPublishedImages,
        };
      });
    } else {
      this.addPublishedImage(updatedPublishedImage);
    }
  }

  updatePublishedImageOnLike(id: number, mode: "like" | "unlike") {
    const publishedImages = this.getPublishedImages();
    const publishedImage = publishedImages.find((pi) => pi.id === id);
    if (!publishedImage) {
      return;
    }

    if (mode === "like") {
      publishedImage.isLiked = true;
      publishedImage.likesCount = publishedImage.likesCount + 1;
    }

    if (mode === "unlike") {
      publishedImage.isLiked = false;
      publishedImage.likesCount = publishedImage.likesCount - 1;
    }
  }

  getPublishedImageForFaceSwapObservable() {
    return this.getFieldObservable("publishedImageForFaceSwap");
  }

  setPublishedImageForFaceSwap(publishedImageForFaceSwap: GeneratedImage | undefined) {
    this.update((state) => ({ ...state, publishedImageForFaceSwap }));
  }
}
