import { CommonModule } from "@angular/common";
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import { ColumnComponent, ColumnData } from "./column.component";
import { Sizeable } from "./sizeable";

type DistrubuteOpts = {
  clearPrevious: boolean;
  addNew: boolean;
};

@Component({
  selector: "m-c-image-grid",
  templateUrl: "./cooler-image-grid.component.html",
  styleUrls: ["./cooler-image-grid.component.scss"],
  standalone: true,
  imports: [CommonModule, ColumnComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageGridComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() items: Sizeable[] = [];
  @Input() baseColumnWidth = 250;
  @Input() baseColumnWidthMobile = 150;
  @Input() needClearPreviousOnChange = false;
  @ContentChild(TemplateRef) itemTemplate!: TemplateRef<any>;

  protected minWidth = 768;

  protected columns: ColumnData[] = [];
  private lastAddedItemIndex = 0;
  private wasInited = false;
  private boundResizeListener!: () => void;

  constructor(
    private readonly element: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.wasInited = true;

    // delay initial column calculation, as grid container is not ready yet and needs to be sized properly in DOM
    setTimeout(() => {
      this.distributeItems({ clearPrevious: true, addNew: true });
    }, 0);

    // bind onResize to `this` context
    this.boundResizeListener = this.onResize.bind(this);
    window.addEventListener("resize", this.boundResizeListener);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.wasInited) {
      return;
    }
    if (changes.items) {
      if (this.needClearPreviousOnChange) {
        this.distributeItems({ clearPrevious: true, addNew: false });
      } else {
        if (changes.items.currentValue.length !== changes.items.previousValue?.length) {
          this.distributeItems({ clearPrevious: false, addNew: true });
        } else {
          // TODO we need to pass changes to columns somehow
          this.cdr.detectChanges();
        }
      }
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener("resize", this.boundResizeListener);
  }

  distributeItems(opts: DistrubuteOpts) {
    if (this.items.length === 0) {
      return;
    }

    if (opts.clearPrevious || this.columns.length === 0) {
      this.columns = [];

      const el = this.element.nativeElement as HTMLElement;
      const containerWidth = el.offsetWidth;

      let columnsCount = Math.max(1, Math.floor(containerWidth / this.baseColumnWidth));
      if (window.innerWidth < this.minWidth) {
        columnsCount = Math.max(1, Math.floor(containerWidth / this.baseColumnWidthMobile));
      }
      for (let i = 0; i < columnsCount; i++) {
        this.columns.push({
          items: [],
          totalHeight: 0,
        });
      }
    }

    let i = 0;
    if (opts.addNew) {
      i = this.lastAddedItemIndex;
    }

    let currentColumn = 0;
    while (i < this.items.length) {
      const heights = this.columns.map((c) => c.totalHeight);
      const min = Math.min(...heights);
      currentColumn = heights.indexOf(min);

      const item = this.items[i];
      const column = this.columns[currentColumn];
      column.items.push(item);
      const scale = this.baseColumnWidth / item.width;
      column.totalHeight += item.height * scale;

      i++;
      this.lastAddedItemIndex = i;
    }

    this.cdr.detectChanges();
  }

  // we cant use host listener, as it will trigger cdr on every change
  // @HostListener("window:resize")
  onResize() {
    const el = this.element.nativeElement as HTMLElement;
    const containerWidth = el.offsetWidth;
    let newColumnsCount = Math.max(1, Math.floor(containerWidth / this.baseColumnWidth));
    if (window.innerWidth < this.minWidth) {
      newColumnsCount = Math.max(1, Math.floor(containerWidth / this.baseColumnWidthMobile));
    }
    if (this.columns.length !== newColumnsCount) {
      this.distributeItems({ clearPrevious: true, addNew: false });
    }
  }
}
