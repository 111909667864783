import { Expose, Type } from "class-transformer";
import { CoverObject } from "./cover-object";
import { Fill, GradientFill, SolidFill } from "./color";
import { CoverObjectType, FillType } from "./enums";

export abstract class ShapeObject extends CoverObject {
  @Type(() => Fill, {
    discriminator: {
      property: "__type",
      subTypes: [
        { value: SolidFill, name: FillType.Solid },
        { value: GradientFill, name: FillType.Gradient },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @Expose()
  fill!: Fill;

  @Type(() => Fill, {
    discriminator: {
      property: "__type",
      subTypes: [
        { value: SolidFill, name: FillType.Solid },
        { value: GradientFill, name: FillType.Gradient },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @Expose()
  strokeFill?: Fill;

  @Expose() strokeWidth?: number;
}

export class RectangleObject extends ShapeObject {
  @Expose() cornerRadius?: number;
  constructor() {
    super();
    this.__type = CoverObjectType.Rectangle;
  }

  override getDefaultName(): string {
    return "Rectangle";
  }
}

export class EllipseObject extends ShapeObject {
  constructor() {
    super();
    this.__type = CoverObjectType.Ellipse;
  }

  override getDefaultName(): string {
    return "Ellipse";
  }
}
