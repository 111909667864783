import { Injectable } from "@angular/core";
import { filterUndefined } from "@metranpage/core";
import { GeneratedImage, GeneratedImageService, PublishedImageStore } from "@metranpage/image-generation";
import { User, UserStore } from "@metranpage/user-data";
import { Observable } from "rxjs";
import { HomeService } from "../../services/home.service";
import { PublishedObjectsDataSource } from "./data-source";

@Injectable({
  providedIn: "root",
})
export class ImagesDataSource extends PublishedObjectsDataSource<GeneratedImage> {
  private items$: Observable<GeneratedImage[]> | undefined;

  constructor(
    private readonly userStore: UserStore,
    private readonly generatedImageService: GeneratedImageService,
    private readonly publishedImageStore: PublishedImageStore,
    private readonly homeService: HomeService,
  ) {
    super("images");
  }

  initItems() {
    if (!this.items$) {
      this.items$ = this.publishedImageStore.getPublishedImagesObservable().pipe(filterUndefined());

      this.publishedImageStore.getPublishedImagesPageCountObservable().subscribe((pageCount) => {
        this.pageCount = pageCount;
      });

      this.page = 1;

      this.loadPublishedImagesPaginated();
    }
  }

  override loadNextPage() {
    if (!this.userStore.getUser() && this.page >= 2) {
      this.homeService.showLoginModal();
      return;
    }

    this.page++;
    if (this.page > this.pageCount) {
      this.page = this.pageCount;
      return;
    }
    this.loadPublishedImagesPaginated();
  }

  override getItems(): Observable<GeneratedImage[]> {
    this.initItems();
    return this.publishedImageStore.getPublishedImagesObservable();
  }

  override getObject(id: number): GeneratedImage | undefined {
    return this.publishedImageStore.getPublishedImages().find((ig) => ig.id === id);
  }

  override getImageUrlForObject(id: number, isThumbnail: boolean): string | undefined {
    const generatedImage = this.publishedImageStore.getPublishedImages().find((ig) => ig.id === id);
    if (!generatedImage) {
      return "";
    }
    return this.generatedImageService.getUrlForImage(generatedImage, isThumbnail);
  }

  override getAuthorForObject(id: number): User | undefined {
    const generatedImage = this.publishedImageStore.getPublishedImages().find((ig) => ig.id === id);
    if (!generatedImage) {
      return undefined;
    }
    return generatedImage.author as User;
  }

  protected loadPublishedImagesPaginated() {
    const user = this.userStore.getUser();
    if (user) {
      this.generatedImageService.loadPublishedImagesPaginated(this.page);
    } else {
      this.generatedImageService.loadPublishedImagesPaginatedUnauthorized(this.page);
    }
  }

  override like(id: number) {
    this.generatedImageService.likeGeneratedImage(id);
  }

  override unlike(id: number) {
    this.generatedImageService.unlikeGeneratedImage(id);
  }

  override canUnpublish(userId: number | undefined) {
    return (
      this.userStore.isUserAdmin() ||
      (this.userStore.isUserHasPaidSubscription() && this.userStore.getUser()?.id === userId)
    );
  }
}
