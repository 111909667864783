<m-modal-backdrop
  class="modal-backdrop"
  [closeButtonVisible]="closeButtonVisible"
  [closeOnBackDropClick]="closeOnBackDropClick"
  (close)="onCloseClick()"
>
  <div class="container">
    <div class="arrow-zone">
      <div *ngIf="previousObject" class="arrow-button-previous" (click)="onPreviousClick()">
        <m-ng-icon src="button-arrow-right.svg" [size]="24" />
      </div>
    </div>

    <m-ng-island class="image-container">
      <m-community-image-view *ngIf="image" [image]="image" />
      <m-community-cover-view *ngIf="cover" [cover]="cover" />
    </m-ng-island>

    <m-ng-island class="details-container">
      <m-community-image-details-view
        *ngIf="image"
        [image]="image"
        [canUnpublish]="canImageUnpublish"
        (onUnpublish)="onUnpublishImage(image)"
        (onLike)="onLikeImageClick(image, $event)"
      />
      <m-community-cover-details-view
        *ngIf="cover"
        [cover]="cover"
        [canUnpublish]="canCoverUnpublish"
        (onUnpublish)="onUnpublishCover(cover)"
        (onLike)="onLikeCoverClick(cover, $event)"
      />
    </m-ng-island>

    <div class="arrow-zone">
      <div *ngIf="nextObject" class="arrow-button-next" (click)="onNextClick()">
        <m-ng-icon src="button-arrow-right.svg" [size]="24" />
      </div>
    </div>
    <m-loading-spinner #spinner *ngIf="isLoading" @fadeInOut />
  </div>
</m-modal-backdrop>

<m-modal-backdrop
  class="modal-backdrop-mobile"
  [closeButtonVisible]="false"
  [closeOnBackDropClick]="false"
  (close)="onCloseClick()"
>
  <div class="container-wrapper-mobile">
    <div class="close-button" (click)="onCloseClick()">
      <m-ng-icon class="burger-icon" src="burger-menu-opened-icon.svg" [size]="24" />
      <span i18n="@@app-bar.menu-mobile.close-button">app-bar.menu-mobile.close-button</span>
    </div>

    <m-ng-scrollbars [options]="{scrollbars: {autoHide: 'leave', autoHideDelay: 1500}}">
      <div class="container-mobile">
        <m-ng-island class="image-container-mobile">
          <m-community-image-view *ngIf="image" [image]="image" />
          <m-community-cover-view *ngIf="cover" [cover]="cover" />
        </m-ng-island>

        @if(image) {
        <m-ng-island class="details-container-mobile">
          <m-community-image-details-actions-view
            [image]="image"
            [canUnpublish]="canImageUnpublish"
            (unpublish)="onUnpublishImage(image)"
          />
        </m-ng-island>
        }
        <!--  -->
        @if(cover && canCoverUnpublish) {
        <m-ng-island class="details-container-mobile">
          <m-community-cover-details-actions-view
            [cover]="cover"
            [canUnpublish]="canCoverUnpublish"
            (unpublish)="onUnpublishCover(cover)"
          />
        </m-ng-island>
        }

        <div class="arrows-mobile-wrapper">
          <div *ngIf="previousObject" class="arrow-mobile-button-previous" (click)="onPreviousClick()">
            <m-ng-icon src="button-arrow-right.svg" [size]="24" />
          </div>

          <div *ngIf="nextObject" class="arrow-mobile-button-next" (click)="onNextClick()">
            <m-ng-icon src="button-arrow-right.svg" [size]="24" />
          </div>
        </div>

        <m-ng-island class="details-container-mobile">
          <m-community-image-details-view
            *ngIf="image"
            [image]="image"
            [hasActions]="false"
            (onUnpublish)="onUnpublishImage(image)"
            (onLike)="onLikeImageClick(image, $event)"
          />
          <m-community-cover-details-view
            *ngIf="cover"
            [cover]="cover"
            [hasActions]="false"
            (onUnpublish)="onUnpublishCover(cover)"
            (onLike)="onLikeCoverClick(cover, $event)"
          />
        </m-ng-island>

        <div class="spacer"></div>

        <m-loading-spinner #spinner *ngIf="isLoading" @fadeInOut />
      </div>
    </m-ng-scrollbars>
  </div>
</m-modal-backdrop>

<m-modal
  *ngIf="isUnpublishModalVisible"
  [closeButtonVisible]="true"
  [closeOnBackDropClick]="true"
  (close)="onUnpublishModalClose()"
>
  <div class="modal-container">
    <div class="modal-content">
      <span i18n="@@home.community.unpublish-modal">home.community.unpublish-modal</span>
    </div>

    <div class="modal-buttons">
      <m-button
        button-style="secondary"
        size="full-width"
        (onClick)="onUnpublishModalClose()"
        i18n="@@dialog.cancel-button"
      >
        dialog.cancel-button
      </m-button>
      <m-button button-style="primary" (onClick)="onUnpublishModalConfirm()" i18n="@@dialog.yes-button">
        dialog.yes-button
      </m-button>
    </div>
  </div>
</m-modal>
