import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { ImageGenerationCharacterReference } from "../../models/image-generation-character-reference/image-generation-character-reference";

export type ImageGenerationCharacterReferenceState = {
  faceReference: ImageGenerationCharacterReference[];
  faceReferencePageCount: number;
  characterReference: ImageGenerationCharacterReference[];
  characterReferencePageCount: number;
};

@Injectable({
  providedIn: "root",
})
export class ImageGenerationCharacterReferenceStore extends Store<ImageGenerationCharacterReferenceState> {
  protected override getInitialState(): ImageGenerationCharacterReferenceState {
    return {
      faceReference: [],
      faceReferencePageCount: 1,
      characterReference: [],
      characterReferencePageCount: 1,
    };
  }

  getFaceReferenceObservable() {
    return this.getFieldObservable("faceReference");
  }

  getFaceReference() {
    return this.getField("faceReference");
  }

  setFaceReference(faceReference: ImageGenerationCharacterReference[]) {
    this.update((state) => ({ ...state, faceReference }));
  }

  getFaceReferencePageCountObservable() {
    return this.getFieldObservable("faceReferencePageCount");
  }

  setFaceReferencePageCount(count: number) {
    this.update((state) => ({ ...state, faceReferencePageCount: count }));
  }

  getCharacterReferenceObservable() {
    return this.getFieldObservable("characterReference");
  }

  getCharacterReference() {
    return this.getField("characterReference");
  }

  setCharacterReference(characterReference: ImageGenerationCharacterReference[]) {
    this.update((state) => ({ ...state, characterReference }));
  }

  getCharacterReferencePageCountObservable() {
    return this.getFieldObservable("characterReferencePageCount");
  }

  setCharacterReferencePageCount(count: number) {
    this.update((state) => ({ ...state, characterReferencePageCount: count }));
  }

  addCharacterReference(characterReference: ImageGenerationCharacterReference) {
    if (characterReference.type === "face") {
      this.update((state) => {
        const storeCharacterReference = state.faceReference.find((fr) => fr.id === characterReference.id);
        if (storeCharacterReference) {
          return state;
        }

        return {
          ...state,
          faceReference: [characterReference].concat(state.faceReference),
        };
      });
    }

    if (characterReference.type === "character") {
      this.update((state) => {
        const storeCharacterReference = state.characterReference.find((cr) => cr.id === characterReference.id);
        if (storeCharacterReference) {
          return state;
        }

        return {
          ...state,
          characterReference: [characterReference].concat(state.characterReference),
        };
      });
    }
  }

  addCharacterReferenceToStart(items: ImageGenerationCharacterReference[]) {
    this.update((state) => {
      const faceReferences = items.filter((i) => i.type === "face");
      const newFaceReference: ImageGenerationCharacterReference[] = [];
      for (const faceReference of faceReferences) {
        const storeFaceReference = state.faceReference.find((fr) => fr.id === faceReference.id);
        if (storeFaceReference) {
          continue;
        }
        newFaceReference.push(faceReference);
      }

      const characterReferences = items.filter((i) => i.type === "character");
      const newCharacterReference: ImageGenerationCharacterReference[] = [];
      for (const characterReference of characterReferences) {
        const storeCharacterReference = state.characterReference.find((cr) => cr.id === characterReference.id);
        if (storeCharacterReference) {
          continue;
        }
        newCharacterReference.push(characterReference);
      }

      return {
        ...state,
        faceReference: newFaceReference.concat(state.faceReference),
        characterReference: newCharacterReference.concat(state.characterReference),
      };
    });
  }

  addCharacterReferenceToEnd(items: ImageGenerationCharacterReference[]) {
    this.update((state) => {
      const faceReferences = items.filter((i) => i.type === "face");
      const newFaceReference: ImageGenerationCharacterReference[] = [];
      for (const faceReference of faceReferences) {
        const storeFaceReference = state.faceReference.find((fr) => fr.id === faceReference.id);
        if (storeFaceReference) {
          continue;
        }
        newFaceReference.push(faceReference);
      }

      const characterReferences = items.filter((i) => i.type === "character");
      const newCharacterReference: ImageGenerationCharacterReference[] = [];
      for (const characterReference of characterReferences) {
        const storeCharacterReference = state.characterReference.find((cr) => cr.id === characterReference.id);
        if (storeCharacterReference) {
          continue;
        }
        newCharacterReference.push(characterReference);
      }

      return {
        ...state,
        faceReference: state.faceReference.concat(newFaceReference),
        characterReference: state.characterReference.concat(newCharacterReference),
      };
    });
  }

  updateCharacterReference(updatedCharacterReference: ImageGenerationCharacterReference) {
    let items: ImageGenerationCharacterReference[] = [];
    if (updatedCharacterReference.type === "face") {
      items = this.getFaceReference();
    }
    if (updatedCharacterReference.type === "character") {
      items = this.getCharacterReference();
    }
    const storeCharacterReference = items.find((i) => i.id === updatedCharacterReference.id);
    if (storeCharacterReference) {
      this.update((state) => {
        if (updatedCharacterReference.type === "face") {
          const updatedFaceReferences = state.faceReference.map((fr) => {
            if (fr.id === updatedCharacterReference.id) {
              return updatedCharacterReference;
            }
            return fr;
          });

          return {
            ...state,
            faceReference: updatedFaceReferences,
          };
        }

        if (updatedCharacterReference.type === "character") {
          const updatedCharacterReferences = state.characterReference.map((cr) => {
            if (cr.id === updatedCharacterReference.id) {
              return updatedCharacterReference;
            }
            return cr;
          });

          return {
            ...state,
            characterReference: updatedCharacterReferences,
          };
        }

        return state;
      });
    } else {
      this.addCharacterReference(updatedCharacterReference);
    }
  }
}
