import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import {
  CompanyImageGenerationWatermarkSettingsUpdateData,
  ImageGenerationWatermarkSettingsDataDto,
} from "../../models/company";
import { AdminCompanyImageGenerationWatermarkSettingsApi } from "./companies-image-generation-watermark-settings.api";
import { AdminCompanyImageGenerationWatermarkSettingsStore } from "./companies-image-generation-watermark-settings.store";

export type UpdateCompanySettingsResult = {
  status: GeneralResultStatus;
  error?: unknown;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyImageGenerationWatermarkSettingsService {
  constructor(
    private readonly api: AdminCompanyImageGenerationWatermarkSettingsApi,
    private readonly store: AdminCompanyImageGenerationWatermarkSettingsStore,
  ) {}

  async getImageGenerationWatermarkSettings() {
    return await this.api.getImageGenerationWatermarkSettings();
  }

  async loadImageGenerationWatermarkSettings() {
    const settings = await this.api.getImageGenerationWatermarkSettings();
    this.store.setWatermarkSettings(settings);
  }

  async updateImageGenerationWatermarkSettings(
    file: File | undefined,
    data: ImageGenerationWatermarkSettingsDataDto,
  ): Promise<UpdateCompanySettingsResult> {
    try {
      const settings = await this.api.updateImageGenerationWatermarkSettings(file, data);
      this.store.setWatermarkSettings(settings);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }

  getUrlForImage(settingsId: number, imagePath: string) {
    return this.api.getUrlForImage(settingsId, imagePath);
  }
}
