import { Expose, Type } from "class-transformer";
import { CoverObject } from "./cover-object";
import { CoverObjectType } from "./enums";
import { TextObject } from "./text-object";
import { ImageObject } from "./image-object";
import { EllipseObject, RectangleObject } from "./shape-object";
import { SvgObject } from "./svg-object";
import { MaskGroupObject } from "./mask-group-object";

export type GroupAlike = {
  groupId?: string;
  objects: CoverObject[];
}

export class GroupObject extends CoverObject implements GroupAlike {
  @Type(() => CoverObject, {
    discriminator: {
      property: "__type",
      subTypes: [
        { value: TextObject, name: CoverObjectType.Text },
        { value: ImageObject, name: CoverObjectType.Image },
        { value: RectangleObject, name: CoverObjectType.Rectangle },
        { value: EllipseObject, name: CoverObjectType.Ellipse },
        { value: SvgObject, name: CoverObjectType.SVG },
        { value: GroupObject, name: CoverObjectType.Group },
        { value: MaskGroupObject, name: CoverObjectType.MaskGroup },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @Expose()
  objects!: CoverObject[];

  groupId?: string;

  constructor() {
    super();
    this.__type = CoverObjectType.Group;
  }

  override getDefaultName(): string {
    return "Group";
  }
}
