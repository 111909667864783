import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { CoverObject, GroupObject, ImageObject, MaskGroupObject, ShapeObject, TextObject } from "@metranpage/book-data";
import { CoverUiService } from "../../services/cover/cover-ui.service";
import { User } from "@metranpage/user-data";
import { Observable } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "m-cover-object-list-item",
  templateUrl: "./cover-object-list-item.component.html",
  styleUrls: ["./cover-object-list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverObjectListItemComponent {
  @Input() object!: CoverObject;

  @Input() isSelected = false;

  @Input() user!: User;

  @Input() level!: number;

  @Input() updates!: Observable<void>;

  @Output() select = new EventEmitter<CoverObject>();

  protected isEditable = false;
  protected layerName = "";

  constructor(
    private readonly coverUiService: CoverUiService,
    private readonly destroyRef: DestroyRef,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngAfterViewInit(): void {
    this.layerName = this.object.name || "";

    this.updates.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  get objectTypeIconPath(): string {
    if (this.object.isMask) {
      return "/assets/icons/mask-01.svg";
    }
    if (this.object instanceof TextObject) {
      return "/assets/icons/text-01.svg";
    }
    if (this.object instanceof ImageObject) {
      return "/assets/icons/image-04.svg";
    }
    if (this.object instanceof ShapeObject) {
      return "/assets/icons/shape-01.svg";
    }
    if (this.object instanceof GroupObject) {
      return "/assets/icons/group-01.svg";
    }
    if (this.object instanceof MaskGroupObject) {
      return "/assets/icons/mask-01.svg";
    }
    return "/assets/icons/text-01.svg";
  }

  get visibilityIcon(): string {
    if (this.object.isVisible) {
      return "/assets/icons/eye.svg";
    }
    return "/assets/icons/eye-close.svg";
  }

  get lockIcon(): string {
    return `/assets/icons/${this.object.isLocked ? "locked-01.svg" : "unlocked-01.svg"}`;
  }

  get groupObject(): GroupObject | undefined {
    return this.object instanceof GroupObject ? this.object : undefined;
  }

  ngOnChanges(_changes: SimpleChanges): void { }

  setCurrent(object: CoverObject) {
    this.select.emit(object);
  }

  onObjectClick() {
    this.setCurrent(this.object);
  }

  validateObjectName(name: string | undefined | null): boolean {
    const value = name?.trim();
    if (value?.length) {
      return true;
    }
    return false;
  }

  enableEdit(event: MouseEvent) {
    this.isEditable = true;
    // Focus the element for immediate editing
    if (event.target && event.target instanceof HTMLElement) {
      const t = event.target as HTMLElement;
      setTimeout(() => this.moveCursorToClickEvent(t, event), 0);
    }
  }

  onNameChange() {
    // if (this.validateObjectName(this.object.name)) {
    //   this.coverUiService.renameObject(this.object);
    // }
  }

  onNameBlur() {
    this.isEditable = false;
    if (this.validateObjectName(this.object.name)) {
      this.object.name = this.layerName;
    } else {
      this.object.name = this.object.getDefaultName();
    }
    this.coverUiService.renameObject(this.object);
  }

  onNameEnter(event: Event) {
    event.preventDefault(); // Prevent default behavior for `Enter`

    this.onNameBlur();
  }

  onNameFocus() {
    this.select.emit(this.object);
  }

  moveCursorToClickEvent(element: HTMLElement, event: MouseEvent) {
    element.focus();

    const range = document.createRange();
    const selection = window.getSelection();
    const rect = element.getBoundingClientRect();

    // Calculate the click offset
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // Find the text node and position in the contenteditable element
    let node = element.firstChild;
    let offset = 0;

    if (node) {
      while (node && node.nodeType === Node.TEXT_NODE) {
        const rangeForNode = document.createRange();
        rangeForNode.selectNodeContents(node);

        const boundingBox = rangeForNode.getBoundingClientRect();

        if (x < boundingBox.right && y < boundingBox.bottom) {
          offset = Math.floor((x / boundingBox.width) * node.textContent!.length);
          break;
        }

        node = node.nextSibling;
      }
    }

    // Set the caret position if node is found
    if (node) {
      range.setStart(node, offset);
      range.collapse(true);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  }
}
