<!-- <m-button -->
<!--   button-style="primary" -->
<!--   size="full-width" -->
<!--   [is-disabled]="!cover" -->
<!--   (onClick)="onCopyPublishedCoverSettings()" -->
<!--   i18n="@@published-image.settings.use-current-settings" -->
<!-- > -->
<!--   published-image.settings.use-current-settings -->
<!-- </m-button> -->

<m-button
  *ngIf="canUnpublish"
  button-style="secondary"
  icon="book-delete.svg"
  icon-position="only"
  (onClick)="onShowUnpublishCoverModal()"
/>
