<div class="character-reference-wrapper" (click)="onClick()">
  @if ((selectedTab === 1 && !advancedCharacterReference) || (selectedTab === 2 && !creativeCharacterReference)) {
  <m-ng-icon src="character-reference.svg" [size]="28" />
  <div class="dot"></div>
  }
  <!--  -->
  @if (selectedTab === 1 && advancedCharacterReference) {
  <ng-container
    *ngTemplateOutlet="previewTemplate; context: { characterReference: advancedCharacterReference }"
  ></ng-container>
  }
  <!--  -->
  @if (selectedTab === 2 && creativeCharacterReference) {
  <ng-container
    *ngTemplateOutlet="previewTemplate; context: { characterReference: creativeCharacterReference }"
  ></ng-container>
  }
</div>

<ng-template #previewTemplate let-characterReference="characterReference">
  <div class="preview-wrapper">
    <m-skeleton-image
      [url]="getCharacterReferenceImageUrl(characterReference)"
      width="48"
      height="48"
      unit="px"
      imgObjectFit="contain"
    />
    <div class="controls">
      <m-ng-icon src="close-cross.svg" [size]="20" />
    </div>
  </div>
</ng-template>
