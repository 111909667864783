import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CoverObject} from "@metranpage/book-data";
import { User } from "@metranpage/user-data";
import { Observable, filter, map, startWith, tap } from "rxjs";
import { CoverUiService } from "../../services/cover/cover-ui.service";

@Component({
  selector: "m-cover-layers-section",
  templateUrl: "./cover-layers-section.component.html",
  styleUrls: ["./cover-layers-section.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverLayersSectionComponent implements OnChanges, OnInit {
  @Input() objects!: CoverObject[];
  @Input() currentObject?: CoverObject;
  @Input() selectedObjects: CoverObject[] = [];
  @Input() user!: User;
  @Input() updates!: Observable<void>;

  @Output() current = new EventEmitter<CoverObject[]>();
  @Output() reorder = new EventEmitter<CoverObject[]>();
  @Output() update = new EventEmitter<CoverObject>();
  @Output() objectsUpdate = new EventEmitter<CoverObject[]>();
  @Output() delete = new EventEmitter<CoverObject[]>();
  @Output() clone = new EventEmitter<CoverObject>();

  values$!: Observable<Partial<CoverObject>>;

  groupVisibility = true;

  readonly form = new FormGroup({
    opacity: new FormControl<number | undefined>(undefined, [
      Validators.pattern("^[0-9.]*$"),
      Validators.min(0),
      Validators.max(100),
    ]),
  });

  constructor(
    private readonly coverUiService: CoverUiService,
    private readonly destroyRef: DestroyRef,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.values$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      filter(() => this.form.valid),
      map((v) => <Partial<CoverObject>>v),
      tap((v) => {
        if (!this.form.dirty || !this.form.valid || !this.currentObject) {
          return;
        }
        Object.assign(this.currentObject, v);
        this.update.emit(this.currentObject);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentObject) {
      this.form.reset();
      if (this.currentObject) {
        const patchValue = {
          opacity: this.currentObject.opacity ?? 100,
        };
        this.form.patchValue(patchValue);
      }
    }
  }

  reorderObjects(objects: CoverObject[]) {
    this.reorder.emit(objects);
  }

  get visibilityIcon(): string {
    if (this.currentObject!.isVisible) {
      return "/assets/icons/eye.svg";
    }
    return "/assets/icons/eye-close.svg";
  }

  get lockIcon(): string {
    return `/assets/icons/${this.currentObject?.isLocked ? "locked-01.svg" : "unlocked-01.svg"}`;
  }

  onCurrent(objects: CoverObject[]) {
    this.current.emit(objects);
  }

  deleteObject(object: CoverObject) {
    this.delete.emit([object]);
  }

  toggleLockObject(object: CoverObject) {
    this.currentObject!.isLocked = !(this.currentObject?.isLocked ?? false);
    this.update.emit(object);
  }

  toggleObjectVisibility(object: CoverObject) {
    this.currentObject!.isVisible = !(this.currentObject?.isVisible ?? true);
    this.update.emit(object);
  }

  cloneObject(object: CoverObject) {
    this.clone.emit(object);
  }

  toggleObjectsVisibility() {
    this.groupVisibility = !this.groupVisibility;
    this.selectedObjects.map((selectedObject) => {
      selectedObject.isVisible = this.groupVisibility;
    });
    this.objectsUpdate.emit(this.selectedObjects);
  }

  deleteObjects() {
    this.delete.emit(this.selectedObjects);
  }
}
