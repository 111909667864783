<div
  id="parent"
  cdkDropList
  [cdkDropListData]="objects"
  [cdkDropListConnectedTo]="dragDropService.registered"
  [cdkDropListEnterPredicate]="allowDropPredicate"
  (cdkDropListDropped)="reorderCoverObjects($event)"
>

  <ng-container *ngFor="let object of reversedObjects">
    @if (isGroup(object)) {
    <m-cover-object-list-item-group
      cdkDrag
      [object]="castGroup(object)"
      [selectedObjects]="selectedObjects"
      [user]="user"
      [level]="1"
      [isGroupSelected]="isSelected(object)"
      [updates]="updates"
      (cdkDragMoved)="onDragMoved($event)"
      (reorder)="reorderCoverObjects($event)"
      (select)="onObjectSelected($event)"
    />
    } @else if (isMask(object)) {
    <m-cover-object-list-item-mask
      cdkDrag
      [object]="castMask(object)"
      [selectedObjects]="selectedObjects"
      [user]="user"
      [level]="1"
      [isMaskSelected]="isSelected(object)"
      [updates]="updates"
      (cdkDragMoved)="onDragMoved($event)"
      (reorder)="reorderCoverObjects($event)"
      (select)="onObjectSelected($event)"
    />
    } @else {
    <m-cover-object-list-item
      cdkDrag
      [object]="object"
      [user]="user"
      [level]="1"
      [isSelected]="isSelected(object)"
      [updates]="updates"
      (select)="onObjectSelected($event)"
      (cdkDragStarted)="onDragStarted($event)"
      (cdkDragMoved)="onDragMoved($event)"
      (cdkDragReleased)="onDragReleased($event)"
    />
    }
    <!-- <m-cover-object-list-item -->
    <!--   [object]="object" -->
    <!--   [isGroupItem]="isGroup" -->
    <!--   [listIds]="listIds" -->
    <!--   [isSelected]="isSelected(object)" -->
    <!--   [isCurrent]="currentObject === object" -->
    <!--   [user]="user" -->
    <!--   (select)="onObjectSelected($event)" -->
    <!-- > -->
    <!-- </m-cover-object-list-item> -->
  </ng-container>

</div>
