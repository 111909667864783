import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { slideInOutVertical } from "@metranpage/components";
import { User } from "@metranpage/user-data";
import { Character } from "../../models/character/character";
import { DownloadGeneratedImageData, GeneratedImage, SelectGeneratedImageData } from "../../models/generated-image";
import {
  ImageGeneration,
  ImageGenerationAdvancedStyle,
  ImageGenerationBasicStyle,
  ImageGenerationCreativeStyle,
} from "../../models/image-generation";
import { ImageGenerationDataService } from "../../services/image-generation-data.service";
import { ImageGenerationService } from "../../services/image-generation.service";

export type PreviewImageData = { imageGeneration: ImageGeneration; generatedImage: GeneratedImage };

@Component({
  selector: "m-generation-result",
  templateUrl: "./generation-result.view.html",
  styleUrls: ["./generation-result.view.scss"],
  animations: [slideInOutVertical],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerationResultView implements OnInit {
  @Input()
  user?: User;
  @Input()
  imageGeneration!: ImageGeneration;
  @Input()
  advancedStyle?: ImageGenerationAdvancedStyle;
  @Input()
  creativeStyle?: ImageGenerationCreativeStyle;
  @Input()
  showDivider = true;
  @Input()
  isModal = false;
  @Input()
  characters: Character[] = [];
  @Input()
  hasPremium = true;

  @Output()
  onPreviewImage = new EventEmitter<PreviewImageData>();
  @Output()
  onSelectImage = new EventEmitter<SelectGeneratedImageData>();
  @Output()
  onRepeatImageGeneration = new EventEmitter<ImageGeneration>();
  @Output()
  onDeleteImageGeneration = new EventEmitter<ImageGeneration>();
  @Output()
  onDownnloadImage = new EventEmitter<DownloadGeneratedImageData>();
  @Output()
  onDeleteImage = new EventEmitter<GeneratedImage>();
  @Output()
  onUpscaleImage = new EventEmitter<GeneratedImage>();
  @Output()
  onUnzoomImage = new EventEmitter<GeneratedImage>();
  @Output()
  onEditImageGeneration = new EventEmitter<ImageGeneration>();

  protected isCollapsed = false;

  protected styleTitle = "";

  constructor(
    private readonly imageGenerationService: ImageGenerationService,
    private readonly imageGenerationDataService: ImageGenerationDataService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isCollapsed = this.imageGeneration.status === "error";
    this.updateStyleTitle();
  }

  protected toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  protected onDeleteGeneratedImageClick(generatedImage: GeneratedImage) {
    this.onDeleteImage.emit(generatedImage);
  }

  protected onRepeatImageGenerationClick() {
    this.onRepeatImageGeneration.emit(this.imageGeneration);
  }

  protected onDeleteImageGenerationClick() {
    this.onDeleteImageGeneration.emit(this.imageGeneration);
  }

  protected onSelectImageClick(data: SelectGeneratedImageData) {
    this.onSelectImage.emit(data);
  }

  protected onDownloadImageClick(downloadGeneratedImageData: DownloadGeneratedImageData) {
    this.onDownnloadImage.emit(downloadGeneratedImageData);
  }

  protected onDeleteImageClick(generatedImage: GeneratedImage) {
    this.onDeleteImage.emit(generatedImage);
  }

  protected onUpscaleImageClick(generatedImage: GeneratedImage) {
    this.onUpscaleImage.emit(generatedImage);
  }

  protected onUnzoomImageClick(generatedImage: GeneratedImage) {
    this.onUnzoomImage.emit(generatedImage);
  }

  protected onShowImagePreviewClick(generatedImage: GeneratedImage) {
    this.onPreviewImage.emit({
      imageGeneration: this.imageGeneration,
      generatedImage,
    });
  }

  protected getGeneratedImages(imageGeneration: ImageGeneration) {
    return this.imageGenerationService.orderGeneratedImages(imageGeneration.generatedImages);
  }

  protected onEditImageGenerationClick() {
    this.onEditImageGeneration.emit(this.imageGeneration);
  }

  protected isEditable(): boolean {
    return (
      (this.imageGeneration.generationMode === "advanced" || this.imageGeneration.generationMode === "creative") &&
      this.imageGeneration.styleId >= 0
    );
  }

  protected getDetalizationTitle() {
    if (!this.imageGeneration.detalization) {
      return "";
    }
    return this.imageGenerationDataService.getDetalizationTitle(this.imageGeneration.detalization);
  }

  protected getMoodTitle() {
    if (!this.imageGeneration.mood) {
      return "";
    }
    return this.imageGenerationDataService.getMoodTitle(this.imageGeneration.mood);
  }

  protected getAdvancedGenerationModeTitle() {
    if (this.imageGeneration.generationMode !== "advanced" || !this.imageGeneration.advancedGenerationMode) {
      return "";
    }
    return this.imageGenerationDataService.getAdvancedGenerationModeTitle(this.imageGeneration.advancedGenerationMode);
  }

  protected updateStyleTitle() {
    if (this.imageGeneration.userStyleId) {
      this.styleTitle = $localize`:@@image-generation.selected-style.user-style-reference:`;
      return;
    }
    if (this.advancedStyle) {
      this.styleTitle = this.advancedStyle.title;
      // return;
    }
    if (this.creativeStyle) {
      this.styleTitle = this.creativeStyle.title;
      // return;
    }
    this.styleTitle = this.imageGenerationService.clearStyleTitle(this.styleTitle);
  }
}
