import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AuthModule } from "@metranpage/auth";
import { BookModule } from "@metranpage/book";
import { CoreModule } from "@metranpage/core";
import { ImageGenerationModule } from "@metranpage/image-generation";
import { PricingModule } from "@metranpage/pricing";
import { HomePage } from "./pages/home/home.page";
import { CommunityDetailView } from "./views/community/community-detail-view/community-detail.view";
import { CommunityCoverDetailsActionsView } from "./views/community/community-detail-view/cover/cover-details-actions.view";
import { CommunityCoverDetailsView } from "./views/community/community-detail-view/cover/cover-details.view";
import { CommunityCoverView } from "./views/community/community-detail-view/cover/cover.view";
import { CommunityImageDetailsActionsView } from "./views/community/community-detail-view/image/image-details-actions.view";
import { CommunityImageDetailsView } from "./views/community/community-detail-view/image/image-details.view";
import { CommunityImageView } from "./views/community/community-detail-view/image/image.view";
import { CommunityGridAndDetailView } from "./views/community/community-grid-and-detail/community-grid-and-detail.view";
import { PublishedObjectPreview } from "./views/community/published-object-preview/published-object-preview.view";
import { PublishedObjectsGridView } from "./views/community/published-objects-grid/published-objects-grid.view";
import { HomeMenuView } from "./views/home-menu/home-menu.view";
import { LastProjectCardEditView } from "./views/last-project/last-project-card-edit.view";
import { LastProjectEditMenuView } from "./views/last-project/last-project-edit-menu.view";
import { LastProjectView } from "./views/last-project/last-project.view";
import { LoginModalView } from "./views/login-modal/login-modal.view";
import { LoginSidebarMenuView } from "./views/login-sidebar-menu/login-sidebar-menu.view";
import { PromoMenuButtonsView } from "./views/promo-menu-buttons/promo-menu-buttons.view";
import { SocialsView } from "./views/socials/socials.view";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    NgOptimizedImage,
    RouterModule,
    BookModule,
    ImageGenerationModule,
    AuthModule,
    PricingModule,
  ],
  providers: [],
  declarations: [
    // components

    // views
    LastProjectView,
    LastProjectCardEditView,
    LastProjectEditMenuView,
    SocialsView,
    HomeMenuView,
    PublishedObjectsGridView,
    PublishedObjectPreview,
    CommunityGridAndDetailView,
    CommunityDetailView,
    CommunityImageView,
    CommunityImageDetailsView,
    CommunityImageDetailsActionsView,
    CommunityCoverView,
    CommunityCoverDetailsView,
    CommunityCoverDetailsActionsView,
    PromoMenuButtonsView,
    LoginSidebarMenuView,
    LoginModalView,

    // pages
    HomePage,
  ],
  exports: [SocialsView],
})
export class HomeModule {}
