<div class="wrapper">
  <div class="section main">
    <m-ng-scrollbars>

      <m-cover-object-list
        [objects]="objects"
        [user]="user"
        [currentObject]="currentObject"
        [selectedObjects]="selectedObjects"
        [updates]="updates"
        (reorder)="reorderObjects($event)"
        (current)="onCurrent($event)"
      />
    </m-ng-scrollbars>
  </div>

  <div class="section" *ngIf="currentObject">
    <div class="layer-controls-wrapper">
      <div class="block opacity">
        <form [formGroup]="form" *ngIf="values$ | async as values">
          <m-ng-input-number-with-icon
            iconLeft="opacity-01.svg"
            formControlName="opacity"
            size="32"
            [iconSize]="24"
            text-align="right"
          />
        </form>
      </div>
      <div class="block">
        <div class="button">
          <m-ng-icon [src]="visibilityIcon" [size]="24" (click)="toggleObjectVisibility(currentObject)"></m-ng-icon>
        </div>
        <div class="button">
          <m-ng-icon [src]="lockIcon" [size]="24" (click)="toggleLockObject(currentObject)"></m-ng-icon>
        </div>
        <div class="button">
          <m-ng-icon src="/assets/icons/clone-01.svg" [size]="24" (click)="cloneObject(currentObject)"></m-ng-icon>
        </div>
        <div class="button delete" *ngIf="!currentObject.isMask">
          <m-ng-icon src="/assets/icons/delete-01.svg" [size]="24" (click)="deleteObject(currentObject)"></m-ng-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="section" *ngIf="selectedObjects.length > 1">
    <div class="layer-controls-wrapper">
      <div class="block">
        <div class="button">
          <m-ng-icon
            [src]="groupVisibility ? '/assets/icons/eye.svg' : '/assets/icons/eye-close.svg'"
            [size]="24"
            (click)="toggleObjectsVisibility()"
          ></m-ng-icon>
        </div>

        <div class="button delete">
          <m-ng-icon src="/assets/icons/delete-01.svg" [size]="24" (click)="deleteObjects()"></m-ng-icon>
        </div>
      </div>
    </div>
  </div>
</div>
