import { Injectable } from "@angular/core";
import { LoadingService, RealtimeService, filterUndefined } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { Subject, filter } from "rxjs";
import { CoverConceptualGenerationDataDto } from "../models/cover-conceptual-generation";
import {
  CoverConceptualGenerationStep2,
  CoverConceptualGenerationStep2DataDto,
} from "../models/cover-conceptual-generation-2";
import {
  CoverConceptualGeneration2ResultUpdate,
  CoverConceptualGenerationChanges,
  CoverConceptualGenerationResultUpdate,
} from "../models/cover-conceptual-generation-update";
import { TextGenerationDataDto } from "../models/text-generation";
import { CoverConceptualGenerationStore } from "./cover-conceptual-generation.store";
import { TextGenerationApi } from "./text-generation.api";

@Injectable({
  providedIn: "root",
})
export class TextGenerationService {
  coverConceptualGenerationStep2Updates$ = new Subject<CoverConceptualGenerationStep2>();

  constructor(
    private readonly textGenerationApi: TextGenerationApi,
    private readonly coverConceptualGenerationStore: CoverConceptualGenerationStore,
    private readonly notificationService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    realtimeService: RealtimeService,
  ) {
    realtimeService
      .getEvents<CoverConceptualGenerationChanges>("changes")
      .pipe(
        filterUndefined(),
        filter((x) => x?.type === "cover-conceptual-generation-result"),
      )
      .subscribe((data: CoverConceptualGenerationChanges) => {
        const coverConceptualGenerationUpdate = data as CoverConceptualGenerationResultUpdate;
        if (coverConceptualGenerationUpdate) {
          const newCoverConceptualGeneration = coverConceptualGenerationUpdate.coverConceptualGeneration;

          this.coverConceptualGenerationStore.updateCoverConceptualGeneration(newCoverConceptualGeneration);

          if (coverConceptualGenerationUpdate.isError) {
            this.notifyOnTextGenerationError();
          }
        }
      });

    realtimeService
      .getEvents<CoverConceptualGenerationChanges>("changes")
      .pipe(
        filterUndefined(),
        filter((x) => x?.type === "cover-conceptual-generation-2-result"),
      )
      .subscribe((data: CoverConceptualGenerationChanges) => {
        const coverConceptualGenerationUpdate = data as CoverConceptualGeneration2ResultUpdate;

        this.loadingService.stopLoading();

        if (coverConceptualGenerationUpdate.isError) {
          this.notifyOnTextGenerationError();
          return;
        }

        if (coverConceptualGenerationUpdate) {
          // const newCoverConceptualGeneration = coverConceptualGenerationUpdate.coverConceptualGeneration;

          // this.coverConceptualGenerationStore.updateCoverConceptualGeneration(newCoverConceptualGeneration);

          if (coverConceptualGenerationUpdate.coverConceptualGeneration) {
            this.coverConceptualGenerationStep2Updates$.next(coverConceptualGenerationUpdate.coverConceptualGeneration);
          }
        }
      });
  }

  async upgradeTextGeneration(data: TextGenerationDataDto) {
    return await this.textGenerationApi.upgradeTextGeneration(data);
  }

  async loadLastCoverConceptualGeneration(coverId: number) {
    const lastCoverConceptualGeneration = await this.textGenerationApi.loadLastCoverConceptualGeneration(coverId);
    this.coverConceptualGenerationStore.setCoverConceptualGeneration(coverId, lastCoverConceptualGeneration);
  }

  async coverConceptualGeneration(data: CoverConceptualGenerationDataDto) {
    try {
      await this.textGenerationApi.coverConceptualGeneration(data);
      return "success";
    } catch (error: unknown) {
      return "error";
    }
  }

  async coverConceptualGenerationStep2(data: CoverConceptualGenerationStep2DataDto) {
    try {
      await this.textGenerationApi.coverConceptualGenerationStep2(data);
      return "success";
    } catch (error: unknown) {
      return "error";
    }
  }

  notifyOnTextGenerationError() {
    this.notificationService.error($localize`:@@cover-editor.conceptual-assistant.result.generation-error:`);
  }
}
