<div
  class="layer-outer-wrapper"
  [class.current]="isMaskSelected"
  [class.invalid]="!validateObjectName(object.name)"
>
  <div
    class="layer-wrapper"
    (click)="onObjectClick()">
    <div class="cell type">
      <m-ng-icon
        [size]="24"
        [src]="objectTypeIconPath"
      ></m-ng-icon>
      <div
        class="object-role"
        *ngIf="user.isAdmin && object.role"
        [ngClass]="object.role.role"
        [class.mapped-by-id]="object.role.isMappedById"
      ></div>
    </div>
    <div
      class="cell name"
      spellcheck="false"
      [editable]="isEditable"
      [(ngModel)]="layerName"
      (dblclick)="enableEdit($event)"
      (change)="onNameChange()"
      (blur)="onNameBlur()"
      (focus)="onNameFocus()"
    ></div>
    <div
      class="cell visibility"
      *ngIf="!object.isVisible"
    >
      <m-ng-icon
        [size]="24"
        src="/assets/icons/eye-close.svg"
      ></m-ng-icon>
    </div>

    <div
      class="cell drag-handle"
      *ngIf="!object.isLocked"
      cdkDragHandle
    >
      <m-ng-icon
        [size]="24"
        src="/assets/icons/drag-handle-01.svg"
      ></m-ng-icon>
    </div>

    <div
      class="cell lock"
      *ngIf="object.isLocked"
    >
      <m-ng-icon
        [size]="24"
        src="/assets/icons/locked-01.svg"
      ></m-ng-icon>
    </div>
  </div>
  <div
    [id]="'group-' + object.groupId!"
    class="layers-container"
    cdkDropList
    [cdkDropListData]="object.objects"
    [cdkDropListConnectedTo]="dragDropService.registered"
    [cdkDropListEnterPredicate]="allowDropPredicate"
    (cdkDropListDropped)="onReorder($event)"
  >
    <!-- <ng-container *ngIf="reversedObjects.length === 0 && !dragDropService.dragStartedContainerId"> -->
    <ng-container *ngIf="hasOnlyMaskObjects">
      <div class="empty">
        <m-ng-icon
          [size]="24"
          src="/assets/icons/plus.svg"
        ></m-ng-icon>
        <div class="text" i18n="@@cover-editor.mask.add-elements">cover-editor.mask.add-elements</div>
      </div>
    </ng-container>

    <ng-container *ngFor="let object of reversedObjects; trackBy: trackByFn">
      <!-- @if (isGroup(object)) { -->
      <!-- <m-cover-object-list-item-group -->
      <!--   cdkDrag -->
      <!--   [object]="castGroup(object)" -->
      <!--   [connectedIds]="connectedIds" -->
      <!--   (reorder)="reorderCoverObjects($event)" -->
      <!-- /> -->
      <!-- } @else { -->
      <m-cover-object-list-item
        cdkDrag
        [object]="object"
        [user]="user"
        [level]="level + 1"
        [isSelected]="isSelected(object)"
        [updates]="updates"
        (select)="onObjectSelected($event)"
        (cdkDragStarted)="onDragStarted($event)"
        (cdkDragMoved)="onDragMoved($event)"
        (cdkDragReleased)="onDragReleased($event)"
      />
      <!-- } -->
    </ng-container>
  </div>
</div>
