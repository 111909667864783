<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <m-filter-switch [options]="priceTypes" (onSelect)="onSelectPriceType($event)" [selectedOptionId]="priceType" />

  <m-ng-island [paddingSize]="24">
    <div *ngIf="priceType === 'basic'" class="controls">
      <div class="section-title" i18n="@@admin.companies.company.image-generation-price.basic">
        admin.companies.company.image-generation-price.basic
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.1">
          admin.companies.company.image-generation-price.1
        </span>
        <m-ng-input-number formControlName="generation1ImagePrice" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.2">
          admin.companies.company.image-generation-price.2
        </span>
        <m-ng-input-number formControlName="generation2ImagePrice" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.3">
          admin.companies.company.image-generation-price.3
        </span>
        <m-ng-input-number formControlName="generation3ImagePrice" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.4">
          admin.companies.company.image-generation-price.4
        </span>
        <m-ng-input-number formControlName="generation4ImagePrice" />
      </div>
    </div>

    <div *ngIf="priceType === 'advanced'" class="controls">
      <div class="section-title" i18n="@@admin.companies.company.image-generation-price.advanced.fast">
        admin.companies.company.image-generation-price.advanced.fast
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.1">
          admin.companies.company.image-generation-price.1
        </span>
        <m-ng-input-number formControlName="generation1ImagePriceAdvancedFast" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.2">
          admin.companies.company.image-generation-price.2
        </span>
        <m-ng-input-number formControlName="generation2ImagePriceAdvancedFast" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.3">
          admin.companies.company.image-generation-price.3
        </span>
        <m-ng-input-number formControlName="generation3ImagePriceAdvancedFast" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.4">
          admin.companies.company.image-generation-price.4
        </span>
        <m-ng-input-number formControlName="generation4ImagePriceAdvancedFast" />
      </div>

      <div class="divider"></div>

      <div class="section-title" i18n="@@admin.companies.company.image-generation-price.advanced.quality">
        admin.companies.company.image-generation-price.advanced.quality
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.1">
          admin.companies.company.image-generation-price.1
        </span>
        <m-ng-input-number formControlName="generation1ImagePriceAdvancedQuality" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.2">
          admin.companies.company.image-generation-price.2
        </span>
        <m-ng-input-number formControlName="generation2ImagePriceAdvancedQuality" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.3">
          admin.companies.company.image-generation-price.3
        </span>
        <m-ng-input-number formControlName="generation3ImagePriceAdvancedQuality" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.4">
          admin.companies.company.image-generation-price.4
        </span>
        <m-ng-input-number formControlName="generation4ImagePriceAdvancedQuality" />
      </div>

      <div class="divider"></div>

      <div class="section-title" i18n="@@admin.companies.company.image-generation-price.advanced.ultra">
        admin.companies.company.image-generation-price.advanced.ultra
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.1">
          admin.companies.company.image-generation-price.1
        </span>
        <m-ng-input-number formControlName="generation1ImagePriceAdvancedUltra" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.2">
          admin.companies.company.image-generation-price.2
        </span>
        <m-ng-input-number formControlName="generation2ImagePriceAdvancedUltra" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.3">
          admin.companies.company.image-generation-price.3
        </span>
        <m-ng-input-number formControlName="generation3ImagePriceAdvancedUltra" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.4">
          admin.companies.company.image-generation-price.4
        </span>
        <m-ng-input-number formControlName="generation4ImagePriceAdvancedUltra" />
      </div>

      <div class="divider"></div>

      <div class="section-title" i18n="@@admin.companies.company.image-generation-price.advanced.fabula">
        admin.companies.company.image-generation-price.advanced.fabula
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.1">
          admin.companies.company.image-generation-price.1
        </span>
        <m-ng-input-number formControlName="generation1ImagePriceAdvancedFabula" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.2">
          admin.companies.company.image-generation-price.2
        </span>
        <m-ng-input-number formControlName="generation2ImagePriceAdvancedFabula" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.3">
          admin.companies.company.image-generation-price.3
        </span>
        <m-ng-input-number formControlName="generation3ImagePriceAdvancedFabula" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.4">
          admin.companies.company.image-generation-price.4
        </span>
        <m-ng-input-number formControlName="generation4ImagePriceAdvancedFabula" />
      </div>

      <!-- <div class="control">
        <span
          class="label"
          i18n="@@admin.companies.company.image-generation-price.fabula-character-reference-coefficient"
        >
          admin.companies.company.image-generation-price.fabula-character-reference-coefficient
        </span>
        <m-ng-input-number formControlName="fabulaCharacterReferenceCoeficient" />
      </div> -->
    </div>

    <div *ngIf="priceType === 'creative'" class="controls">
      <div class="section-title" i18n="@@admin.companies.company.image-generation-price.creative">
        admin.companies.company.image-generation-price.creative
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.4">
          admin.companies.company.image-generation-price.1
        </span>
        <m-ng-input-number formControlName="generation4ImagePriceMj" />
      </div>

      <div class="control">
        <span
          class="label"
          i18n="@@admin.companies.company.image-generation-price.creative-character-reference-coefficient"
        >
          admin.companies.company.image-generation-price.creative-character-reference-coefficient
        </span>
        <m-ng-input-number formControlName="mjCharacterReferenceCoeficient" />
      </div>
    </div>

    <div *ngIf="priceType === 'other'" class="controls">
      <div class="section-title" i18n="@@admin.companies.company.image-generation-price.other">
        admin.companies.company.image-generation-price.other
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.unzoom">
          admin.companies.company.image-generation-price.unzoom
        </span>
        <m-ng-input-number formControlName="unzoomPrice" />
      </div>
      <!-- <div class="control">
      <span class="label" i18n="@@admin.companies.company.image-generation-price.upscale">
        admin.companies.company.image-generation-price.upscale
      </span>
      <m-ng-input-number  formControlName="upscalePrice" />
    </div> -->
      <div class="control">
        <span
          class="label"
          i18n="@@admin.companies.company.image-generation-price.coefficient-for-generation-with-elements"
        >
          admin.companies.company.image-generation-price.coefficient-for-generation-with-elements
        </span>
        <m-ng-input-number formControlName="generationWithElementsCoeficient" />
      </div>
      <div class="control">
        <span
          class="label"
          i18n="@@admin.companies.company.image-generation-price.coefficient-for-convert-leonardo-tokens"
        >
          admin.companies.company.image-generation-price.coefficient-for-convert-leonardo-tokens
        </span>
        <m-ng-input-number formControlName="convertLeonardoAiTokensCoeficient" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-price.fabula-remove-background">
          admin.companies.company.image-generation-price.fabula-remove-background
        </span>
        <m-ng-input-number formControlName="fabulaRemoveBackground" />
      </div>
    </div>

    <div class="row">
      <m-button
        button-style="primary"
        [is-disabled]="isDisabled()"
        (onClick)="onSubmit()"
        [is-disabled]="!form.valid"
        i18n="@@admin.common.save"
      >
        admin.common.save
      </m-button>
    </div>
  </m-ng-island>
</form>
