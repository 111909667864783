import { Expose, Type } from "class-transformer";
import { CoverObject } from "./cover-object";
import { CoverObjectType, FillType, TextCase } from "./enums";
import { TextAlignment } from "./keys";
import { TextboxShadowOptions } from "./text-shadow-options";
import { Fill, GradientFill, SolidFill } from "./color";

export class TextObject extends CoverObject {
  @Expose() text?: string;

  @Expose() fontFamily?: string;
  @Expose() fontSize?: number;
  @Expose() lineHeight?: number;
  @Expose() letterSpacing?: number;
  @Expose() textAlign?: TextAlignment;
  @Expose() textCase?: TextCase;

  @Expose() bold?: boolean;
  @Expose() italic?: boolean;
  @Expose() underline?: boolean;
  @Expose() linethrough?: boolean;
  @Type(() => TextboxShadowOptions) @Expose() shadow?: TextboxShadowOptions | null;

  @Type(() => Fill, {
    discriminator: {
      property: "__type",
      subTypes: [
        { value: SolidFill, name: FillType.Solid },
        { value: GradientFill, name: FillType.Gradient },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @Expose()
  fill!: Fill;

  @Type(() => Fill, {
    discriminator: {
      property: "__type",
      subTypes: [
        { value: SolidFill, name: FillType.Solid },
        { value: GradientFill, name: FillType.Gradient },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @Expose()
  strokeFill?: Fill;

  @Expose() strokeWidth?: number;

  constructor() {
    super();
    this.__type = CoverObjectType.Text;
  }

  override getDefaultName(): string {
    return "Text";
  }
}

