<m-button
  button-style="primary"
  icon-position="only"
  icon="face-swap-icon-28.svg"
  [is-disabled]="!image"
  title="published-image.settings.face-swap-tooltip"
  i18n-title="@@published-image.settings.face-swap-tooltip"
  (onClick)="onFaceSwapPublishedImage()"
/>

<m-button
  button-style="primary"
  size="full-width"
  [is-disabled]="!image"
  (onClick)="onCopyPublishedImageSettings()"
  i18n="@@published-image.settings.use-current-settings"
>
  published-image.settings.use-current-settings
</m-button>

<m-button
  *ngIf="canUnpublish"
  button-style="secondary"
  icon="book-delete.svg"
  icon-position="only"
  (onClick)="onShowUnpublishImageModal()"
/>
