<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <m-ng-island [paddingSize]="24">
    <div class="controls">
      <div class="section-title" i18n="@@admin.companies.company.image-generation-watermark.title">
        admin.companies.company.image-generation-watermark.title
      </div>

      <div class="control">
        <span class="control-title" i18n="@@admin.companies.company.image-generation-watermark.company-logo-image">
          admin.companies.company.image-generation-watermark.company-logo-image
        </span>

        <div class="file-upload">
          <m-file-drop
            #filedrop
            accept="image/png, image/jpeg"
            [multiple]="false"
            (dropped)="dropped($event)"
            style="--custom-height: 100%; flex-shrink: 0"
            [ngClass]="{ 'borderless': !!imageFile }"
          >
            @if(!imageUrl) {
            <div class="file-drop-container">
              <div class="drop-placeholder">
                <m-icon src="upload-2.svg" size="original" color="--color-font-main"></m-icon>

                <div>
                  <div class="description-text">
                    <ng-container
                      i18n="@@admin.companies.company.image-generation-watermark-settings.upload-button.text-1"
                    >
                      admin.companies.company.image-generation-watermark-settings.upload-button.text-11
                    </ng-container>
                  </div>
                  <div class="description-text">
                    <ng-container
                      i18n="@@admin.companies.company.image-generation-watermark-settings.upload-button.text-2"
                    >
                      admin.companies.company.image-generation-watermark-settings.upload-button.text-2
                    </ng-container>
                    <span
                      class="mark"
                      (click)="openFileSelector()"
                      i18n="@@admin.companies.company.image-generation-watermark-settings.upload-button.text-2-upload"
                    >
                      admin.companies.company.image-generation-watermark-settings.upload-button.text-2-upload
                    </span>
                    <ng-container
                      i18n="@@admin.companies.company.image-generation-watermark-settings.upload-button.text-3"
                    >
                      admin.companies.company.image-generation-watermark-settings.upload-button.text-3
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            }
            <!--  -->
            @if(imageUrl) {
            <div
              class="preview"
              [ngClass]="{ hovered: isPreviewHovered }"
              (dragover)="onDragOver($event)"
              (mouseenter)="onMouseEnter($event)"
              (mouseleave)="onMouseLeave($event)"
            >
              <img [src]="imageUrl" />

              <div class="upload-another">
                <m-icon src="upload-2.svg" size="original" color="--color-font-main"></m-icon>

                <div>
                  <div class="description-text">
                    <span
                      class="mark"
                      (click)="openFileSelector()"
                      i18n="@@admin.companies.company.image-generation-watermark-settings.upload-another-button.text-1-upload"
                    >
                      admin.companies.company.image-generation-watermark-settings.upload-another-button.text-1-upload
                    </span>
                    <ng-container
                      i18n="@@admin.companies.company.image-generation-watermark-settings.upload-another-button.text-2"
                    >
                      admin.companies.company.image-generation-watermark-settings.upload-another-button.text-2
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            }
          </m-file-drop>
        </div>
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-watermark.is-visible">
          admin.companies.company.image-generation-watermark.is-visible
        </span>
        <m-switch formControlName="isVisible" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-watermark.offset-bottom">
          admin.companies.company.image-generation-watermark.offset-bottom
        </span>
        <m-ng-input-number formControlName="offsetBottom" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.image-generation-watermark.offset-right">
          admin.companies.company.image-generation-watermark.offset-right
        </span>
        <m-ng-input-number formControlName="offsetRight" />
      </div>
    </div>

    <div class="row">
      <m-button
        button-style="primary"
        [is-disabled]="isDisabled()"
        (onClick)="onSubmit()"
        [is-disabled]="!form.valid"
        i18n="@@admin.common.save"
      >
        admin.common.save
      </m-button>
    </div>
  </m-ng-island>
</form>
