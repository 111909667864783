import { Expose } from "class-transformer";
import { ShapeObject } from "./shape-object";
import { CoverObjectType } from "./enums";

export class SvgObject extends ShapeObject {
  @Expose() imageUrl?: string;
  @Expose() fileId?: string;
  constructor() {
    super();
    this.__type = CoverObjectType.SVG;
  }

  override getDefaultName(): string {
    return "Shape";
  }
}

export enum SvgObjectCategory {
  BasicShapes = 1,
  AgeConstraints = 2,
  Arrows = 3,
  Splashes = 4,
}

export type SvgObjectUrl = {
  iconUrl: string;
  objectUrl: string;
};

export type PredefinedSvgObjects = {
  category: SvgObjectCategory;
  urls: SvgObjectUrl[];
};
