import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { BookCover } from "@metranpage/book-data";
import { RouterService } from "@metranpage/core";
import { UserStore } from "@metranpage/user-data";
import { UserVerificationOverlayServiceInterface } from "@metranpage/user-interfaces";
import { Subject, Subscription, debounceTime, timer } from "rxjs";
import { HomeService } from "../../../../services/home.service";
import { CommunityDataService } from "../../community-data.service";

@Component({
  selector: "m-community-cover-details-view",
  templateUrl: "cover-details.view.html",
  styleUrls: ["cover-details.view.scss"],
})
export class CommunityCoverDetailsView implements OnInit {
  @Input()
  cover!: BookCover;
  @Input()
  hasActions = true;
  @Input()
  canUnpublish = false;

  @Output()
  onUnpublish = new EventEmitter<void>();
  @Output()
  onLike = new EventEmitter<boolean>();

  protected avatarText = "";

  protected debounceTime = 500;

  protected isLiked = false;
  protected likesCount = 0;

  private likeClick = new Subject<Event>();
  sub: Subscription = new Subscription();

  constructor(
    private readonly communityDataService: CommunityDataService,
    private readonly userStore: UserStore,
    private readonly homeService: HomeService,
    private readonly routerService: RouterService,
    @Inject("UserVerificationOverlayService")
    protected readonly userVerificationOverlayService: UserVerificationOverlayServiceInterface,
  ) {}

  async ngOnInit() {
    this.updateAvatarText();

    this.isLiked = this.cover.isLiked;
    this.likesCount = this.cover.likesCount;
    this.watchLikeClick();
  }

  protected onCopyPublishedCoverSettings() {
    // this.publishedCoverStore.setPublishedCoverSettings(this.image?.generationSettings);
    // this.router.navigateByUrl("/image-generation");
  }

  protected onShowUnpublishCoverModal() {
    this.onUnpublish.emit();
  }

  protected getUrlForCover(cover: BookCover) {
    return this.communityDataService.getImageUrlForObject("covers", cover.id, true)!;
  }

  protected onLikeClick(e: Event) {
    const user = this.userStore.getUser();
    if (!user) {
      this.homeService.showLoginModal();
      return;
    }
    if (!user.isVerified) {
      this.routerService.showInModal(undefined);
      timer(100).subscribe(() => {
        this.userVerificationOverlayService.open();
      });
      return;
    }

    this.isLiked = !this.isLiked;
    if (this.isLiked) {
      this.likesCount = this.likesCount + 1;
    } else {
      this.likesCount = this.likesCount - 1;
    }

    this.likeClick.next(e);
  }

  protected watchLikeClick() {
    this.sub.add(
      this.likeClick.pipe(debounceTime(this.debounceTime)).subscribe((e: Event) => {
        this.onLike.emit(this.isLiked);
      }),
    );
  }

  protected updateAvatarText() {
    const firstLetterOfFirstName = this.cover.author?.firstName?.charAt(0)?.toUpperCase() || "";
    const firstLetterOfLastName = this.cover.author?.lastName?.charAt(0)?.toUpperCase() || "";
    this.avatarText = `${firstLetterOfFirstName}${firstLetterOfLastName}`;
  }
}
