<div class="group">
  <div *ngIf="userBalance" class="tokens">
    @if(userBalance.goldCredits) {
    <div class="token-group">
      <div class="token-balance">
        <m-ng-icon src="/assets/img/app-bar-menu/token-gold.svg" [size]="24" />
        <span class="credit-value">{{ userBalance.goldCredits }}</span>
      </div>
      <m-ng-icon
        *ngIf="isPurchasesAvailable"
        class="token-action"
        src="/assets/img/app-bar-menu/tokens-info.svg"
        [size]="20"
        (click)="onTokenGoldInfoShow()"
      />
    </div>
    }
    <!--  -->

    @if(isPurchasesAvailable) {
    <div class="token-group">
      <div class="token-balance">
        <m-ng-icon src="/assets/img/app-bar-menu/token-simple-{theme}.svg" [size]="24" />
        <span class="credit-value">{{ getUserBalance() }}</span>
      </div>
      <m-ng-icon
        class="token-action"
        src="/assets/img/app-bar-menu/tokens-plus.svg"
        [size]="20"
        (click)="onTokenSimpleAddClick()"
      />
    </div>
    }
  </div>

  <m-ng-icon class="burger-icon" src="burger-menu-closed-icon.svg" [size]="28" (click)="toggleMenu()" />
</div>

@if(isMenuOpened) {
<m-modal-backdrop [closeButtonVisible]="false" [closeOnBackDropClick]="false" (close)="toggleMenu()">
  <div class="menu-wrapper">
    <m-ng-island @fadeInOut [paddingSize]="0" class="menu">
      <div class="menu-header">
        @if(!hideNavbarLogo) {
        <m-logo logo-style="full" routerLink="/" class="logo"></m-logo>
        }

        <m-ng-icon class="burger-icon" src="burger-menu-opened-icon.svg" [size]="28" (click)="toggleMenu()" />
      </div>

      <div class="divider"></div>

      <div class="menu-buttons-wrapper">
        @if(user) {
        <m-button
          button-style="tertiary"
          icon="/assets/img/app-bar-menu/account.svg"
          icon-position="left"
          text-align="left"
          size="full-width"
          routerLink="/account"
          (onClick)="track('burger-profile'); closeMenu()"
          i18n="@@app-bar.account-button"
        >
          app-bar.account-button
        </m-button>

        <m-button
          button-style="tertiary"
          icon="/assets/img/app-bar-menu/menu-projects.svg"
          icon-position="left"
          text-align="left"
          size="full-width"
          routerLink="/books"
          (onClick)="track('burger-projects'); closeMenu()"
          i18n="@@app-bar.my-projects-button"
        >
          app-bar.my-projects-button
        </m-button>
        } @else {
        <m-button
          button-style="tertiary"
          icon="/assets/img/app-bar-menu/account.svg"
          icon-position="left"
          text-align="left"
          size="full-width"
          routerLink="/login"
          (onClick)="track('burger-login'); closeMenu()"
          i18n="@@app-bar.sign-in-button"
        >
          app-bar.sign-in-button
        </m-button>
        }
        <!--  -->
        @if(isPurchasesAvailable) {
        <m-button
          button-style="tertiary"
          icon="/assets/img/app-bar-menu/payments.svg"
          icon-position="left"
          text-align="left"
          size="full-width"
          routerLink="/payments/subscription"
          (onClick)="track('burger-pricing'); closeMenu()"
          i18n="@@app-bar.profile.menu.pricing-button"
        >
          app-bar.profile.menu.pricing-button
        </m-button>
        }
        <!--  -->
        @if(user) {
        <m-button
          button-style="tertiary"
          icon="/assets/img/app-bar-menu/promocode.svg"
          icon-position="left"
          text-align="left"
          size="full-width"
          (onClick)="showPromocodeModal()"
          i18n="@@app-bar.profile.menu.promocode-button"
        >
          app-bar.profile.menu.promocode-button
        </m-button>

        @if(isOnboardingAvailable) {
        <m-button
          button-style="tertiary"
          icon="/assets/img/app-bar-menu/onboarding.svg"
          icon-position="left"
          text-align="left"
          size="full-width"
          (onClick)="startOnboarding()"
          i18n="@@app-bar.profile.menu.start-onboarding-button"
        >
          app-bar.profile.menu.start-onboarding-button
        </m-button>
        }
        <!--  -->
        @if(knowledgeBaseUrl) {
        <m-button
          button-style="tertiary"
          icon="/assets/img/app-bar-menu/knowledge-base.svg"
          icon-position="left"
          text-align="left"
          size="full-width"
          (onClick)="openKnowledgeBase(knowledgeBaseUrl)"
          i18n="@@app-bar.profile.menu.knowledge-base-button"
        >
          app-bar.profile.menu.knowledge-base-button
        </m-button>
        } }
        <!--  -->
        @if(isLanguageSwitchAvailable) {
        <a class="menu-item not-clickable">
          <div class="elements-group">
            <m-icon src="/assets/img/app-bar-menu/language.svg" color="--color-font-input" size="m" />
            <span class="" i18n="@@app-bar.profile.menu.language-button">app-bar.profile.menu.language-button</span>
          </div>
          <div class="elements-group">
            <m-lang-select-2
              [userLanguage]="language"
              select-style="small"
              (changeLang)="onLanguageChangeRequested($event)"
            />
          </div>
        </a>
        }

        <m-button
          button-style="tertiary"
          icon="/assets/img/app-bar-menu/color-scheme.svg"
          icon-position="left"
          text-align="left"
          size="full-width"
          (onClick)="onThemeSwitchToggle()"
        >
          <div class="menu-button-content">
            <span class="" i18n="@@app-bar.profile.menu.dark-theme-button">app-bar.profile.menu.dark-theme-button</span>

            <m-switch [(ngModel)]="isDarkThemeEnable" [isInteractive]="false" [withLabel]="false" />
          </div>
        </m-button>

        @if(isLogoutButtonAvailable) {
        <m-button
          button-style="tertiary"
          icon="/assets/img/app-bar-menu/logout.svg"
          icon-position="left"
          text-align="left"
          color-theme="danger"
          size="full-width"
          (onClick)="onLogoutButtonClick()"
          i18n="@@app-bar.profile.menu.logout-button"
        >
          app-bar.profile.menu.logout-button
        </m-button>
        }
      </div>

      @if(socials.length > 0) {
      <div class="divider"></div>

      <m-socials class="menu-socials-wrapper" [socials]="socials" [withIsland]="false" />
      }
    </m-ng-island>

    <div class="close-button" (click)="toggleMenu()">
      <m-ng-icon class="burger-icon" src="burger-menu-opened-icon.svg" [size]="24" />
      <span i18n="@@app-bar.menu-mobile.close-button">app-bar.menu-mobile.close-button</span>
    </div>
  </div>
</m-modal-backdrop>
}

<m-modal
  *ngIf="isAboutGoldCreditsVisible"
  position="center"
  [closeButtonVisible]="false"
  (close)="onTokenGoldInfoClose()"
>
  <div class="modal-container">
    <div class="info-block">
      <span class="info-header" i18n="@@books.preview.payments-modal.what-is-gold-credits">
        books.preview.payments-modal.what-is-gold-credits
      </span>
      <span class="info-text" i18n="@@books.preview.payments-modal.what-is-gold-credits-text">
        books.preview.payments-modal.what-is-gold-credits-text
      </span>
    </div>
    <div class="info-block">
      <span class="info-header" i18n="@@books.preview.payments-modal.how-to-get-gold-credits">
        books.preview.payments-modal.how-to-get-gold-credits
      </span>
      <div>
        <m-localized-text
          class="info-text"
          text="books.preview.payments-modal.how-to-get-gold-credits-text"
          i18n-text="@@books.preview.payments-modal.how-to-get-gold-credits-text"
        />
      </div>
    </div>
    <div class="info-block">
      <m-button
        button-style="primary"
        size="full-width"
        (onClick)="onTokenGoldInfoClose()"
        i18n="@@dialog.understand-button"
      >
        dialog.understand-button
      </m-button>
    </div>
  </div>
</m-modal>

<m-promocode-modal
  *ngIf="isPromocodeModalVisible"
  (onPromocodeEnter)="promocodeEnter($event)"
  (onClose)="hidePromocodeModal()"
/>
