<m-modal-backdrop (close)="onCloseClick()">
  <div *ngIf="selectedImage" class="container">
    <div class="arrow-zone">
      <div *ngIf="hasPrevious" class="arrow-button-previous" (click)="onPreviousClick()">
        <m-ng-icon src="button-arrow-right.svg" [size]="24" />
      </div>
    </div>

    <m-ng-island class="preview-island">
      <m-preview-generated-image [selectedImage]="selectedImage"></m-preview-generated-image>
    </m-ng-island>

    <m-ng-island class="sidebar">
      <div class="preview-info-modal">
        <div class="actions-group-container">
          <form
            *ngIf="selectedImage.variantSelectOptions.length > 0"
            [formGroup]="formVarianImageGenerationsSelect"
            class="image-select"
          >
            <m-select
              size="m-plus"
              formControlName="images"
              [options]="selectedImage.variantSelectOptions"
              [hideArrowDivider]="true"
            ></m-select>

            <div class="divider"></div>
          </form>

          @if(isUnzoomAvailable()) {
          <div class="settings">
            <div class="settings-title">
              <m-ng-icon src="unzoom.svg" [size]="28" />

              <span i18n="@@image-generation.generation.unzoom.title">image-generation.generation.unzoom.title</span>
            </div>

            <div class="settings-info">
              <span i18n="@@image-generation.generation.unzoom.info">image-generation.generation.unzoom.info</span>
            </div>

            <div class="button-container">
              <m-button
                button-style="secondary"
                [is-disabled]="isUnzoomDisable()"
                size="full-width"
                (onClick)="onUnzoomClick()"
              >
                <span
                  [class.not-enought-tokens]="!isEnoughtTokens('unzoom')"
                  i18n="@@image-generation.generation.unzoom.button-text"
                >
                  image-generation.generation.unzoom.button-text
                </span>
                <div class="price" [class.not-enought-tokens]="!isEnoughtTokens('unzoom')">
                  <m-ng-icon class="token-icon" src="/assets/img/app-bar-menu/token-simple-{theme}.svg" [size]="20" />
                  <span *ngIf="prices">{{ getPrice('unzoom') }}</span>
                </div>
              </m-button>
            </div>

            <m-info-block type="warning" [labels]="getUnzoomErrors()" />
          </div>

          <div class="divider"></div>
          }

          <form *ngIf="isUpscaleAvailable()" [formGroup]="formUpscale" class="settings">
            <div class="settings-title">
              <m-ng-icon src="upscale.svg" [size]="28" />

              <span i18n="@@image-generation.generation.upscale.title">image-generation.generation.upscale.title</span>
            </div>

            <div class="settings-info">
              <span i18n="@@image-generation.generation.upscale.info">image-generation.generation.upscale.info</span>
              <br />
              <span i18n="@@image-generation.generation.upscale.strength">
                image-generation.generation.upscale.strength
              </span>
            </div>

            <m-slider-selector formControlName="creativityStrength" [points]="getUpscaleStrengthPoints()" />

            <div class="button-container">
              <m-button
                button-style="secondary"
                [is-disabled]="isUpscaleDisable()"
                [show-premium-mark]="!hasPremium"
                size="full-width"
                (onClick)="onUpscaleClick()"
              >
                <span
                  [class.not-enought-tokens]="!isEnoughtTokens('upscale')"
                  i18n="@@image-generation.generation.upscale.button-text"
                >
                  image-generation.generation.upscale.button-text
                </span>
                <div class="price" [class.not-enought-tokens]="!isEnoughtTokens('upscale')">
                  <m-ng-icon class="token-icon" src="/assets/img/app-bar-menu/token-simple-{theme}.svg" [size]="20" />
                  <span *ngIf="prices">{{ getPrice('upscale') }}</span>
                </div>
              </m-button>
            </div>

            <m-info-block type="warning" [labels]="getUpscaleErrors()" />
          </form>
        </div>

        <div class="group-container">
          <div class="divider"></div>

          <div class="modal-buttons" [ngClass]="{'space-between': hasPremium}">
            <m-button
              button-style="primary"
              icon-position="only"
              icon="face-swap-icon-28.svg"
              [is-disabled]="!selectedImage.hasImage"
              title="published-image.settings.face-swap-tooltip"
              i18n-title="@@published-image.settings.face-swap-tooltip"
              (onClick)="onFaceSwapPublishedImage()"
            />

            <m-button
              button-style="secondary"
              icon="download.svg"
              icon-position="only"
              [is-disabled]="!selectedImage.hasImage"
              (onClick)="onDownloadImageClick()"
            />

            <m-button
              button-style="secondary"
              icon="share.svg"
              icon-position="only"
              [is-disabled]="!selectedImage.hasImage"
              (onClick)="onShowShareImageModal()"
            />

            @if(hasPremium) {
            <m-button
              button-style="secondary"
              icon="book-delete.svg"
              icon-position="only"
              [is-disabled]="!selectedImage.hasImage"
              (onClick)="onShowDeleteGeneratedImageModal()"
            />
            }
          </div>

          @if(isModal) {
          <div class="divider"></div>

          <div class="button-wrapper">
            <m-button
              button-style="primary"
              size="full-width"
              [is-disabled]="!selectedImage.hasImage"
              (onClick)="selectClick()"
              i18n="@@image-generation.image.select-button"
            >
              image-generation.image.select-button
            </m-button>
          </div>
          }
        </div>
      </div>
    </m-ng-island>

    <div class="arrow-zone">
      <div *ngIf="hasNext" class="arrow-button-next" (click)="onNextClick()">
        <m-ng-icon src="button-arrow-right.svg" [size]="24" />
      </div>
    </div>
  </div>
</m-modal-backdrop>

<m-share-image-modal
  *ngIf="generatedImageForShare"
  [user]="user"
  [isPublic]="generatedImageForShare.isPublic"
  [moderationRequest]="generatedImageForShare.moderationImageRequest"
  (onPublish)="onPublishImageClick()"
  (onClose)="onCloseShareImageModal()"
/>

<m-confirmation-modal
  *ngIf="generatedImageForDelete"
  [text]="modalConfirmationDeleteImageText"
  confirmText="dialog.delete-button"
  i18n-confirmText="@@dialog.delete-button"
  cancelText="dialog.cancel-button"
  i18n-cancelText="@@dialog.cancel-button"
  (onConfirm)="deleteImage()"
  (onClose)="onCloseDeleteGeneratedImageModal()"
/>

<m-low-balance-modal
  *ngIf="isLowBalanceModalVisible && (tariffsForUpgrade$ | async) as tariffs"
  @fadeInOut
  [user]="user"
  [tariffs]="tariffs"
  [balanceData]="imageGenerationPaymentData"
  [activeSubscription]="activeSubscription"
  [isImageGeneration]="true"
  [imageGenerationMode]="imageGenerationMode"
  [userRewardOneTime]="getSubscribeToTelegramChannelReward()"
  [currency]="currency"
  (close)="closePricingModal()"
  (buySubscription)="onBuySubscription($event)"
/>

<m-premium-tariffs-modal @fadeInOut *ngIf="isPremiumModalVisible" (close)="closePremiumModal()" />
