import { Expose, Type } from "class-transformer";
import { GradientDirection, GradientType } from "./keys";
import { FillType } from "./enums";

export class Color {
  @Expose() r: number;
  @Expose() g: number;
  @Expose() b: number;
  @Expose() a: number;

  constructor(r: number, g: number, b: number, a: number) {
    this.r = r;
    this.g = g;
    this.b = b;
    this.a = a;
  }

  static toCss(color: Color): string {
    return `rgba(${color.r},${color.g},${color.b},${color.a})`;
  }

  toCss(): string {
    return Color.toCss(this);
  }

  equals(color: Color): boolean {
    return (
      Math.round(this.r) === Math.round(color.r) &&
      Math.round(this.g) === Math.round(color.g) &&
      Math.round(this.b) === Math.round(color.b) &&
      this.a === color.a
    );
  }

  isEqualTone(color: Color): boolean {
    return (
      Math.round(this.r) === Math.round(color.r) &&
      Math.round(this.g) === Math.round(color.g) &&
      Math.round(this.b) === Math.round(color.b)
    );
  }
}

export class ColorStop {
  @Expose() @Type(() => Color) color: Color = new Color(0, 0, 0, 1);
}

export class Gradient {
  @Expose() type: GradientType = "linear";
  @Expose() direction: GradientDirection = "horizontal";
  @Expose() @Type(() => ColorStop) colorStops: ColorStop[] = [];
}

export abstract class Fill {
  @Expose() __type!: FillType;
}

export class SolidFill extends Fill {
  @Expose() @Type(() => Color) color!: Color;

  constructor() {
    super();
    this.__type = FillType.Solid;
    this.color = new Color(0, 0, 0, 1);
  }
}

export class GradientFill extends Fill {
  @Expose() @Type(() => Gradient) gradient!: Gradient;

  constructor() {
    super();
    this.__type = FillType.Gradient;
  }
}
