import { Injectable } from "@angular/core";
import { RealtimeService } from "@metranpage/core";
import { GeneralResultStatus, MetranpageFile } from "@metranpage/core-data";
import { Subject } from "rxjs";
import {
  ImageGenerationCharacterReference,
  ImageGenerationCharacterReferenceDataDto,
} from "../../models/image-generation-character-reference/image-generation-character-reference";
import {
  ImageGenerationCharacterReferenceApi,
  ImageGenerationCharacterReferenceFilter,
} from "./image-generation-character-reference.api";
import { ImageGenerationCharacterReferenceStore } from "./image-generation-character-reference.store";

export type CreateImageGenerationCharacterReferenceResultStatus = GeneralResultStatus | "file-limit-error";
export type CreateImageGenerationCharacterReferenceResponse = {
  characterReference?: ImageGenerationCharacterReference;
  error?: string;
};

@Injectable({
  providedIn: "root",
})
export class ImageGenerationCharacterReferenceService {
  // onChangeCharacterReference$ = new Subject<number>();

  constructor(
    private readonly imageGenerationCharacterReferenceStore: ImageGenerationCharacterReferenceStore,
    private readonly imageGenerationCharacterReferenceApi: ImageGenerationCharacterReferenceApi,
    readonly realtimeService: RealtimeService,
  ) {
    // realtimeService
    //   .getEvents<ImageGenerationCharacterReference | undefined>("image-generation-user-style-result")
    //   .subscribe((characterReference: ImageGenerationCharacterReference | undefined) => {
    //     this.imageGenerationCharacterReferenceStore.setCharacterReference(characterReference);
    //   });
  }

  // onChangeCharacterReference(characterReference: number) {
  //   this.onChangeCharacterReference$.next(userStyleReferenceId);
  // }

  async loadCharacterReferencePaginated(page: number, filters: ImageGenerationCharacterReferenceFilter = {}) {
    const characterReferenceData = await this.imageGenerationCharacterReferenceApi.getCharacterReferencesPaginated(
      page,
      filters,
    );
    if (filters.type === "face") {
      this.imageGenerationCharacterReferenceStore.addCharacterReferenceToEnd(characterReferenceData.items);
      this.imageGenerationCharacterReferenceStore.setFaceReferencePageCount(characterReferenceData.pageCount);
    }
    if (filters.type === "character") {
      this.imageGenerationCharacterReferenceStore.addCharacterReferenceToEnd(characterReferenceData.items);
      this.imageGenerationCharacterReferenceStore.setCharacterReferencePageCount(characterReferenceData.pageCount);
    }
  }

  async createCharacterReference(data: ImageGenerationCharacterReferenceDataDto, file: File | undefined) {
    try {
      const result = await this.imageGenerationCharacterReferenceApi.createCharacterReference(data, file);
      if (result.characterReference) {
        this.imageGenerationCharacterReferenceStore.addCharacterReference(result.characterReference);
      }
      return result;
    } catch (error: any) {
      return { error: "request-error" };
    }
  }

  async deleteCharacterReference(id: number) {
    try {
      await this.imageGenerationCharacterReferenceApi.deleteCharacterReference(id);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  getUrlForImage(imageGenerationCharacterReference: ImageGenerationCharacterReference, isThumbnail = false) {
    if (isThumbnail) {
      return this.imageGenerationCharacterReferenceApi.getUrlForFile(imageGenerationCharacterReference.previewFile);
    }
    return this.imageGenerationCharacterReferenceApi.getUrlForFile(imageGenerationCharacterReference.file);
  }

  getUrlForFile(file: MetranpageFile) {
    return this.imageGenerationCharacterReferenceApi.getUrlForFile(file);
  }
}
