<div
  class="layer-outer-wrapper"
  [class]="{ 'level-1': level == 1, 'level-2': level == 2, 'level-3': level == 3 }"
  [class.current]="isSelected"
  [class.invalid]="!validateObjectName(object.name)"
  (click)="onObjectClick()"
>
  <div class="layer-wrapper">
    <div class="cell type">
      <m-ng-icon [size]="24" [src]="objectTypeIconPath"></m-ng-icon>
      <div
        class="object-role"
        *ngIf="user.isAdmin && object.role"
        [ngClass]="object.role.role"
        [class.mapped-by-id]="object.role.isMappedById"
      ></div>
    </div>
    <div
      class="cell name"
      spellcheck="false"
      [editable]="isEditable"
      [(ngModel)]="layerName"
      (keydown.enter)="onNameEnter($event)"
      (dblclick)="enableEdit($event)"
      (change)="onNameChange()"
      (blur)="onNameBlur()"
      (focus)="onNameFocus()"
    ></div>
    <div class="cell visibility" *ngIf="!object.isVisible">
      <m-ng-icon [size]="24" src="/assets/icons/eye-close.svg"></m-ng-icon>
    </div>

    <div
      class="cell drag-handle"
      *ngIf="!object.isLocked"
      cdkDragHandle
    >
      <m-ng-icon
        [size]="24"
        src="/assets/icons/drag-handle-01.svg"
      ></m-ng-icon>
    </div>

    <div class="cell lock" *ngIf="object.isLocked">
      <m-ng-icon [size]="24" src="/assets/icons/locked-01.svg"></m-ng-icon>
    </div>
  </div>
</div>
