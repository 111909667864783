<div class="preview-info-modal">
  <div class="header">
    <div class="author">
      <div *ngIf="avatarText" class="avatar">{{ avatarText }}</div>
      <div class="author-name">
        <span>{{ cover.author?.firstName }}</span>
        <span>{{ cover.author?.lastName }}</span>
      </div>
    </div>

    <div class="likes" [ngClass]="{liked: isLiked}" (click)="onLikeClick($event)">
      <m-ng-icon *ngIf="!isLiked" class="icon-unliked" src="like-1.svg" [size]="25" />
      <m-ng-icon *ngIf="isLiked" class="icon-liked" src="like-2.svg" [size]="25" />

      <span>{{ likesCount }}</span>
    </div>
  </div>

  <div class="divider"></div>

  <div class="settings" [ngClass]="{'margin-bottom': hasActions}">
    <!-- <div class="settings-group">
          <span class="group-title" i18n="@@published-image.settings.creation-date">
            published-image.settings.creation-date
          </span>
          <span class="group-value">{{ getCreationDate() }}</span>

          <div class="divider"></div>
        </div> -->

    @if(hasActions) {
    <div class="modal-buttons">
      <m-community-cover-details-actions-view
        [cover]="cover"
        [canUnpublish]="canUnpublish"
        (unpublish)="onShowUnpublishCoverModal()"
      />
    </div>
    }
  </div>
</div>
