import { moveItemInArray } from "@angular/cdk/drag-drop";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  ApplyTemplateRequestDto,
  Book,
  BookCover,
  BookCoverTemplate,
  Color,
  CoverObject,
  CoverObjectContainer,
  CoverObjectType,
  CoverSnippet,
  CoverSnippetCategory,
  CoverSnippetCategoryObjects,
  CoverSnippetCreateDataDto,
  CoverSnippetCreateMetaData,
  CoverSnippetSubcategory,
  EllipseObject,
  Fill,
  GradientFill,
  GroupAlike,
  GroupObject,
  ImageObject,
  MaskGroupObject,
  ObjectPosition,
  ObjectsAlignment,
  PredefinedSvgObjects,
  RectangleObject,
  ShapeObject,
  SolidFill,
  SvgObject,
  TextCase,
  TextObject,
  UploadGeneratedImageRequestDto,
} from "@metranpage/book-data";
import { ColorConverterService, fadeInOutOnEnterLeave, slideInOutVertical } from "@metranpage/components";
import { LoadingService, NotificationsPopUpService, RealtimeService, filterUndefined } from "@metranpage/core";
import {
  FabulGenerationMode,
  FabulaGeneratedImage,
  FabulaImageGenerationResultUpdate,
  FabulaImageGenerationService,
  FabulaRemoveBackgroundDataDto,
  ImageGeneration,
  ImageGenerationPrices,
  ImageGenerationService,
  PublishedImageStore,
  SelectGeneratedImageData,
} from "@metranpage/image-generation";
import { OnboardingService } from "@metranpage/onboarding";
import { PricingService } from "@metranpage/pricing";
import { ActiveSubscription, Tariff } from "@metranpage/pricing-data";
import {
  BlockSize,
  CoverConceptualFormService,
  CoverConceptualGeneration,
  CoverConceptualGenerationDataDto,
  CoverConceptualGenerationStep2,
  CoverConceptualGenerationStore,
  PicData,
  TextGenerationService,
  TitlePart,
} from "@metranpage/text-generation";
import { ThemeService } from "@metranpage/theme";
import { RewardsService } from "@metranpage/user";
import { RewardsStore, User, UserBalance, UserRewardOneTime, UserStore } from "@metranpage/user-data";
import { BalanceData } from "@metranpage/user-payment-data";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { instanceToPlain, plainToInstance } from "class-transformer";
import {
  util,
  ActiveSelection,
  BasicTransformEvent,
  Canvas,
  Ellipse,
  FabricImage,
  FabricObject,
  Gradient,
  Group,
  ImageFormat,
  Line,
  Pattern,
  Point,
  Rect,
  Shadow,
  StaticCanvas,
  TEvent,
  TPointerEvent,
  TPointerEventInfo,
  Textbox,
  config,
  loadSVGFromURL,
} from "fabric";
import * as _ from "lodash-es";
import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  debounceTime,
  filter,
  fromEvent,
  lastValueFrom,
  map,
  switchMap,
  tap,
} from "rxjs";
import { COVER_TEXT_STORAGE } from "../../book.module";
import { CoverSnippetCreateService } from "../../services/cover-snippet/cover-snippet-create.service";
import { CoverSnippetDataService } from "../../services/cover-snippet/cover-snippet-data.service";
import { CoverSnippetService } from "../../services/cover-snippet/cover-snippet.service";
import { CoverSnippetStore } from "../../services/cover-snippet/cover-snippet.store";
import { CoverFontsService } from "../../services/cover/cover-fonts.service";
import { CoverTemplateStore } from "../../services/cover/cover-template.store";
import { CoverUiService, UpdateGroupParam } from "../../services/cover/cover-ui.service";
import { CoverService } from "../../services/cover/cover.service";
import { CoverTextStorage } from "../../services/cover/text-storage/cover-text-storage";
import { FontsWithColorData } from "../cover-conceptual-assistant-generation-result/cover-conceptual-assistant-generation-result.view";
import {
  CoverSnippetScrollPositionState,
  CreateCoverObject,
} from "../cover-object-create/cover-object-create.component";
import { initAligningGuidelines } from "./fabric/extensions";
import DataHelper from "./helpers/data-helper";
import { FabricCustomizer } from "./helpers/fabric-customizer";
import { SelectionManager } from "./helpers/selection-helper";
import { Guidelines, Snapper } from "./helpers/snapper";
import { BookCoverState, BookCoverStateOptions, SimpleUndoRedo } from "./helpers/undo-redo";

type ObjectCreatedModel = { objects: ObjectShape[]; correctionsApplied: boolean };

type EditingMode = "common" | "pan";

const defaultText = "Текст всякий там";
const defaultImageUrl = "https://img.freepik.com/premium-vector/geometric-abstract-vertical-background_697972-1515.jpg";
const defaultFontFamily = "Roboto";

const colorActive = "#E02379";
const snapTolerance = 8;
const gridSize = 8;
const shapAngle = 15;
const safeMarginValue = (1 / 2.54) * 72;
const safeMarginWidth = 2;
const zoomByWheel = true;
const maskOuterStrokeWidth = 10000;
const maskInnerStrokeWidth = 2;

const coverFullsizeImageMultiplier = 5;
const coverPreviewImageMultiplier = 2;

export type ObjectShape = (
  | TextObject
  | ImageObject
  | RectangleObject
  | EllipseObject
  | SvgObject
  | GroupObject
  | MaskGroupObject
) & {
  shape?: FabricObject;
};

type ViewMode = "editing" | "preview" | "final";
type SidebarMode =
  | "object-settings"
  | "multiselect-settings"
  | "object-create"
  | "template-list"
  | "conceptual-assistant";

type ObjectSize = { width: number; height: number };
type RescaleFitMode = "contain" | "cover" | "horizontally" | "vertically";

type Position = { x: number; y: number };

type CoverObjectRole = "title" | "subtitle" | "author";

@UntilDestroy()
@Component({
  selector: "m-cover-view",
  templateUrl: "./cover.view.html",
  styleUrls: ["./cover.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutOnEnterLeave, slideInOutVertical],
})
export class CoverView implements OnChanges, AfterViewInit, OnDestroy, OnInit {
  @Input() book!: Book;
  @Input() cover!: BookCover;
  @Input() isCoverJustCreated = false;

  @Output() back: EventEmitter<void> = new EventEmitter<void>();
  @Output() processing: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("wrapperRef") wrapperRef!: ElementRef;
  @ViewChild("canvasRef") canvasRef!: ElementRef;

  user!: User;

  stage!: Canvas;

  objects!: ObjectShape[];
  panSelectedObjects?: ObjectShape[] = [];
  selectionManager!: SelectionManager;
  lastInteractiveGroup?: FabricObject;

  coverBaseRect!: Rect;
  coverSafeMarginsRect!: Rect;
  maskOuterRect!: Rect;
  maskInnerRect!: Rect;
  previewModeMaskRect!: Rect;

  maskOuterRectStrokeColor = "rgba(41, 43, 75, 0.64)";

  previewModeMaskRectStrokeColor = "rgba(41, 43, 75, 1)";
  maskInnerRectStrokeColor = "#484A73";

  guidelines: Line[] = [];

  resizeObserver!: ResizeObserver;

  snapper: Snapper = new Snapper(snapTolerance);

  viewMode: ViewMode = "editing";

  fontsLoaded: FontFace[] = [];

  predefinedSvgObjects: PredefinedSvgObjects[] = [];

  isCompletionModalVisible = false;
  isImageSelectionModalVisible = false;

  sidebarMode: SidebarMode = "object-create";

  undoRedo: SimpleUndoRedo = new SimpleUndoRedo();

  panPrevPoint?: Point;
  editingMode: EditingMode = "common";
  isPanning = false;

  isMouseInsideCanvas = true;

  isEyeDropperActive = false;
  eyeDropperSelectedColor?: Color;
  eyeDropperPosition?: Position;
  onEyeDroperSelected?: (color: Color) => void;

  protected isImageGeneratorVisible = false;

  protected isShareModalVisible = false;

  protected imageSize = {
    width: 148,
    height: 210,
  };

  protected processingImageObject: ImageObject | undefined = undefined;
  protected processingImageGenenerationId: number | undefined = undefined;

  protected isLowBalanceModalVisible = false;
  tariffsForUpgrade$!: Observable<Tariff[]>;
  protected activeSubscription?: ActiveSubscription;
  protected higherTariff?: Tariff;
  protected hasPaidTariff = false;
  protected hasTrialPeriod = false;
  protected balance!: UserBalance;
  protected imageGenerationPaymentData!: BalanceData;
  protected prices?: ImageGenerationPrices;
  protected imageGenerationMode: FabulGenerationMode | undefined = undefined;
  objectPosition!: ObjectPosition;

  protected rewardsOneTime: UserRewardOneTime[] = [];

  sub: Subscription = new Subscription();

  protected readonly updateCoverStates$: Subject<void> = new Subject<void>();

  protected coverConceptualGeneration?: CoverConceptualGeneration;
  isCoverConceptualModalVisible = false;
  isCoverConceptualGenerationStarted = false;

  protected coverSnippetSidebarMaxWidth = 280;
  protected coverSnippetCategoryObjects: CoverSnippetCategoryObjects[] = [];
  protected coverSnippetObjectsForCreate: ObjectShape[] = [];
  protected isCoverSnippetPublishingModalVisible = false;

  protected coverSnippetScrollPositionState?: CoverSnippetScrollPositionState;

  constructor(
    private readonly coverService: CoverService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly themeService: ThemeService,
    private readonly userStore: UserStore,
    private readonly coverTemplateStore: CoverTemplateStore,
    private readonly loadingService: LoadingService,
    @Inject(COVER_TEXT_STORAGE) private readonly textStorageService: CoverTextStorage,
    private readonly onboardingService: OnboardingService,
    private readonly coverUiService: CoverUiService,
    private readonly textGenerationService: TextGenerationService,
    private readonly publishedImageStore: PublishedImageStore,
    private readonly colorConverter: ColorConverterService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly coverFontsService: CoverFontsService,
    private readonly realtimeService: RealtimeService,
    private readonly destroyRef: DestroyRef,
    private readonly fabulaImageGenerationService: FabulaImageGenerationService,
    private readonly imageGenerationService: ImageGenerationService,
    private readonly pricingService: PricingService,
    rewardsStore: RewardsStore,
    private readonly rewardsService: RewardsService,
    private readonly coverConceptualGenerationStore: CoverConceptualGenerationStore,
    private readonly coverSnippetService: CoverSnippetService,
    private readonly coverSnippetDataService: CoverSnippetDataService,
    private readonly coverSnippetCreateService: CoverSnippetCreateService,
    private readonly coverSnippetStore: CoverSnippetStore,
    private readonly coverConceptualFormService: CoverConceptualFormService,
  ) {
    this.subscribeToRealtimeServiceEvents();
    this.subscribeToUserStoreEvents();
    this.subscribeToCoverUiServiceEvents();

    this.watchTheme();

    const customizer = new FabricCustomizer();
    customizer.setRotationIcon();

    this.tariffsForUpgrade$ = combineLatest([
      userStore.getActiveSubscriptionObservable(),
      pricingService.getTariffsForCompany(),
    ]).pipe(
      map(([subscription, tariffs]) => ({ subscription, tariffs: tariffs.filter((v) => v.isFree === false) })),
      map((info) => {
        if (!info.subscription || info.subscription.tariff.isFree) {
          return info.tariffs.filter((t) => t.period === 1);
        }
        return info.tariffs.filter((t) => t.period === info.subscription?.tariff.period);
      }),
    );

    this.sub.add(
      rewardsStore.getRewardsOneTimeObservable().subscribe((rewards) => {
        this.rewardsOneTime = rewards;
      }),
    );
  }

  ngOnInit() {
    this.updateCoverStates$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((_) => this.saveCoverState()),
        debounceTime(500),
        tap(async () => await this.saveCoverAsync()),
      )
      .subscribe();

    this.imageSize = {
      width: this.cover.width || 148,
      height: this.cover.height || 210,
    };

    // TODO: add catch
    Promise.all([
      this.loadFontsAsync(),
      this.loadCoverSnippetsAsync(),
      this.loadPredefinedSvgShapesAsync(),
      this.loadImageGenerationPricesAsync(),
    ]);

    this.loadLastCoverConceptualGeneration();

    this.subscribeToCoverConceptualGenerationEvents();
    this.subscribeToCoverConceptualGenerationStep2Events();

    this.subscribeToCoverSnippetsEvents();

    /* await this.loadFontsAsync();
    await this.loadPredefinedSvgShapesAsync();
    await this.loadImageGenerationPricesAsync(); */
  }

  subscribeToCoverConceptualGenerationEvents() {
    this.sub.add(
      this.coverConceptualGenerationStore
        .getCoverConceptualGenerationObservable()
        .subscribe((coverConceptualGeneration) => {
          this.coverConceptualGeneration = coverConceptualGeneration;
          this.changeDetector.markForCheck();
        }),
    );
  }

  subscribeToCoverConceptualGenerationStep2Events() {
    this.sub.add(
      this.textGenerationService.coverConceptualGenerationStep2Updates$
        .pipe(filter((x) => x.coverId === this.cover.id))
        .subscribe((coverConceptualGenerationStep2) => {
          this.onCoverConceptualGenerationStep2Change(coverConceptualGenerationStep2);
        }),
    );
  }

  subscribeToRealtimeServiceEvents() {
    this.realtimeService
      .getEvents<BookCover>("book-cover-state")
      .pipe(filterUndefined())
      .subscribe((bookCoverUpdate: BookCover) => {
        if (this.cover.id === bookCoverUpdate.id) {
          this.cover = bookCoverUpdate;
          this.changeDetector.markForCheck();
        }
      });

    this.realtimeService
      .getEvents<FabulaImageGenerationResultUpdate>("image-enhancement-result")
      .pipe(filterUndefined())
      .subscribe(async (imageGenerationUpdate: FabulaImageGenerationResultUpdate) => {
        if (imageGenerationUpdate.isError) {
          this.notifyOnImageGenerationError(imageGenerationUpdate.mode);
        }

        if (this.processingImageGenenerationId !== imageGenerationUpdate.imageGeneration.id) {
          return;
        }
        await this.processGeneratedImagesAsync(imageGenerationUpdate.imageGeneration.generatedImages);
        this.onProccesingData(false);
      });
  }

  subscribeToUserStoreEvents() {
    this.sub.add(
      this.userStore
        .getUserObservable()
        .pipe(filterUndefined())
        .subscribe((user) => {
          this.user = user;
        }),
    );

    this.sub.add(
      this.userStore
        .getBalanceObservable()
        .pipe(filterUndefined())
        .subscribe((balance) => {
          this.balance = balance;
        }),
    );
  }

  subscribeToCoverUiServiceEvents() {
    this.coverUiService.updateGroup$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (v) => {
      await this.updateGroupAsync(v);
    });

    this.coverUiService.renameObject$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (v) => {
      await this.renameObjectAsync(v);
    });
  }

  get viewModeIcon() {
    if (this.viewMode === "editing") {
      return "/assets/icons/frame-01.svg";
    }
    return "/assets/icons/frame-no-01.svg";
  }

  get currentObject(): ObjectShape | undefined {
    return this.selectionManager?.currentObject;
  }

  get selectedObjects(): ObjectShape[] {
    return this.selectionManager?.selectedObjects ?? [];
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.checkCoverData();

    if (!this.stage) {
      return;
    }

    this.undoRedo.reset();
    this.saveCoverState();
    if (!changes.cover.firstChange) {
      await this.onCoverAsync();
    }
  }

  /* getActiveSubscription() {
    this.sub.add(
      this.userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        this.activeSubscription = activeSubscription;
        this.hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
        this.hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;

        if (!this.activeSubscription) {
          return;
        }
        this.getHigherTariff();
      }),
    );
  } */

  private watchTheme() {
    this.themeService.changeEvents$.pipe(untilDestroyed(this)).subscribe((v) => {
      this.maskOuterRectStrokeColor = v === "dark" ? "rgba(41, 43, 75, 0.64)" : "rgba(255, 255, 255, 0.64)";
      this.previewModeMaskRectStrokeColor = v === "dark" ? "rgba(41, 43, 75, 1)" : "rgba(255, 255, 255, 1)";
      this.maskInnerRectStrokeColor = v === "dark" ? "#484A73" : "#E1E1EB";
      const maskOuterRect = this.stage?.getObjects().find((i: any) => i.name === "maskOuterRect");
      const maskInnerRect = this.stage?.getObjects().find((i: any) => i.name === "maskInnerRect");
      const previewModeMaskRect = this.stage?.getObjects().find((i: any) => i.name === "previewModeMask");
      if (maskOuterRect) {
        maskOuterRect.stroke = this.maskOuterRectStrokeColor;
      }
      if (previewModeMaskRect) {
        previewModeMaskRect.stroke = this.previewModeMaskRectStrokeColor;
      }
      if (maskInnerRect) {
        maskInnerRect.stroke = this.maskInnerRectStrokeColor;
      }
      if (maskOuterRect && maskInnerRect) {
        //maskOuterRect.render(this.stage.getContext());
        //maskInnerRect.render(this.stage.getContext());
        this.stage.renderAll();
      }
    });
  }

  async loadCoverSnippetsAsync() {
    console.log("loading snippets objects ...");
    await this.coverSnippetService.loadCoverSnippets();
    console.log("snippets objects loaded");
  }

  subscribeToCoverSnippetsEvents() {
    this.sub.add(
      this.coverSnippetStore.getCoverSnippetsObservable().subscribe((coverSnippets) => {
        this.proccessCoverSnippets(coverSnippets);
      }),
    );
  }

  async loadPredefinedSvgShapesAsync() {
    console.log("loading predefined svg objects ...");
    this.predefinedSvgObjects = await this.coverService.getPredefinedSvgObjects();
    console.log("predefined svg objects loaded");
  }

  async loadFontsAsync() {
    console.log("loading fonts ...");
    const fonts$ = this.coverFontsService.getCompleteSet();
    const loaded$ = fonts$.pipe(
      switchMap((v) => this.coverFontsService.loadFontFaces(v)),
      map((v) => {
        v.sort((a, b) => a.family.localeCompare(b.family));
        return v;
      }),
    );
    this.fontsLoaded = await lastValueFrom(loaded$);
    //await lastValueFrom(of({}).pipe(delay(25000)));
    this.coverFontsService.announceFontsLoaded(this.fontsLoaded);
    console.log("fonts loaded");
  }

  constructGroupIds() {
    const groups = this.cover.objects?.filter((i) => this.isGroupAlike(i));
    if (!groups) {
      return;
    }
    groups.forEach((v, i) => {
      (<GroupAlike>v).groupId = i.toString();
    });
  }

  async onCoverAsync(alertIfNoImage = true, showLoader = true, loadTextFromStorage = false) {
    this.constructGroupIds();
    if (loadTextFromStorage) {
      await this.textStorageService.toCoverAsync(this.cover);
    }
    await this.constructStageAsync(this.cover.objects ?? [], alertIfNoImage, showLoader);
  }

  async saveCoverAsync() {
    const dto = instanceToPlain(this.cover, { excludeExtraneousValues: true });
    await this.textStorageService.fromCoverAsync(this.cover);
    await this.coverService.updateCover(this.book.id, dto);
    await this.saveCoverPreviewImageAsync();
  }

  async uploadCoverImageAsync(data: Blob, fullsize: boolean) {
    const file = DataHelper.blobToFile(data, "cover.png");
    return await this.coverService.uploadImage(this.book.id, file, fullsize ? "cover-fullsize" : "cover-preview");
  }

  getCoverImageBlob(multiplier: number): Blob {
    const currentViewMode = this.viewMode;

    this.setViewMode("final");

    if (this.lastInteractiveGroup) {
      this.hideMaskLayers(this.lastInteractiveGroup as Group);
    }

    const dimensions = { width: this.stage.getWidth(), height: this.stage.getHeight() };
    const zoom = this.stage.getZoom();
    const viewportTransform = this.stage.viewportTransform;

    this.stage.setZoom(1);
    this.stage.setDimensions({
      width: this.coverBaseRect.width! * this.stage.getZoom(),
      height: this.coverBaseRect.height! * this.stage.getZoom(),
    });

    this.stage.absolutePan(
      new Point({
        x: this.coverBaseRect.left!,
        y: this.coverBaseRect.top!,
      }),
    );

    const base64 = this.saveStageToBase64(this.stage, multiplier);

    this.stage.setZoom(zoom);
    this.stage.setDimensions(dimensions);
    this.centerStage();

    this.stage.setViewportTransform(viewportTransform!);

    if (this.lastInteractiveGroup) {
      this.showMaskLayers(this.lastInteractiveGroup as Group);
    }

    this.setViewMode(currentViewMode);

    const blob = DataHelper.base64ToBlob(base64, "image/png");
    return blob;
  }

  constructCoverBaseShapeAndMask() {
    this.coverBaseRect = new Rect({
      /* originX: "center",
      originY: "center",
      left: this.cover.width / 2,
      top: this.cover.height / 2, */
      originX: "left",
      originY: "top",
      left: 0,
      top: 0,
      width: this.cover.width,
      height: this.cover.height,
      strokeWidth: 0,
      fill: this.cover.backgroundColor?.toCss(),
      selectable: false,
      evented: false,
      name: "coverBaseRect",
      objectCaching: false,
      absolutePositioned: true,
    });

    this.coverSafeMarginsRect = new Rect({
      left: safeMarginValue,
      top: safeMarginValue,
      width: this.cover.width! - safeMarginValue * 2 - safeMarginWidth,
      height: this.cover.height! - safeMarginValue * 2 - safeMarginWidth,
      fill: undefined,
      strokeWidth: safeMarginWidth,
      stroke: colorActive,
      selectable: false,
      evented: false,
      strokeDashArray: [4, 4],
      strokeUniform: true,
      name: "safeMarginRect",
      objectCaching: false,
    });

    this.stage.add(this.coverBaseRect);
    this.stage.sendObjectToBack(this.coverBaseRect);

    this.stage.add(this.coverSafeMarginsRect);
    this.stage.bringObjectToFront(this.coverSafeMarginsRect);

    this.maskOuterRect = new Rect({
      left: 0 - maskOuterStrokeWidth,
      top: 0 - maskOuterStrokeWidth,
      width: this.cover.width! + maskOuterStrokeWidth,
      height: this.cover.height! + maskOuterStrokeWidth,
      selectable: false,
      evented: false,
      name: "maskOuterRect",
      fill: "rgba(0,0,0,0)",
      strokeWidth: maskOuterStrokeWidth,
      stroke: this.maskOuterRectStrokeColor,
      objectCaching: false,
    });

    this.maskInnerRect = new Rect({
      left: 0 - maskInnerStrokeWidth,
      top: 0 - maskInnerStrokeWidth,
      width: this.cover.width! + maskInnerStrokeWidth,
      height: this.cover.height! + maskInnerStrokeWidth,
      selectable: false,
      evented: false,
      name: "maskInnerRect",
      fill: "rgba(0,0,0,0)",
      strokeWidth: maskInnerStrokeWidth,
      stroke: this.maskInnerRectStrokeColor,
      objectCaching: false,
    });

    this.previewModeMaskRect = new Rect({
      left: 0 - maskOuterStrokeWidth,
      top: 0 - maskOuterStrokeWidth,
      width: this.cover.width! + maskOuterStrokeWidth,
      height: this.cover.height! + maskOuterStrokeWidth,
      selectable: false,
      evented: false,
      name: "previewModeMask",
      fill: "rgba(0,0,0,0)",
      strokeWidth: maskOuterStrokeWidth,
      stroke: this.previewModeMaskRectStrokeColor,
      objectCaching: false,
    });

    this.stage.add(this.maskOuterRect);
    this.stage.add(this.maskInnerRect);
    this.stage.add(this.previewModeMaskRect);
    // заменили на bringObjectToFront(). Есть только у Group
    this.stage.bringObjectToFront(this.maskOuterRect);
    this.stage.bringObjectToFront(this.previewModeMaskRect);
    this.stage.bringObjectToFront(this.maskInnerRect);

    this.centerStage();
  }

  toggleViewMode() {
    this.setViewMode(this.viewMode === "editing" ? "preview" : "editing");
  }

  setViewMode(mode: ViewMode) {
    this.viewMode = mode;
    this.coverSafeMarginsRect.visible = mode === "editing";
    this.maskOuterRect.visible = mode === "editing";
    this.previewModeMaskRect.visible = mode !== "editing";
    this.maskInnerRect.visible = mode !== "final";

    /* const objects = this.stage.getObjects().filter((v: any) => v.name === "object");

    const clipPath = mode === "editing" ? undefined : this.coverBaseRect;
    for (const object of objects) {
      object.clipPath = clipPath;
      object.dirty = true;
      if (object instanceof Group) {
        for (const o of object.getObjects()) {
          o.clipPath = clipPath;
          o.dirty = true;
        }
      }
    } */

    this.stage.renderAll();
  }

  centerStage() {
    if (this.cover) {
      this.stage.absolutePan(
        new Point({
          x: -(this.stage.width! - this.cover.width! * this.stage.getZoom()) / 2,
          y: -(this.stage.height! - this.cover.height! * this.stage.getZoom()) / 2,
        }),
      );
    }
  }

  async getCoverFontFamiliesAsync(): Promise<FontFace[]> {
    if (!this.cover.objects) {
      return [];
    }
    const families: string[] = [];
    for (const o of this.cover.objects) {
      if (o instanceof TextObject && !families.some((i) => i === o.fontFamily) && o.fontFamily) {
        families.push(o.fontFamily);
      }
    }
    return lastValueFrom(this.coverFontsService.getSubset(families));
  }

  async constructStageAsync(objects: ObjectShape[], alertIfNoImage = true, showLoader = true) {
    console.log("constructing stage ...");
    if (showLoader) {
      this.loadingService.startLoading({ fullPage: true });
    }

    this.changeDetector.markForCheck();

    this.objects = [];
    this.selectionManager.clear();

    this.stage.clear();

    if (this.fontsLoaded.length === 0) {
      console.log("loading cover fonts ...");
      const fonts = await this.getCoverFontFamiliesAsync();
      await lastValueFrom(this.coverFontsService.loadFontFaces(fonts));
      console.log("cover fonts loaded");
    }

    console.log("creating object shapes ...");
    const v = await this.createObjectShapesAsync([...objects]);
    console.log("object shapes created");
    this.objects = v.objects.sort((a, b) => a.zIndex - b.zIndex);
    this.addObjectsToStage(v.objects);
    this.constructCoverBaseShapeAndMask();
    this.setViewMode(this.viewMode);
    // TODO: check multiple saves to backend
    await this.saveCoverPreviewImageAsync();
    this.loadingService.stopLoading();
    this.changeDetector.markForCheck();
    console.log("stage constructed");

    /* if (alertIfNoImage && !this.cover.objects?.some((v) => v instanceof ImageObject)) {
      // this.isImageSelectionModalVisible = true;

      if (!this.coverConceptualGeneration) {
        this.isCoverConceptualModalVisible = true;
      }
    } */

    if (v.correctionsApplied) {
      await this.saveCoverAsync();
    }
  }

  addObjectsToStage(objects: ObjectShape[]) {
    this.stage.add(...objects.map((v) => v.shape!));
  }

  async createObjectShapesAsync(
    objects: CoverObject[],
    resizeImages = false,
    addListeners = true,
  ): Promise<ObjectCreatedModel> {
    const result = <ObjectCreatedModel>{
      objects,
      correctionsApplied: false,
    };
    if (objects.length === 0) {
      return result;
    }

    for (const o of objects) {
      let shape: FabricObject;

      if (o instanceof ImageObject) {
        let url = defaultImageUrl;
        if (o.imageUrl && o.imageUrl !== "default") {
          url = this.coverService.getObjectImageUrl(this.book.id, o.imageUrl);
        }

        if (o.fileId) {
          url = this.coverSnippetService.getUrlForPreviewImageByFileVid(o.fileId);
        }

        const image = await FabricImage.fromURL(url, {
          crossOrigin: "anonymous",
        });
        shape = image;

        this.initializeObjectShape(o, shape, resizeImages, addListeners);
        if ((!o.width || !o.height) && shape.width && shape.height) {
          o.width = shape.width;
          o.height = shape.height;
          result.correctionsApplied = true;
        }
      } else if (o instanceof SvgObject) {
        let svgUrl = o.imageUrl;
        if (o.fileId) {
          svgUrl = this.coverSnippetService.getUrlForPreviewImageByFileVid(o.fileId);
        }
        if (!svgUrl) {
          throw new Error("SvgObject must have imageUrl or fileId set");
        }

        const svgParsingOutput = await loadSVGFromURL(svgUrl, undefined, {
          crossOrigin: "anonymous",
        });
        const image = util.groupSVGElements(svgParsingOutput.objects as FabricObject[], svgParsingOutput.options);
        shape = image;
        this.initializeObjectShape(o, shape, resizeImages, addListeners);
        if ((!o.width || !o.height) && shape.width && shape.height) {
          o.width = shape.width;
          o.height = shape.height;
          result.correctionsApplied = true;
        }
      } else if (o instanceof GroupObject) {
        shape = new Group();
        (shape as Group).subTargetCheck = true;
        const v = await this.createObjectShapesAsync(o.objects, resizeImages);
        const shapes = v.objects.map((i) => (i as ObjectShape).shape!);

        for (const s of shapes) {
          (shape as Group).add(s);
        }

        this.initializeObjectShape(o, shape, resizeImages, addListeners);
      } else if (o instanceof MaskGroupObject) {
        shape = new Group();
        (shape as Group).subTargetCheck = true;
        /* this.createObjectShapesAsync(o.objects, resizeImages).then((v) => {
          const shapes = v.objects.map((i) => (i as ObjectShape).shape!);

          for (const s of shapes) {
            (shape as Group).add(s);
          }

          this.initializeObjectShape(o, shape, resizeImages, addListeners);
          shapesCreated++;
          if (shapesCreated === objects.length) {
            resolve(result);
          }
        }); */

        // for (const s of maskShapes) {
        //   (shape as Group).add(s);
        // }

        const objects = await this.createObjectShapesAsync(o.objects, resizeImages);

        const maskShapes = objects.objects.filter((i) => i.isMask).map((i) => i.shape as FabricObject);

        let maskIndex = 0;
        for (const s of maskShapes) {
          s.set({ name: `mm-mask-${maskIndex++}` });
          (shape as Group).add(s);
        }

        const shapes = objects.objects.filter((i) => !i.isMask).map((i) => (i as ObjectShape).shape!);
        for (const s of shapes) {
          (shape as Group).add(s);
        }
        this.initializeObjectShape(o, shape, resizeImages, addListeners);

        await this.recolorMaskShapesToCheckers(maskShapes);

        if (maskShapes.length > 0) {
          const clone = await maskShapes[0].clone();
          shape.set({
            clipPath: clone,
            dirty: true,
          });
        }
        this.hideMaskLayers(shape as Group);
      } else {
        if (o instanceof TextObject) {
          shape = new Textbox(o.text ?? defaultText);
        } else if (o instanceof RectangleObject) {
          shape = new Rect();
        } else if (o instanceof EllipseObject) {
          shape = new Ellipse();
        } else {
          //reject(new Error("Unsupported object type"));
          throw new Error("Unsupported object type");
        }

        this.initializeObjectShape(o, shape, resizeImages, addListeners);
      }
    }
    return result;
  }

  updateObjectFromShape(object: ObjectShape, shape: FabricObject) {
    const selection = shape.group;

    const matrix = shape.calcTransformMatrix();
    const options = util.qrDecompose(matrix);

    let x = shape?.left;
    let y = shape?.top;

    if (selection && selection instanceof Group && shape.aCoords && !selection.isType("activeselection")) {
      // when moving item in group, we should rearrange all nested objects position, as group origin is always in center, not in top left
      // https://github.com/fabricjs/fabric.js/issues/10035#issuecomment-2271208727
      x = shape.left;
      y = shape.top;
      const groupObject = this.findObjectForShape(selection);
      if (groupObject) {
        groupObject.x = selection.getXY().x;
        groupObject.y = selection.getXY().y;

        for (const nested of selection.getObjects()) {
          const nestedObject = (groupObject as GroupAlike).objects.find((v) => (v as ObjectShape).shape === nested);
          if (nestedObject) {
            nestedObject.x = nested.left;
            nestedObject.y = nested.top;
          }
        }
      }
    } else if (selection && shape.aCoords) {
      // handle selecition
      // get position on canvas, instead of position in selection
      const groupmatrix = selection.calcTransformMatrix();
      const point = shape.aCoords.tl.transform(groupmatrix);
      x = point.x;
      y = point.y;
    }

    object.x = x;
    object.y = y;

    object.rotationAngle = options.angle;

    /* object.scaleX = Math.abs(options.scaleX);
    object.scaleY = Math.abs(options.scaleY); */

    /* object.scaleX = shape.scaleX;
    object.scaleY = shape.scaleY;
    object.skewX = shape.skewX;
    object.skewY = shape.skewY; */

    object.scaleX = options.scaleX;
    object.scaleY = options.scaleY;
    object.skewX = options.skewX;
    object.skewY = options.skewY;

    if (object instanceof TextObject && shape instanceof Textbox) {
      object.lineHeight = ((shape.lineHeight || 0) === 0 ? 1 : shape.lineHeight!) * 100;
      object.fontSize = (shape.fontSize || 0) === 0 ? 24 : shape.fontSize!;
      object.width = shape.width;
    } else if (object instanceof ShapeObject) {
      object.width = shape.width;
      object.height = shape.height;
    }

    this.updateObjectPosition();
  }

  get isGradientApplicable(): boolean {
    const object = this.currentObject;
    if (!object) {
      return false;
    }
    if (object instanceof SvgObject && object.shape instanceof Group) {
      return false;
    }
    return true;
  }

  updateShapeFromObject(shape: FabricObject, object: ObjectShape) {
    if (shape instanceof Textbox) {
      if (shape.isEditing) {
        shape.exitEditing();
      }
    }

    let newShapeAttributes: any = {};

    if (!shape.group) {
      shape.rotate(object.rotationAngle ?? 0);
      const newGroupAttributes = {
        scaleX: object.scaleX ?? 1,
        scaleY: object.scaleY ?? 1,
        skewX: object.skewX ?? 0,
        skewY: object.skewY ?? 0,
        left: object.x ?? 0,
        top: object.y ?? 0,
      };
      newShapeAttributes = { ...newShapeAttributes, ...newGroupAttributes };
    }

    newShapeAttributes.opacity = (object.opacity ?? 100) / 100;

    newShapeAttributes.lockMovementX = object.isLocked || false;
    newShapeAttributes.lockMovementY = object.isLocked || false;
    newShapeAttributes.lockRotation = object.isLocked || false;
    newShapeAttributes.lockScalingFlip = object.isLocked || false;
    newShapeAttributes.lockScalingX = object.isLocked || false;
    newShapeAttributes.lockScalingY = object.isLocked || false;
    newShapeAttributes.lockSkewingX = object.isLocked || false;
    newShapeAttributes.lockSkewingY = object.isLocked || false;
    newShapeAttributes.selectable = !object.isLocked;
    newShapeAttributes.evented = !object.isLocked;

    newShapeAttributes.visible = object.isVisible;

    if (object instanceof TextObject && shape instanceof Textbox) {
      const newTextAttributes: any = {};
      newTextAttributes.text =
        object.textCase && object.text
          ? object.textCase === TextCase.Auto
            ? object.text
            : this.updateCase(object.text, object.textCase)
          : object.text || "";

      newTextAttributes.fontSize = (object.fontSize || 0) === 0 ? 24 : object.fontSize!;

      newTextAttributes.lineHeight = ((object.lineHeight || 0) === 0 ? 100 : object.lineHeight!) / 100;
      newTextAttributes.charSpacing = object.letterSpacing || 0;
      newTextAttributes.textAlign = object.textAlign ?? "left";
      newTextAttributes.fontFamily = object.fontFamily ?? defaultFontFamily;

      newTextAttributes.underline = object.underline || false;
      newTextAttributes.fontWeight = object.bold ? "bold" : "normal";
      newTextAttributes.fontStyle = object.italic ? "italic" : "normal";
      newTextAttributes.linethrough = object.linethrough || false;

      clearFabricFontCache(shape.fontFamily);

      if (!object.width) {
        this.applyOptimalTextboxWidth(shape);
      } else {
        newTextAttributes.width = object.width;
      }
      if (object.shadow) {
        newTextAttributes.shadow = new Shadow({
          blur: object.shadow.blur && object.shadow.blur / 10,
          offsetX: object.shadow.offset && object.shadow.offset * Math.cos((object.shadow.direction! * Math.PI) / 180),
          offsetY: object.shadow.offset && object.shadow.offset * Math.sin((object.shadow.direction! * Math.PI) / 180),
          color: object.shadow.color,
        });
      } else {
        newTextAttributes.shadow = object.shadow;
      }
      this.applyFill(shape, object.fill);
      newShapeAttributes = { ...newShapeAttributes, ...newTextAttributes };
    } else if (object instanceof ShapeObject) {
      const newFigureAttributes: any = {};
      if (!(object instanceof SvgObject)) {
        newFigureAttributes.width = object.width ?? 0;
        newFigureAttributes.height = object.height ?? 0;
      }

      newFigureAttributes.strokeWidth = (object.strokeWidth ?? 0) / 1;
      newFigureAttributes.stroke = (object.strokeFill as SolidFill)?.color?.toCss();

      if (object instanceof EllipseObject && shape instanceof Ellipse) {
        newFigureAttributes.rx = object.width! / 2;
        newFigureAttributes.ry = object.height! / 2;
      }

      if (object instanceof RectangleObject && shape instanceof Rect) {
        newFigureAttributes.rx = object.cornerRadius ?? 0;
        newFigureAttributes.ry = object.cornerRadius ?? 0;
      }

      if (object instanceof SvgObject && shape instanceof Group) {
        for (const s of shape.getObjects()) {
          newFigureAttributes.strokeWidth = (object.strokeWidth ?? 0) / 1;
          newFigureAttributes.stroke = (object.strokeFill as SolidFill)?.color?.toCss();
          this.applyFill(s, object.fill);
        }
      }
      this.applyFill(shape, object.fill);
      newShapeAttributes = { ...newShapeAttributes, ...newFigureAttributes };
    } else if (this.isGroupAlike(object)) {
      for (const o of object.objects) {
        const os = o as ObjectShape;
        this.updateShapeFromObject(os.shape!, os);
      }
    }
    shape.set(newShapeAttributes);
    shape.setCoords();
  }

  private updateCase(text: string, textCase: TextCase): string {
    if (textCase === TextCase.Upper) {
      return text.toUpperCase();
    }
    if (textCase === TextCase.Lower) {
      return text.toLowerCase();
    }
    return text;
  }

  private checkCoverData() {
    if (!this.cover.objects) {
      return;
    }
    // TODO implement versioning and migrations
    for (const object of this.cover.objects) {
      if (object.isVisible === undefined) {
        object.isVisible = true;
      }
    }
  }

  private applyOptimalTextboxWidth(shape: Textbox): void {
    // shape.width = shape.dynamicMinWidth ? shape.dynamicMinWidth * 2 : shape.width;
    /* if (!shape.left || !shape.width || !this.cover.width) {
      return;
    }

    let iters = 0;
    const originalWidth = shape.width;
    while (shape.textLines.length > 1 && shape.width <= this.cover.width - shape.left) {
      shape.set({ width: shape.getScaledWidth() + 1 });
      if (++iters > 10) {
        shape.set({ width: originalWidth });
        break;
      }
    } */
    /* if (!shape.left || !shape.width || !this.cover.width) {
      return;
    }
    while (shape.textLines.length > 1 && shape.width <= this.cover.width - shape.left) {
      shape.set({ width: shape.getScaledWidth() + 1 });
    } */
  }

  applyFill(shape: FabricObject, fill: Fill) {
    if (fill instanceof SolidFill) {
      shape.fill = fill.color.toCss();
    } else if (fill instanceof GradientFill) {
      const gradient = fill.gradient;
      if (gradient.colorStops.length === 0) {
        return;
      }

      // let coords: fabric.IGradientOptionsCoords;
      let coords: any;

      if (gradient.type === "linear") {
        coords = {
          x1: 0,
          y1: 0,
          x2: 1,
          y2: 0,
        };
      } else {
        coords = {
          x1: 0.5,
          y1: 0.5,
          x2: 0.5,
          y2: 0.5,
          r1: 0,
          r2: 0.5,
        };
      }

      const colorsCount = gradient.colorStops.length;

      shape.fill = new Gradient({
        type: gradient.type,
        gradientUnits: "percentage",
        coords,
        colorStops: [
          ...gradient.colorStops.map((v, i) => ({
            offset: i / (colorsCount - 1),
            color: v.color.toCss(),
          })),
        ],
      }) as FabricObject["fill"];
    }
  }

  setCurrentObjects(objects: ObjectShape[]) {
    this.selectionManager.selectObjects(objects, true);
  }

  async createObjectAsync(event: CreateCoverObject, needToStopLoading = true) {
    const length = this.objects.length;
    let coverObject: CoverObject | undefined = undefined;
    if (event.type === CoverObjectType.Text) {
      const textObject = new TextObject();
      textObject.id = undefined;
      textObject.name = `Text ${length}`;
      textObject.x = 10;
      textObject.y = 20;
      textObject.text = `Text ${length}`;
      textObject.fontFamily = defaultFontFamily;
      textObject.fontSize = 24;
      textObject.lineHeight = 100;
      textObject.letterSpacing = 0;
      textObject.textAlign = "left";
      textObject.fill = new SolidFill();
      textObject.zIndex = length + 1;

      coverObject = textObject;
    } else if (event.type === CoverObjectType.Image) {
      const imageObject = new ImageObject();
      imageObject.id = undefined;
      imageObject.name = `Image ${length}`;
      imageObject.x = 0;
      imageObject.y = 0;
      imageObject.imageUrl = event.imageName!;
      imageObject.zIndex = this.cover.objects?.some((v) => v instanceof ImageObject) ? length + 1 : 0;
      imageObject.needRescale = true;

      coverObject = imageObject;
    } else if (event.type === CoverObjectType.Rectangle) {
      const rectangleObject = new RectangleObject();
      rectangleObject.id = undefined;
      rectangleObject.name = `Rectangle ${length}`;
      rectangleObject.x = 10;
      rectangleObject.y = 20;
      rectangleObject.width = 100;
      rectangleObject.height = 100;
      rectangleObject.fill = new SolidFill();
      rectangleObject.strokeWidth = 0;
      rectangleObject.strokeFill = new SolidFill();
      rectangleObject.zIndex = length + 1;

      coverObject = rectangleObject;
    } else if (event.type === CoverObjectType.Ellipse) {
      const ellipseObject = new EllipseObject();
      ellipseObject.id = undefined;
      ellipseObject.name = `Ellipse ${length}`;
      ellipseObject.x = 10;
      ellipseObject.y = 20;
      ellipseObject.width = 100;
      ellipseObject.height = 100;
      ellipseObject.fill = new SolidFill();
      ellipseObject.strokeWidth = 0;
      ellipseObject.strokeFill = new SolidFill();
      ellipseObject.zIndex = length + 1;

      coverObject = ellipseObject;
    } else if (event.type === CoverObjectType.SVG) {
      const svgObject = new SvgObject();
      svgObject.id = undefined;
      svgObject.name = `Shape ${length}`;
      svgObject.x = 10;
      svgObject.y = 20;
      svgObject.fill = new SolidFill();
      svgObject.strokeWidth = 0;
      svgObject.strokeFill = new SolidFill();
      svgObject.imageUrl = event.svgData;
      svgObject.zIndex = length + 1;

      coverObject = svgObject;
    } else if (event.type === CoverObjectType.Snippet) {
      coverObject = await this.getCoverSnippetObject(event.coverSnippet, length);
    }
    if (coverObject) {
      this.loadingService.startLoading({ fullPage: true });
      this.cover.objects?.push(coverObject);

      const v = await this.createObjectShapesAsync([coverObject], true);

      this.objects.push(...v.objects);
      this.addObjectsToStage(v.objects);
      this.stage.setActiveObject(v.objects[0].shape!);
      this.stage.bringObjectToFront(this.coverSafeMarginsRect);
      this.stage.bringObjectToFront(this.maskOuterRect);
      this.stage.bringObjectToFront(this.previewModeMaskRect);
      this.stage.bringObjectToFront(this.maskInnerRect);

      if (coverObject?.zIndex === 0) {
        this.objects.sort((a, b) => a.zIndex - b.zIndex);
        this.stage.sendObjectToBack(v.objects[0].shape!);
        this.stage.bringObjectForward(v.objects[0].shape!);
        this.stage.sendObjectToBack(this.coverBaseRect);
      }

      this.stage.renderAll();
      this.updateCoverStates$.next();
      if (needToStopLoading) {
        this.loadingService.stopLoading();
      }
      this.changeDetector.markForCheck();
    }
  }

  async deleteObjectsAsync(objects: ObjectShape[]) {
    this.stage.discardActiveObject();
    this.stage.remove(...objects.map((v) => v.shape!));

    this.cover.objects = this.cover.objects?.filter((v) => !objects.some((o) => o === v));
    this.objects = this.objects?.filter((v) => !objects.some((o) => o === v));

    for (const object of objects) {
      const container = this.findContainerForObject(object);
      if (container && container !== this.objects) {
        container.splice(container.indexOf(object), 1);
      }
      if (object.shape?.group) {
        object.shape.group.remove(object.shape);
      }
    }

    this.updateCoverStates$.next();
    this.stage.renderAll();

    this.changeDetector.markForCheck();
  }

  async updateObjectAsync(object: ObjectShape) {
    this.updateShapeFromObject(object.shape!, object);
    object.shape!.dirty = true;
    this.stage.renderAll();
    this.updateCoverStates$.next();
  }

  async reorderObjectsAsync(objects: ObjectShape[]) {
    this.objects = [...this.objects]; // force change detection. we already changed this.objects in layers component
    this.resetZIndexes();
    this.changeDetector.detectChanges();
    this.updateCoverStates$.next();
  }

  private resetZIndexes() {
    this.resetZIndexInContaner(this.objects, this.stage, 3);
  }
  private resetZIndexInContaner(collection: ObjectShape[], fabricCollection: any, startindIndex: number) {
    let index = startindIndex;
    for (const object of collection) {
      const newIndex = index;
      index++;
      object.zIndex = newIndex;
      fabricCollection.moveObjectTo(object.shape!, newIndex);

      if (this.isGroupAlike(object)) {
        this.resetZIndexInContaner(object.objects, object.shape, 0);
      }
    }
    return index;
  }

  async renameObjectAsync(object: ObjectShape) {
    object.isNameModifiedByUser = true;
    this.updateCoverStates$.next();
  }

  async cloneObjectAsync(object: CoverObject) {
    const coverObject: CoverObject = plainToInstance(Object.getPrototypeOf(object).constructor, object, {
      excludeExtraneousValues: true,
    });
    const length = this.objects.length;
    coverObject.id = undefined;
    coverObject.x! += 10;
    coverObject.y! += 10;
    coverObject.zIndex = length + 1;
    coverObject.name += " [copy]";

    const container = this.findContainerForObject(object);
    if (coverObject && container) {
      this.loadingService.startLoading({ fullPage: true });
      if (container === this.objects) {
        // hack. When adding objects to groups - we don't need to modify cover
        this.cover.objects?.push(coverObject);
      }
      const v = await this.createObjectShapesAsync([coverObject], false);

      container.push(...v.objects);
      this.addObjectsToStage(v.objects);
      this.stage.setActiveObject(v.objects[0].shape!);
      this.stage.bringObjectToFront(this.coverSafeMarginsRect);
      this.stage.bringObjectToFront(this.maskOuterRect);
      this.stage.bringObjectToFront(this.previewModeMaskRect);
      this.stage.bringObjectToFront(this.maskInnerRect);
      this.updateCoverStates$.next();
      this.loadingService.stopLoading();
    }
  }

  private alignRelativeTo(objectsToAlign: FabricObject[], relativeToObject: FabricObject, alignment: ObjectsAlignment) {
    const selection = relativeToObject;
    if (!selection) {
      return;
    }
    const shapes = objectsToAlign;
    const selc = selection.getCenterPoint();

    for (const shape of shapes) {
      const sc = shape.getCenterPoint();
      const bb = shape.getBoundingRect();
      if (shapes.length > 1) {
        if (alignment === "center") {
          shape.setPositionByOrigin(new Point(0, -selc.y + sc.y), "center", "center");
        } else if (alignment === "middle") {
          shape.setPositionByOrigin(new Point(-selc.x + sc.x, 0), "center", "center");
        } else if (alignment === "center-middle") {
          shape.setPositionByOrigin(new Point(0, 0), "center", "center");
        } else if (alignment === "left") {
          shape.setPositionByOrigin(
            new Point(-selection.getScaledWidth() / 2 + bb.width / 2, -selc.y + sc.y),
            "center",
            "center",
          );
        } else if (alignment === "right") {
          shape.setPositionByOrigin(
            new Point(selection.getScaledWidth() / 2 - bb.width / 2, -selc.y + sc.y),
            "center",
            "center",
          );
        } else if (alignment === "top") {
          shape.setPositionByOrigin(
            new Point(-selc.x + sc.x, -selection.getScaledHeight() / 2 + bb.height / 2),
            "center",
            "center",
          );
        } else if (alignment === "bottom") {
          shape.setPositionByOrigin(
            new Point(-selc.x + sc.x, selection.getScaledHeight() / 2 - bb.height / 2),
            "center",
            "center",
          );
        }
        shape.setCoords();
      } else {
        const bb = shape.getBoundingRect();
        if (alignment === "center") {
          shape.setPositionByOrigin(
            new Point(selection.left! + selection.getScaledWidth() / 2, sc.y),
            "center",
            "center",
          );
        } else if (alignment === "middle") {
          shape.setPositionByOrigin(
            new Point(sc.x, selection.top! + selection.getScaledHeight() / 2),
            "center",
            "center",
          );
        } else if (alignment === "center-middle") {
          shape.setPositionByOrigin(
            new Point(
              selection.left! + selection.getScaledWidth() / 2,
              selection.top! + selection.getScaledHeight() / 2,
            ),
            "center",
            "center",
          );
        } else if (alignment === "left") {
          shape.setPositionByOrigin(new Point(selection.left! + bb.width / 2, sc.y), "center", "center");
        } else if (alignment === "right") {
          shape.setPositionByOrigin(
            new Point(selection.left! + selection.getScaledWidth() - bb.width / 2, sc.y),
            "center",
            "center",
          );
        } else if (alignment === "top") {
          shape.setPositionByOrigin(new Point(sc.x, selection.top! + bb.height / 2), "center", "center");
        } else if (alignment === "bottom") {
          shape.setPositionByOrigin(
            new Point(sc.x, selection.top! + selection.getScaledHeight() - bb.height / 2),
            "center",
            "center",
          );
        }
        shape.setCoords();
      }
    }
  }

  private allObjectsInOneGroup(shapes: FabricObject[]): boolean {
    const group = shapes[0].group;
    if (!group) {
      return false;
    }
    if (group.isType("activeselection")) {
      return false;
    }
    for (const shape of shapes) {
      if (shape.group !== group) {
        return false;
      }
    }
    return true;
  }

  private alignInGroup(objectsToAlign: FabricObject[], group: Group, alignment: ObjectsAlignment) {
    const shapes = objectsToAlign;

    for (const shape of shapes) {
      const sc = shape.getRelativeCenterPoint();
      const bb = shape.getBoundingRect();
      if (alignment === "center") {
        shape.setPositionByOrigin(new Point(0, sc.y), "center", "center");
      } else if (alignment === "middle") {
        shape.setPositionByOrigin(new Point(sc.x, 0), "center", "center");
      } else if (alignment === "center-middle") {
        shape.setPositionByOrigin(new Point(0, 0), "center", "center");
      } else if (alignment === "left") {
        shape.setPositionByOrigin(new Point(-group.getScaledWidth() / 2 + bb.width / 2, sc.y), "center", "center");
      } else if (alignment === "right") {
        shape.setPositionByOrigin(new Point(group.getScaledWidth() / 2 - bb.width / 2, sc.y), "center", "center");
      } else if (alignment === "top") {
        shape.setPositionByOrigin(new Point(sc.x, -group.getScaledHeight() / 2 + bb.height / 2), "center", "center");
      } else if (alignment === "bottom") {
        shape.setPositionByOrigin(new Point(sc.x, group.getScaledHeight() / 2 - bb.height / 2), "center", "center");
      }
      shape.setCoords();
      group.triggerLayout();
      group.set({ dirty: true });
      group._calculateCurrentDimensions();
    }
  }

  alignObjects(alignment: ObjectsAlignment) {
    const selection = this.selectionManager.activeSelection;
    if (selection) {
      if (this.allObjectsInOneGroup(selection.getObjects())) {
        this.alignInGroup(selection.getObjects(), selection.getObjects()[0].group as Group, alignment);
      } else {
        this.alignRelativeTo(selection.getObjects(), selection, alignment);
        const objects = this.selectedObjects;
        this.selectionManager.clear();
        this.selectionManager.selectObjects(objects, true);
        selection.setCoords();
        this.stage.renderAll();
        this.stage.fire("object:modified");
      }
    } else if (this.currentObject?.shape) {
      if (this.currentObject.shape.group) {
        this.alignInGroup([this.currentObject.shape], this.currentObject.shape.group, alignment);
      } else {
        this.alignRelativeTo([this.currentObject.shape], this.coverBaseRect, alignment);
      }
      this.stage.renderAll();
      this.stage.fire("object:modified");
    }
  }

  async updateObjectsAsync(objects: ObjectShape[]) {
    for (const object of objects) {
      this.updateShapeFromObject(object.shape!, object);
    }
    this.updateCoverStates$.next();
  }

  async ungroupAsync(groupObject: GroupObject | MaskGroupObject) {
    const groupShape = (groupObject as ObjectShape).shape as Group;
    const objects = groupObject.objects;
    const shapes = objects.map((v) => (v as ObjectShape).shape!);
    const groupIndex = this.objects.indexOf(groupObject);

    if (groupIndex === -1) {
      return;
    }

    this.selectionManager.clear();

    groupShape.removeAll();
    this.stage.remove(groupShape);
    this.stage.add(...shapes);

    for (const o of objects) {
      o.opacity = ((o.opacity ?? 100) * (groupObject.opacity ?? 100)) / 100;
      if (o instanceof TextObject || o instanceof ShapeObject) {
        this.applyFill((o as ObjectShape).shape!, o.fill);
      }
    }

    for (const s of shapes) {
      s.opacity = (s.opacity * (groupObject.opacity ?? 100)) / 100;
      s.setCoords();
    }

    for (const o of objects) {
      this.updateObjectFromShape(o, (o as ObjectShape).shape!);
    }

    // find this object again as index can be different
    this.cover.objects?.splice(
      this.cover.objects.findIndex((v) => v === groupObject),
      1,
      ...objects,
    );
    this.objects.splice(groupIndex, 1, ...objects);

    this.constructGroupIds();

    this.objects.forEach((v, i) => {
      v.zIndex = i + 1;
      this.stage.moveObjectTo(v.shape!, i + 1);
    });

    this.selectionManager.selectObjects(objects, true);

    this.stage.bringObjectToFront(this.coverSafeMarginsRect);
    this.stage.bringObjectToFront(this.maskOuterRect);
    this.stage.bringObjectToFront(this.previewModeMaskRect);
    this.stage.bringObjectToFront(this.maskInnerRect);
    this.stage.renderAll();

    this.objects = [...this.objects];
    this.sidebarMode = "multiselect-settings";

    this.changeDetector.markForCheck();

    this.saveCoverState();
    await this.saveCoverAsync();
  }

  async ungroupObjectsAsync() {
    if (
      !(this.currentObject instanceof GroupObject) ||
      !(this.currentObject.shape instanceof Group) ||
      (this.currentObject.objects?.length ?? 0) === 0
    ) {
      return;
    }
    await this.ungroupAsync(this.currentObject);
  }

  async groupObjectsAsync() {
    if (this.selectedObjects.length === 0) {
      return;
    }

    const objects = this.selectedObjects;

    const topObjectIndex =
      this.selectedObjects
        .map((i) => this.objects.indexOf(i))
        .reduce((p, c) => {
          return c > p ? c : p;
        }, 0) -
      this.selectedObjects.length +
      1;

    this.objects = this.objects.filter((v) => !objects.some((i) => i === v));
    this.cover.objects = this.cover.objects?.filter((v) => !objects.some((i) => i === v));
    this.stage.remove(...objects.map((v) => v.shape!));

    const length = this.objects.length;

    this.selectionManager.clear();

    const flatObjects: ObjectShape[] = [];
    for (const o of objects) {
      if (o instanceof GroupObject) {
        const gs = o.shape as Group;
        // gs.destroy();
        this.stage.remove(gs);
        flatObjects.push(...o.objects);
        continue;
      }
      flatObjects.push(o);
    }

    const groupObject = new GroupObject();
    groupObject.objects = flatObjects;

    groupObject.id = undefined;
    groupObject.name = `Group ${length + 1}`;
    groupObject.zIndex = length + 1;

    const shapes = flatObjects.map((v) => (v as ObjectShape).shape!);

    const groupShape = new Group(shapes);
    groupShape.set("name", "object"); // https://metranpage.atlassian.net/browse/MT-1909
    groupShape.objectCaching = false;

    groupShape.subTargetCheck = true;
    // groupShape.interactive = true;

    (groupObject as ObjectShape).shape = groupShape;
    this.addBasicEventListeners(groupShape);
    SelectionManager.applySelectionStyles(groupShape);
    groupShape.controls.mtr.offsetY = -18;

    /* this.cover.objects?.push(groupObject);
    this.objects.push(groupObject); */

    this.cover.objects?.splice(topObjectIndex, 0, groupObject);
    this.objects.splice(topObjectIndex, 0, groupObject);

    this.stage.add(groupShape);

    this.objects.forEach((v, i) => {
      v.zIndex = i + 1;
      this.stage.moveObjectTo(v.shape!, i + 1);
    });

    this.stage.setActiveObject(groupShape);
    this.stage.bringObjectToFront(this.coverSafeMarginsRect);
    this.stage.bringObjectToFront(this.maskOuterRect);
    this.stage.bringObjectToFront(this.previewModeMaskRect);
    this.stage.bringObjectToFront(this.maskInnerRect);
    this.stage.renderAll();

    this.sidebarMode = "object-settings";

    this.updateObjectFromShape(groupObject, groupShape);

    for (const o of flatObjects) {
      this.updateObjectFromShape(o, o.shape!);
    }

    this.constructGroupIds();
    this.updateCoverStates$.next();
    this.changeDetector.markForCheck();
  }

  async updateGroupAsync(param: UpdateGroupParam) {
    const groupShape = (param.group as ObjectShape).shape as Group;
    const objectToAdd = param.objectToAdd as ObjectShape;
    const objectToRemove = param.objectToRemove as ObjectShape;

    if (objectToAdd?.shape) {
      this.stage.remove(objectToAdd.shape);
      groupShape.add(objectToAdd.shape);

      for (const obj of param.group.objects) {
        this.updateObjectFromShape(obj, (obj as ObjectShape).shape!);
      }
      this.updateObjectFromShape(objectToAdd, objectToAdd.shape);

      this.updateObjectFromShape(param.group as ObjectShape, groupShape);

      groupShape.set({ dirty: true });
      objectToAdd.shape.set({ dirty: true });
      objectToAdd.shape.setCoords();
      groupShape.setCoords();

      this.cover.objects = this.cover.objects?.filter((v) => v !== objectToAdd);

      this.stage.renderAll();
      this.updateCoverStates$.next();
      this.changeDetector.markForCheck();
      return;
    }

    if (objectToRemove) {
      groupShape.remove(objectToRemove.shape!);

      for (const obj of param.group.objects) {
        this.updateObjectFromShape(obj, (obj as ObjectShape).shape!);
      }
      this.updateObjectFromShape(objectToRemove, objectToRemove.shape!);

      this.updateObjectFromShape(param.group as ObjectShape, groupShape);

      this.cover.objects?.push(objectToRemove);
      this.stage.add(objectToRemove.shape!);

      if (param.group instanceof GroupObject && param.group.objects.length === 1) {
        await this.ungroupAsync(param.group);
      }

      this.updateCoverStates$.next();
      this.changeDetector.markForCheck();
      return;
    }
  }

  async createMask() {
    if (this.selectedObjects.length === 0) {
      return;
    }

    const objects = this.selectedObjects;

    const topObjectIndex =
      this.selectedObjects
        .map((i) => this.objects.indexOf(i))
        .reduce((p, c) => {
          return c > p ? c : p;
        }, 0) -
      this.selectedObjects.length +
      1;

    this.objects = this.objects.filter((v) => !objects.some((i) => i === v));
    this.cover.objects = this.cover.objects?.filter((v) => !objects.some((i) => i === v));
    this.stage.remove(...objects.map((v) => v.shape!));

    this.selectionManager.clear();

    const length = this.objects.length;

    const flatObjects: ObjectShape[] = [];
    for (const o of objects) {
      if (o instanceof GroupObject) {
        const gs = o.shape as Group;
        // gs.destroy();
        this.stage.remove(gs);
        flatObjects.push(...o.objects);
        continue;
      }
      flatObjects.push(o);
    }

    let maskIndex = 0;
    for (const o of flatObjects) {
      o.isMask = true;
      const oldName = o.name;
      o.name = $localize`:@@cover-editor.mask.mask-element:`;
      o.name += ` ${oldName}`;
      if (o.shape) {
        o.shape.set("name", `mm-mask-${maskIndex++}`);
      }
    }

    const maskObject = new MaskGroupObject();
    maskObject.objects = flatObjects;

    maskObject.id = undefined;
    maskObject.name = $localize`:@@cover-editor.mask:`;
    maskObject.name += ` ${length + 1}`;
    maskObject.zIndex = length + 1;

    const shapes = flatObjects.map((v) => (v as ObjectShape).shape!);

    await this.recolorMaskShapesToCheckers(shapes);

    const maskGroupShape = new Group(shapes);
    maskGroupShape.objectCaching = false;

    maskGroupShape.subTargetCheck = true;

    (maskObject as ObjectShape).shape = maskGroupShape;

    /* const clipShape = new Group(shapes);
    clipShape.objectCaching = false;

    clipShape.subTargetCheck = true;
    // clipShape.interactive = true; */

    // (maskObject as ObjectShape).clipShape = clipShape;
    const clone = await shapes[0].clone();
    maskGroupShape.clipPath = clone; // TODO several objects as mask?

    this.addBasicEventListeners(maskGroupShape);
    SelectionManager.applySelectionStyles(maskGroupShape);
    maskGroupShape.controls.mtr.offsetY = -18;

    this.cover.objects?.splice(topObjectIndex, 0, maskObject);
    this.objects.splice(topObjectIndex, 0, maskObject);

    this.stage.add(maskGroupShape);

    this.objects.forEach((v, i) => {
      v.zIndex = i + 1;
      this.stage.moveObjectTo(v.shape!, i + 1);
    });

    this.stage.setActiveObject(maskGroupShape);
    this.stage.bringObjectToFront(this.coverSafeMarginsRect);
    this.stage.bringObjectToFront(this.maskOuterRect);
    this.stage.bringObjectToFront(this.previewModeMaskRect);
    this.stage.bringObjectToFront(this.maskInnerRect);
    this.stage.renderAll();

    this.sidebarMode = "object-settings";

    this.updateObjectFromShape(maskObject, maskGroupShape);

    for (const o of flatObjects) {
      this.updateObjectFromShape(o, o.shape!);
    }

    this.constructGroupIds();
    this.updateCoverStates$.next();
    this.changeDetector.markForCheck();
  }

  private hideMaskLayers(shape: Group) {
    for (const child of shape.getObjects()) {
      if (child.get("name")?.includes("mm-mask")) {
        child.visible = false;
      }
    }
  }

  private showMaskLayers(shape: Group) {
    for (const child of shape.getObjects()) {
      if (child.get("name")?.includes("mm-mask")) {
        child.visible = true;
      }
    }
  }

  private applyClipPathPropsOnShapeChanged(shape: FabricObject) {
    if (shape.get("name")?.includes("mm-mask")) {
      const group = shape.group;
      if (group) {
        const index = shape.get("name").split("-")[1]; // TODO when more than one mask layer

        this.updateClipPathWithPropsFromVisibleShape(group, shape);
      }

      if (shape.fill instanceof Pattern) {
        shape.fill.patternTransform = [1 / shape.scaleX, 0, 0, 1 / shape.scaleY, 0, 0];
      }
    }
  }

  private updateClipPathWithPropsFromVisibleShape(group: Group, maskShape: FabricObject) {
    const cp = group.clipPath;
    if (cp) {
      cp.set({
        left: maskShape.left,
        top: maskShape.top,
        width: maskShape.width,
        height: maskShape.height,
        scaleX: maskShape.scaleX,
        scaleY: maskShape.scaleY,
        angle: maskShape.angle,
        skewX: maskShape.skewX,
        skewY: maskShape.skewY,
      });

      if (maskShape instanceof Textbox) {
        cp.set({
          lineHeight: maskShape.lineHeight,
          fontSize: maskShape.fontSize,
          text: maskShape.text,
        });
      }
    }
    group.set({ dirty: true });
  }

  private async recolorMaskShapesToCheckers(shapes: FabricObject[]) {
    const pattern = await FabricImage.fromURL("/assets/images/checkers.png").then((img) => {
      const patternSourceCanvas = new StaticCanvas();
      img.scaleToWidth(20);
      img.opacity = 0.5;
      patternSourceCanvas.setDimensions({
        width: img.getScaledWidth(),
        height: img.getScaledHeight(),
      });
      patternSourceCanvas.add(img);
      patternSourceCanvas.renderAll();
      const pattern = new Pattern({
        source: patternSourceCanvas.getElement(),
        repeat: "repeat",
      });
      return pattern;
    });
    for (const s of shapes) {
      s.fill = pattern;
    }
  }

  removeMask() {
    if (
      !(this.currentObject instanceof MaskGroupObject) ||
      !(this.currentObject.shape instanceof Group) ||
      (this.currentObject.objects?.length ?? 0) === 0
    ) {
      return;
    }
    this.ungroupAsync(this.currentObject);
  }

  onPreviewFontFamily(fontFamily: string) {
    if (this.currentObject instanceof TextObject && this.currentObject.shape instanceof Textbox) {
      this.currentObject.shape.set("fontFamily", fontFamily);
      this.stage.requestRenderAll();
    }
  }

  onResetFontFamily() {
    if (this.currentObject instanceof TextObject && this.currentObject.shape instanceof Textbox) {
      this.currentObject.shape.fontFamily = this.currentObject.fontFamily ?? "";
      this.stage.requestRenderAll();
    }
  }

  constructGuidelines(activeShape: FabricObject) {
    const marginsInnerRect = new Rect();
    marginsInnerRect.set({
      left: this.coverSafeMarginsRect.left! + this.coverSafeMarginsRect.strokeWidth!,
      top: this.coverSafeMarginsRect.top! + this.coverSafeMarginsRect.strokeWidth!,
      width: this.coverSafeMarginsRect.width! - this.coverSafeMarginsRect.strokeWidth!,
      height: this.coverSafeMarginsRect.height! - this.coverSafeMarginsRect.strokeWidth!,
    });
    this.snapper.calculatePotentialGuidelines([
      ...this.objects
        .map((v) => v.shape!)
        .filter(
          (v) =>
            v !== activeShape &&
            (!(activeShape instanceof ActiveSelection) || !activeShape.getObjects().some((o) => o === v)),
        ),
      this.coverBaseRect,
      marginsInnerRect,
    ]);
  }

  constructGuidelineShapes(guidelines: Guidelines) {
    if (this.guidelines.length > 0) {
      this.stage.remove(...this.guidelines);
      this.guidelines = [];
    }

    const strokeWidth = 1;
    for (const v of guidelines.v) {
      const line = new Line(
        [
          v.position - strokeWidth / 2 / this.stage.getZoom(),
          -5000,
          v.position - strokeWidth / 2 / this.stage.getZoom(),
          5000,
        ],
        {
          stroke: "#c0c0c0",
          strokeWidth: strokeWidth / this.stage.getZoom(),
          selectable: false,
        },
      );
      this.guidelines.push(line);
    }
    for (const h of guidelines.h) {
      const line = new Line(
        [
          -5000,
          h.position - strokeWidth / 2 / this.stage.getZoom(),
          5000,
          h.position - strokeWidth / 2 / this.stage.getZoom(),
        ],
        {
          stroke: "#c0c0c0",
          strokeWidth: strokeWidth / this.stage.getZoom(),
          selectable: false,
        },
      );
      this.guidelines.push(line);
    }

    this.stage.add(...this.guidelines);
  }

  snapToGuidelines(shape: FabricObject, guidelines: Guidelines) {
    const center = shape.getCenterPoint();
    const moveTo = center;
    if (guidelines.v.length > 0) {
      moveTo.x += guidelines.v[0].distance ?? 0;
    }
    if (guidelines.h.length > 0) {
      moveTo.y += guidelines.h[0].distance ?? 0;
    }
    shape.setPositionByOrigin(moveTo, "center", "center");
  }

  resetGuidelines() {
    if (this.guidelines.length > 0) {
      this.stage.remove(...this.guidelines);
      this.guidelines = [];
    }
    this.snapper.resetGuidelines();
  }

  initializeObjectShape(object: ObjectShape, shape: FabricObject, resizeImages = false, addListeners = true) {
    SelectionManager.applySelectionStyles(shape);

    if (addListeners) {
      shape.controls.mtr.offsetY = -18;
    }

    shape.strokeUniform = true;
    (shape as any).name = "object";
    shape.strokeWidth = 0;

    if (this.isGroupAlike(object) && shape instanceof Group) {
      /* const shapes = object.objects.map((v) => (v as ObjectShape).shape);
      for (const s of shapes) {
        shape.addWithUpdate(s);
      } */
    } else {
      shape.originX = "left";
      shape.originY = "top";
    }

    if (resizeImages && shape instanceof FabricImage && object instanceof ImageObject) {
      if (shape.width && shape.height && this.cover.width && this.cover.height && object.needRescale) {
        const ratio = this.rescaleToFit(shape, this.cover, "contain");
        shape.scale(ratio);
        object.scaleX = ratio;
        object.scaleY = ratio;
      }
    }

    this.updateShapeFromObject(shape, object);

    if (object instanceof ShapeObject) {
      shape.set({
        objectCaching: false,
      });

      if (addListeners && (object instanceof RectangleObject || object instanceof EllipseObject)) {
        shape.on("scaling", () => {
          if (shape instanceof Ellipse) {
            shape.set({
              rx: shape.width! / 2,
              ry: shape.height! / 2,
            });
          }
        });
      }
    }

    object.shape = shape;

    if (addListeners) {
      this.addBasicEventListeners(shape);
    }

    if (addListeners && shape instanceof Textbox && object instanceof TextObject) {
      shape.on("changed", () => {
        if (!object.isNameModifiedByUser) {
          object.name = shape.text;
        }
      });
    }
  }

  private updateUncaseText(eventText: string, objectText: string): string {
    return eventText
      .split("")
      .map((char: string, idx: number) => {
        if (!objectText[idx]) {
          return char;
        }
        return char.toLowerCase() === objectText[idx].toLowerCase() ? objectText[idx] : char;
      })
      .join("");
  }

  private subscribeOnTextChange(textObject: TextObject): void {
    this.stage.off("text:changed");
    this.stage.on("text:changed", (event) => {
      if (textObject.text && textObject.textCase && textObject.textCase !== TextCase.Auto && event.target) {
        textObject.text = this.updateUncaseText((event.target as Textbox).text!, textObject.text);
        (event.target as Textbox).set("text", this.updateCase((event.target as Textbox).text!, textObject.textCase));
        this.applyClipPathPropsOnShapeChanged(event.target as FabricObject);
        return;
      }
      textObject.text = (event.target as Textbox)?.text;
      this.applyClipPathPropsOnShapeChanged(event.target as FabricObject);
    });
  }

  addEventListeners() {
    const canvasElement = this.stage.getElement();
    canvasElement.tabIndex = 1;

    const wrapperElement = this.wrapperRef.nativeElement;
    wrapperElement.tabIndex = 2;
    wrapperElement.focus();

    /////////////////////////
    // ZOOM BY WHEEL
    wrapperElement.addEventListener("wheel", (e: WheelEvent) => {
      if (!zoomByWheel) {
        return;
      }
      e.preventDefault();
      const oldScale = this.stage.getZoom();
      const direction = (e as WheelEvent).deltaY > 0 ? -1 : 1;
      const newScale = direction > 0 ? oldScale * 1.05 : oldScale / 1.05;
      const center = this.stage.getCenter();
      this.stage.zoomToPoint(new Point({ x: center.left, y: center.top }), newScale);
      // this.constructGuidelineShapes(this.snapper.snapToGuidelines);
    });
    /////////////////////////

    const filterSelected = (e: any): ObjectShape[] => {
      return this.objects
        .flatMap((v) => {
          if (this.isGroupAlike(v)) {
            return [v as ObjectShape].concat(v.objects as ObjectShape[]);
          }
          return v;
        })
        .filter((o) => e.selected?.some((v: any) => v === o.shape));
    };
    const filterSelectedFromActive = (e: any): ObjectShape[] => {
      return this.objects
        .flatMap((v) => {
          if (this.isGroupAlike(v)) {
            return [v as ObjectShape].concat(v.objects as ObjectShape[]);
          }
          return v;
        })
        .filter((o) => this.stage.getActiveObjects().some((v: any) => v === o.shape));
    };

    this.stage.on("selection:cleared", (e: any) => {
      if (this.isEyeDropperActive && this.selectionManager.selectedObjects.length) {
        this.selectionManager.selectObjects(this.selectionManager.selectedObjects, true);
        return;
      }
      // this.resetGuidelines();
      this.selectionManager.clear();
      if (this.editingMode === "common") {
        this.panSelectedObjects = [];
      }
      this.sidebarMode = "object-create";
      this.changeDetector.detectChanges();
    });

    this.stage.on("selection:created", (e: any) => {
      const objects = filterSelected(e);

      if (objects.length === 1 && objects[0] instanceof TextObject) {
        this.subscribeOnTextChange(objects[0]);
      }
      this.selectionManager.selectObjects(objects);
      if (this.currentObject) {
        this.sidebarMode = "object-settings";
      } else if (this.selectedObjects) {
        this.sidebarMode = "multiselect-settings";
      }
      this.updateObjectPosition();
      if (this.selectionManager.activeSelection) {
        this.addBasicEventListeners(this.selectionManager.activeSelection);
      }

      const active = this.stage.getActiveObjects();
      for (const a of active) {
        if (a.group) {
          this.lastInteractiveGroup = a.group;
          a.group.set({ interactive: true });
          this.showMaskLayers(a.group);
        }
      }
      this.changeDetector.detectChanges();
    });

    this.stage.on("selection:updated", (e: any) => {
      /* const active = this.stage.getActiveObjects();
      const objects = this.objects.filter((o) => active?.some((v) => v === o.shape)); */
      const objects = filterSelectedFromActive(e);
      if (objects.length === 1 && objects[0] instanceof TextObject) {
        this.subscribeOnTextChange(objects[0]);
      }
      const addListeners = this.selectionManager.selectedObjects.length <= 1;
      this.selectionManager.selectObjects(objects);
      if (this.currentObject) {
        this.sidebarMode = "object-settings";
      } else {
        this.sidebarMode = "multiselect-settings";
      }
      this.updateObjectPosition();
      if (this.selectionManager.activeSelection && addListeners) {
        this.addBasicEventListeners(this.selectionManager.activeSelection);
      }

      this.changeDetector.detectChanges();
    });

    fromEvent(this.stage, "object:modified")
      .pipe(
        untilDestroyed(this),
        tap((j) => {
          // this.resetGuidelines();
          for (const object of this.selectionManager.selectedObjects) {
            this.updateObjectFromShape(object, object.shape!);
          }
          this.updateCoverStates$.next();
        }),
      )
      .subscribe();

    /////////////////////////
    // PAN

    this.stage.on("mouse:down", (e: any) => {
      if (this.editingMode === "pan") {
        this.panPrevPoint = e.pointer;
        this.isPanning = true;
        this.changeDetector.markForCheck();
        return;
      }
      if (this.isEyeDropperActive) {
        if (this.onEyeDroperSelected && this.eyeDropperSelectedColor) {
          this.onEyeDroperSelected(this.eyeDropperSelectedColor);
        }
        this.setEyeDropper(false);
      }
    });

    this.stage.on("mouse:up", (e: any) => {
      if (this.editingMode === "pan") {
        this.isPanning = false;
        this.changeDetector.markForCheck();
      }
    });

    this.stage.on("mouse:move", (e: TPointerEventInfo<TPointerEvent>) => {
      const event = e.e;
      if (
        this.editingMode === "pan" &&
        event instanceof MouseEvent &&
        event.buttons === 1 &&
        e.pointer &&
        this.panPrevPoint
      ) {
        const delta = new Point(e.pointer.x - this.panPrevPoint.x, e.pointer.y - this.panPrevPoint.y);
        this.stage.relativePan(delta);
        this.panPrevPoint = e.pointer;
        return;
      }
      if (this.isEyeDropperActive && (event instanceof MouseEvent || event instanceof PointerEvent)) {
        const pointer = this.stage.getPointer(e.e, true);
        this.eyeDropperSelectedColor = this.getColorByPosition(pointer.x, pointer.y);
        this.eyeDropperPosition = { x: event.clientX + 21 - 2, y: event.clientY - 21 - 18 + 2 };
        this.changeDetector.markForCheck();
      }
    });
    /////////////////////////

    document.addEventListener("keydown", async (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === "z" && (e.ctrlKey || e.metaKey)) {
        if (
          this.selectionManager.currentObject &&
          this.selectionManager.currentObject instanceof TextObject &&
          (this.selectionManager.currentObject.shape as Textbox).isEditing
        ) {
          return;
        }

        if (e.shiftKey) {
          await this.redoAsync();
        } else {
          await this.undoAsync();
        }
      } else if (e.key === "Delete" || e.key === "Backspace") {
        const selectedObjects = this.selectionManager.selectedObjects;
        if (!selectedObjects.length) {
          return;
        }
        if (this.isAnyControlBeingEdited()) {
          return;
        }
        await this.deleteObjectsAsync(selectedObjects);
      } else if (e.code === "Space" && this.editingMode !== "pan") {
        if (this.isAnyControlBeingEdited()) {
          return;
        }
        this.editingMode = "pan";
        this.stage.skipTargetFind = true;
        this.stage.selection = false;

        if (this.selectionManager.selectedObjects.length) {
          this.panSelectedObjects = this.selectionManager.selectedObjects;
          this.selectionManager.clear();
        }

        this.changeDetector.markForCheck();
      } else if (e.key === "ArrowUp" || e.key === "ArrowRight" || e.key === "ArrowDown" || e.key === "ArrowLeft") {
        const shape = this.selectionManager.activeSelection ?? this.currentObject?.shape;
        if (!shape) {
          return;
        }
        if (this.currentObject?.isLocked) {
          return;
        }
        if (this.isAnyControlBeingEdited()) {
          return;
        }
        e.preventDefault();
        const delta = e.shiftKey ? gridSize : 1;

        if (e.key === "ArrowUp") {
          shape.top! -= delta;
        }
        if (e.key === "ArrowDown") {
          shape.top! += delta;
        }
        if (e.key === "ArrowLeft") {
          shape.left! -= delta;
        }
        if (e.key === "ArrowRight") {
          shape.left! += delta;
        }
        shape.setCoords();
        this.applyClipPathPropsOnShapeChanged(shape);
        this.stage.renderAll();
        this.stage.fire("object:modified");
      }
    });

    document.addEventListener("keyup", (e: KeyboardEvent) => {
      if (e.code === "Space" && this.editingMode === "pan") {
        this.editingMode = "common";
        if (!this.isEyeDropperActive) {
          this.stage.skipTargetFind = false;
          this.stage.selection = true;
        }
        if (this.panSelectedObjects?.length) {
          this.selectionManager.selectObjects(this.panSelectedObjects, true);
        }
        this.changeDetector.markForCheck();
      }
    });

    this.coverUiService.eyedropper$.pipe(untilDestroyed(this)).subscribe((v) => this.onEyeDropper(v));
  }

  // TODO rename to addShapeEventListeners
  addBasicEventListeners(shape: FabricObject) {
    shape.on("moving", (event: TEvent<TPointerEvent>) => {
      /* this.snapper.calculateActiveShapeGuidelines(shape);
      this.constructGuidelineShapes(this.snapper.snapToGuidelines);
      this.snapToGuidelines(shape, this.snapper.snapToGuidelines); */

      if (event.e.shiftKey) {
        // Snap to gridSize value
        const posX = this.snapToGrid(shape.left!);
        const posY = this.snapToGrid(shape.top!);
        if (this.snapper.snapToGuidelines.v.length === 0) {
          shape.left = posX;
        }
        if (this.snapper.snapToGuidelines.h.length === 0) {
          shape.top = posY;
        }
      }

      this.applyClipPathPropsOnShapeChanged(shape);
    });

    shape.on("rotating", (e: BasicTransformEvent<TPointerEvent>) => {
      const event = e.e;
      // if (event instanceof TouchEvent) {
      //   return;
      // }
      if (event.shiftKey) {
        // Snap to snapAngle value
        const angle = this.snapToAngle(shape.angle!);
        shape.rotate(angle);
      }
      this.applyClipPathPropsOnShapeChanged(shape);
    });

    shape.on("scaling", (e: BasicTransformEvent<TPointerEvent>) => {
      this.applyClipPathPropsOnShapeChanged(shape);
    });

    shape.on("mousedown", () => {
      // this.constructGuidelines(shape);

      if (this.lastInteractiveGroup) {
        if (shape === this.lastInteractiveGroup || (this.lastInteractiveGroup as Group).getObjects().includes(shape)) {
          // do nothing if click inside group
        } else {
          this.hideMaskLayers(this.lastInteractiveGroup as Group);
          this.lastInteractiveGroup.set({ interactive: false });
          this.lastInteractiveGroup = undefined;
        }
      }
    });

    shape.on("modified", () => {
      this.applyClipPathPropsOnShapeChanged(shape);
    });

    if (shape instanceof Group) {
      // shape.on("mouseup", (event) => {
      //   if (event.isClick) {
      //     shape.set( { interactive: true } );
      //   }
      // });
      shape.on("mousedblclick", (event) => {
        shape.set({ interactive: true });

        this.showMaskLayers(shape);

        this.lastInteractiveGroup = shape;
        if (event.subTargets && event.subTargets.length > 0) {
          // this.selectionManager.selectObjects(event.subTargets[0], true);
          // TODO replace with something simple
          const target = event.subTargets[0];
          const group = this.objects.find((v) => v.shape === shape);
          if (group && this.isGroupAlike(group)) {
            const selected = group.objects.find((v) => (v as ObjectShape).shape === target);
            if (selected) {
              this.selectionManager.selectObjects([selected], true);
            }
          }
        }
      });
      shape.on("mouseout", (event) => {
        // mouse out triggers when cursor moves over nested object.
        // Do not reset interactive state if cursor still inside group
        // TODO set interactive to false when we select other object, and not when mouse leaves
        // if (!shape.containsPoint(event.scenePoint)) {
        //   shape.set({ interactive: false });
        //   this.hideMaskLayers(shape);
        // }
      });
    }
  }

  getColorByPosition(x: number, y: number): Color {
    const context = this.stage.getContext();
    const data = context.getImageData(x * window.devicePixelRatio, y * window.devicePixelRatio, 1, 1);
    return new Color(data.data[0], data.data[1], data.data[2], data.data[3] / 255);
  }

  isAnyControlBeingEdited(): boolean {
    const activeElement = document.activeElement;
    if (activeElement?.hasAttribute("contenteditable")) {
      return true;
    }
    if (activeElement instanceof HTMLInputElement) {
      return true;
    }

    const textBoxEditing = this.objects
      .flatMap((v) => {
        if (this.isGroupAlike(v)) {
          return v.objects as ObjectShape[];
        }
        return v as ObjectShape;
      })
      .map((v) => v.shape!)
      .filter((v) => v instanceof Textbox && v.isEditing).length;

    if (textBoxEditing) {
      return true;
    }

    return false;
  }

  resizeStage() {
    const width = this.wrapperRef.nativeElement.offsetWidth;
    const height = this.wrapperRef.nativeElement.offsetHeight;

    this.updateSidebarWidth(width);

    this.stage.setDimensions({
      width,
      height,
    });

    const ratio = (height - 48) / this.cover.height!;
    this.stage.setZoom(ratio);

    this.centerStage();
  }

  initializeStage() {
    const width = this.wrapperRef.nativeElement.offsetWidth;
    const height = this.wrapperRef.nativeElement.offsetHeight;

    config.disableStyleCopyPaste = true;
    this.stage = new Canvas(this.canvasRef.nativeElement, {
      width,
      height,
      uniformScaling: true,
      preserveObjectStacking: true,
      controlsAboveOverlay: true,
      stopContextMenu: true,
      selection: true,
      defaultCursor: "inherit",
    });

    this.selectionManager = new SelectionManager(this.stage);

    this.canvasRef.nativeElement.focus();

    const options = {
      margin: 8,
      width: 0.5,
      color: "rgb(255,0,0,0.9)",
    };
    initAligningGuidelines(this.stage, options);

    this.addEventListeners();

    if (this.isCoverJustCreated) {
      if (!this.coverConceptualGeneration) {
        this.isCoverConceptualModalVisible = true;
      }
    }
  }

  async ngAfterViewInit() {
    this.initializeStage();
    await this.onCoverAsync();

    this.resizeObserver = new ResizeObserver(() => {
      this.resizeStage();
    });

    this.resizeObserver.observe(this.wrapperRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.wrapperRef.nativeElement);
    this.sub.unsubscribe();
  }

  showTemplates() {
    this.sidebarMode = "template-list";
  }

  hideTemplates() {
    this.sidebarMode = "object-create";
  }

  showConceptualAssistantMenu() {
    this.sidebarMode = "conceptual-assistant";
  }

  hideConceptualAssistant() {
    this.sidebarMode = "object-create";
  }

  onObjectSettingsClose() {
    this.sidebarMode = "object-create";
    this.stage.discardActiveObject();
    this.stage.renderAll();
  }

  async toTemplateAsync() {
    this.loadingService.startLoading({ fullPage: true });
    await this.saveCoverFullsizeImageAsync();
    const template = await this.coverService.toTemplate(this.cover.id!);
    this.coverTemplateStore.addTemplate(template);
    this.loadingService.stopLoading();
  }

  async applyTemplateAsync(template: BookCoverTemplate) {
    this.loadingService.startLoading({ fullPage: true });
    const command = <ApplyTemplateRequestDto>{
      bookId: this.book.id,
      templateId: template.id,
    };
    this.cover = await this.coverService.applyTemplate(command);
    this.checkCoverData();
    this.saveCoverState({
      template,
    });
    await this.onCoverAsync(false, true, true);
  }

  saveCoverState(options: BookCoverStateOptions | undefined = undefined) {
    const state = new BookCoverState(this.cover, options);
    this.undoRedo.save(state);
    if (!options?.template) {
      this.coverTemplateStore.setActiveTemplate(undefined);
    }
    //TODO move renderAll
    this.stage?.renderAll();
  }

  private async _undoredoAsync(undo: boolean) {
    const state = undo ? this.undoRedo.undo() : this.undoRedo.redo();
    if (state instanceof BookCoverState) {
      this.cover.objects = state.cover.objects;
      await this.saveCoverAsync();
      await this.onCoverAsync(false, false);
      this.coverTemplateStore.setActiveTemplate(state.options?.template);
    }
  }

  async undoAsync() {
    await this._undoredoAsync(true);
  }

  async redoAsync() {
    await this._undoredoAsync(false);
  }

  setEyeDropper(set: boolean) {
    this.isEyeDropperActive = set;
    this.stage.skipTargetFind = this.isEyeDropperActive;
    if (!set) {
      this.eyeDropperSelectedColor = undefined;
    }
  }

  onEyeDropper(callback: (color: Color) => void) {
    this.setEyeDropper(true);
    this.onEyeDroperSelected = callback;
  }

  // HELPERS

  private getTextObjectsByRoles(objects: CoverObject[], roles: CoverObjectRole[]): TextObject[] {
    const result = objects
      .filter(
        (v) => v instanceof TextObject && roles.some((j) => j === v.id?.toLowerCase() || j === v.name?.toLowerCase()),
      )
      .map((v) => v as TextObject);
    return result;
  }

  snapToGrid(position: number): number {
    return gridSize ? Math.round(position / gridSize) * gridSize : position;
  }

  snapToAngle(angle: number): number {
    return shapAngle ? Math.round(angle / shapAngle) * shapAngle : angle;
  }

  async showCompletionModal() {
    this.isCompletionModalVisible = true;
    await this.saveCoverFullsizeImageAsync();
  }

  async saveCoverPreviewImageAsync() {
    console.log("saving preview image ...");
    const scaleRatio = this.rescaleToFit(this.cover, { width: 190, height: 270 }, "contain");
    const blob = this.getCoverImageBlob(scaleRatio * coverPreviewImageMultiplier);
    await this.uploadCoverImageAsync(blob, false);
    console.log("preview image saved");
  }

  async saveCoverFullsizeImageAsync() {
    this.loadingService.startLoading({ fullPage: true });
    try {
      const blob = this.getCoverImageBlob(coverFullsizeImageMultiplier);
      await this.uploadCoverImageAsync(blob, true);
    } catch (e) {
      console.error(e);
      this.notificationService.error($localize`:@@cover-editor.cover.error-on-save:`);
    } finally {
      this.loadingService.stopLoading();
    }
  }

  closeCompletionModal() {
    this.isCompletionModalVisible = false;
  }

  closeImageSelectionModal() {
    this.isImageSelectionModalVisible = false;
    this.onboardingService.onStartOnboarding(false);
    this.changeDetector.markForCheck();
  }

  closeCoverConceptualModal() {
    this.isCoverConceptualModalVisible = false;
    this.changeDetector.markForCheck();
  }

  async downloadCoverImage() {
    const blob = this.getCoverImageBlob(coverFullsizeImageMultiplier);
    DataHelper.saveAsFile(blob, `book-${this.book.id}-${this.book.title}-cover.png`, "image/png");
  }

  private rescaleToFit(
    src: ObjectSize | BookCover | CoverObject | FabricObject,
    dst: ObjectSize | BookCover | CoverObject | FabricObject,
    mode: RescaleFitMode,
  ): number {
    if (!src.width || !src.height || !dst.width || !dst.height) {
      return 1;
    }
    const scaleRatioX = dst.width / src.width;
    const scaleRatioY = dst.height / src.height;
    if (mode === "horizontally") {
      return scaleRatioX;
    }
    if (mode === "vertically") {
      return scaleRatioY;
    }
    return mode === "contain" ? Math.min(scaleRatioX, scaleRatioY) : Math.max(scaleRatioX, scaleRatioY);
  }

  protected onBackClick() {
    this.back.emit();
  }

  protected async onApplyFontsAndColorsAsync(data: FontsWithColorData) {
    if (!this.cover.objects) {
      return;
    }
    let applied = false;
    if (data.main) {
      const objects = this.getTextObjectsByRoles(this.cover.objects, ["title"]);
      const rgb = data.main.color ? this.colorConverter.hex2rgb(data.main.color) : undefined;
      for (const o of objects) {
        o.fontFamily = data.main.font;
        if (rgb) {
          o.fill = new SolidFill();
          (o.fill as SolidFill).color = new Color(rgb.r, rgb.g, rgb.b, 1);
        }
        applied = true;
        this.updateShapeFromObject((o as ObjectShape).shape!, o);
      }
    }
    if (data.sub) {
      const objects = this.getTextObjectsByRoles(this.cover.objects, ["subtitle"]);
      const rgb = data.sub.color ? this.colorConverter.hex2rgb(data.sub.color) : undefined;
      for (const o of objects) {
        o.fontFamily = data.sub.font;
        if (rgb) {
          o.fill = new SolidFill();
          (o.fill as SolidFill).color = new Color(rgb.r, rgb.g, rgb.b, 1);
        }
        applied = true;
        this.updateShapeFromObject((o as ObjectShape).shape!, o);
      }
    }
    if (data.sec) {
      const objects = this.getTextObjectsByRoles(this.cover.objects, ["author"]);
      const rgb = data.sec.color ? this.colorConverter.hex2rgb(data.sec.color) : undefined;
      for (const o of objects) {
        o.fontFamily = data.sec.font;
        if (rgb) {
          o.fill = new SolidFill();
          (o.fill as SolidFill).color = new Color(rgb.r, rgb.g, rgb.b, 1);
        }
        applied = true;
        this.updateShapeFromObject((o as ObjectShape).shape!, o);
      }
    }

    if (applied) {
      this.updateCoverStates$.next();
    }
  }

  protected onCreateImageGeneration(data: ImageGeneration) {
    this.publishedImageStore.setPublishedImageSettings(data);
    this.isImageGeneratorVisible = true;
    this.changeDetector.markForCheck();
  }

  protected async onGenerateCoverConceptualAsync(data: CoverConceptualGenerationDataDto) {
    this.notificationService.closeAll();

    this.closeCoverConceptualModal();
    this.isCoverConceptualGenerationStarted = true;
    this.sidebarMode = "conceptual-assistant";
    this.changeDetector.markForCheck();

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.textGenerationService.coverConceptualGeneration(data);
    this.loadingService.stopLoading();

    if (result !== "success") {
      this.textGenerationService.notifyOnTextGenerationError();
    }

    this.isCoverConceptualGenerationStarted = false;
    this.changeDetector.markForCheck();
  }

  showImageGenerator() {
    this.isImageGeneratorVisible = true;
  }

  hideImageGenerator() {
    this.isImageGeneratorVisible = false;
    this.changeDetector.markForCheck();
  }

  async selectGeneratedImageAsync(selectImageData: SelectGeneratedImageData) {
    this.loadingService.startLoading({ fullPage: true });
    this.isImageGeneratorVisible = false;
    const result = await this.coverService.uploadGeneratedObjectImage(<UploadGeneratedImageRequestDto>{
      bookId: this.book.id,
      generationId: selectImageData.generationId,
      src: selectImageData.imageUrl,
    });
    this.loadingService.stopLoading();
    await this.createObjectAsync({ type: CoverObjectType.Image, imageName: result.name });
    this.closeImageSelectionModal();
  }

  protected async onShowShareCoverModalAsync() {
    this.isShareModalVisible = true;
    await this.saveCoverFullsizeImageAsync();
  }

  protected onCloseShareCoverModal() {
    this.isShareModalVisible = false;
  }

  protected async onPublishCoverClickAsync() {
    await this.saveCoverFullsizeImageAsync();
    await this.coverService.publishBookCover(this.cover.id);
    this.cover.isPublic = true;

    this.notificationService.notify({
      content: $localize`:@@cover-editor.share.cover-published-notification:`,
      type: "success",
    });
    this.onCloseShareCoverModal();
    this.changeDetector.markForCheck();
  }

  loadLastCoverConceptualGeneration() {
    this.textGenerationService.loadLastCoverConceptualGeneration(this.cover.id);
  }

  async onRemoveBackgroundAsync(imageObject: ImageObject) {
    if (!imageObject.imageUrl) {
      return;
    }

    const isEnoughtTokens = this.fabulaImageGenerationService.isEnoughtTokens(this.prices, this.balance, "nobg");

    if (!isEnoughtTokens) {
      await this.calculatePaymentData("nobg");
      return;
    }

    const data: FabulaRemoveBackgroundDataDto = {
      imageUrl: imageObject.imageUrl,
      bookId: this.book.id,
    };

    this.loadingService.startLoading({ fullPage: true });

    const result = await this.fabulaImageGenerationService.removeBackground(data);
    if (result.status !== "success") {
      this.loadingService.stopLoading();
      this.notifyOnImageGenerationError();
    }
    this.processingImageObject = imageObject;
    this.processingImageGenenerationId = result.generationId;

    this.onProccesingData(true);
  }

  private async calculatePaymentData(mode: FabulGenerationMode) {
    let price = 0;
    if (this.prices && mode === "nobg") {
      price = this.prices.fabulaRemoveBackground;
    }

    this.imageGenerationPaymentData = {
      price: price,
      userBalance: this.balance,
    };

    this.imageGenerationMode = mode;
    this.isLowBalanceModalVisible = true;
  }

  async processGeneratedImagesAsync(images: FabulaGeneratedImage[]) {
    for (const image of images) {
      await this.createObjectAfterRemoveBackgroundAsync(image.imageUrl);
    }

    // TODO back after made async
    // if (this.processingImageObject?.isVisible !== undefined) {
    //   this.processingImageObject.isVisible = false;
    //   // this.updateObject(this.processingImageObject);
    //   this.updateShapeFromObject((this.processingImageObject as ObjectShape).shape!, this.processingImageObject);
    // }

    // this.processingImageObject = undefined;
    // this.processingImageGenenerationId = undefined;

    // this.onProccesingData(false);

    // this.loadingService.stopLoading();
  }

  private async createObjectAfterRemoveBackgroundAsync(imageUrl: string) {
    await this.createObjectAsync({ type: CoverObjectType.Image, imageName: imageUrl }, false);

    let o = this.cover.objects?.find((o) => o instanceof ImageObject && o.imageUrl === imageUrl) as ImageObject;
    if (o) {
      const name = this.processingImageObject?.name ? this.processingImageObject.name : o.name;
      o.name = `${name} - ${$localize`:@@cover-editor.image.removed-background.text:`}`;
      o.x = this.processingImageObject?.x;
      o.y = this.processingImageObject?.y;
      // o.scaleX = this.processingImageObject?.scaleX;
      // o.scaleY = this.processingImageObject?.scaleY;
      o.rotationAngle = this.processingImageObject?.rotationAngle;
      o.opacity = this.processingImageObject?.opacity;
      o.skewX = this.processingImageObject?.skewX;
      o.skewY = this.processingImageObject?.skewY;
      o.isBackgroundRemoved = true;
    }

    if (this.processingImageObject?.isVisible !== undefined) {
      // TODO move after made async
      this.processingImageObject.isVisible = false;
      await this.updateObjectAsync(this.processingImageObject);
    }

    this.loadingService.startLoading({ fullPage: true });

    if (!this.processingImageObject) {
      return;
    }

    o = this.objects?.find((o) => o instanceof ImageObject && o.imageUrl === imageUrl) as ImageObject;
    if (o) {
      const indexProcessingImageObject = this.objects?.indexOf(this.processingImageObject);
      const indexCreatedObject = this.objects?.indexOf(o);

      moveItemInArray(this.objects, indexCreatedObject, indexProcessingImageObject + 1);

      o.scaleX = this.processingImageObject?.scaleX;
      o.scaleY = this.processingImageObject?.scaleY;

      if (o.width && this.processingImageObject.width && o.width !== this.processingImageObject.width) {
        const c = this.processingImageObject.width / o.width;
        o.scaleX = (o.scaleX || 1) * c;
      }
      if (o.height && this.processingImageObject.height && o.height !== this.processingImageObject.height) {
        const c = this.processingImageObject.height / o.height;
        o.scaleY = (o.scaleY || 1) * c;
      }

      await this.reorderObjectsAsync(this.objects);
      this.updateShapeFromObject((o as ObjectShape).shape!, o);
      this.objects = this.objects.slice();
      this.changeDetector.detectChanges();
    }

    // TODO move after made async
    this.processingImageObject = undefined;
    this.processingImageGenenerationId = undefined;

    //await this.saveCoverAsync();
    this.updateCoverStates$.next();

    this.onProccesingData(false);

    this.loadingService.stopLoading();
  }

  notifyOnImageGenerationError(generationMode: FabulGenerationMode | undefined = undefined) {
    switch (generationMode) {
      case "nobg":
        this.notificationService.error($localize`:@@image-generation.generation.variant-image.nobg.error:`);
        break;
      // case "upscale":
      // this.notificationService.error($localize`:@@image-generation.generation.variant-image.upscale.error:`);
      // break;
      // case "unzoom":
      //   this.notificationService.error($localize`:@@image-generation.generation.variant-image.unzoom.error:`);
      //   break;
      default:
        this.notificationService.error($localize`:@@image-generation.generation.error:`);
        break;
    }
  }

  protected async loadImageGenerationPricesAsync() {
    this.prices = await this.imageGenerationService.loadPrices();
  }

  protected async getHigherTariffAsync() {
    this.higherTariff = await this.pricingService.getHigherTariff();
  }

  protected closePricingModal() {
    this.isLowBalanceModalVisible = false;
  }

  protected onBuySubscription(tariff: Tariff) {
    this.closePricingModal();
    window.open(`payments/await-payment-link?tariffId=${tariff.id}`, "_blank");
  }

  onProccesingData(isProcessing: boolean) {
    this.processing.emit(isProcessing);
  }

  protected getSubscribeToTelegramChannelReward() {
    return this.rewardsService.getSubscribeToTelegramChannelReward(this.rewardsOneTime);
  }

  updatePosition(updatedPosition: ObjectPosition): void {
    const aSelection = this.stage.getActiveObject();
    if (aSelection) {
      Object.keys(updatedPosition).map((param) => {
        if (param === "x") {
          aSelection.setX(+updatedPosition[param]!);
        }
        if (param === "y") {
          aSelection.setY(+updatedPosition[param]!);
        }
        if (param === "rotationAngle") {
          aSelection.rotate(+updatedPosition[param]!);
        }
        if (param === "scale") {
          aSelection.scale(+updatedPosition[param]! / 100);
        }
      });

      this.applyClipPathPropsOnShapeChanged(aSelection);
    }
    aSelection?.setCoords();
    this.stage.renderAll();
    this.stage.fire("object:modified");
  }

  private updateObjectPosition(): void {
    const selectionFrame = this.stage.getActiveObject();
    if (selectionFrame) {
      this.objectPosition = {
        x: Math.round(selectionFrame.getX()),
        y: Math.round(selectionFrame.getY()),
        rotationAngle: Math.round(selectionFrame.angle),
        scale: Math.round(selectionFrame.scaleX * 100),
      };
    }

    return;
  }

  private async getCoverSnippetObject(coverSnippet: CoverSnippet | undefined, length: number) {
    if (!coverSnippet || !coverSnippet?.objects || coverSnippet?.objects.length === 0) {
      return;
    }

    const categoryTitle = this.coverSnippetDataService.getCoverSnippetCategoryTitle(coverSnippet?.category);

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.coverSnippetService.applyCoverSnippet(coverSnippet.id, this.book.id);
    this.loadingService.stopLoading();
    if (result !== "success") {
      this.notificationService.error($localize`:@@cover-editor.create-snippet-modal.create.error.notification:`);
      return;
    }

    const copyObjects = this.recursiveOmitShapes(coverSnippet.objects);
    const containerObjects = plainToInstance(CoverObjectContainer, { objects: copyObjects });

    const objects = containerObjects.objects || [];

    for (const o of objects) {
      if (o instanceof ImageObject) {
        o.needRescale = false;
      }
    }

    const firstObject = objects[0];

    if (objects.length === 1) {
      const coverObjectType = this.coverSnippetDataService.getCoverSnippetObjectTypeTitle(firstObject.getDefaultName());

      const newObject = firstObject;
      newObject.id = undefined;
      newObject.name = `${coverObjectType} - ${length}`;
      newObject.x = 10;
      newObject.y = 20;
      newObject.zIndex = length + 1;

      if (newObject instanceof ImageObject) {
        newObject.needRescale = false;
      }

      return newObject;
    }

    const newObject = new GroupObject();
    newObject.id = undefined;
    newObject.name = `${$localize`:@@cover-editor.cover-snippet.default.snippet.title:`} - ${categoryTitle} - ${length}`;
    newObject.x = 10;
    newObject.y = 20;
    newObject.zIndex = length + 1;

    newObject.objects = objects;

    return newObject;
  }

  private recolorSnippetObjects(objects: CoverObject[], color: Color) {
    for (const o of objects) {
      if (
        o instanceof RectangleObject ||
        o instanceof EllipseObject ||
        o instanceof SvgObject ||
        o instanceof TextObject
      ) {
        this.recolorSnippetObject(o, color);
      }
    }
  }

  private recolorSnippetObject(object: CoverObject, color: Color) {
    if (
      object instanceof RectangleObject ||
      object instanceof EllipseObject ||
      object instanceof SvgObject ||
      object instanceof TextObject
    ) {
      object.fill = new SolidFill();
      (object.fill as SolidFill).color = color;
      this.updateShapeFromObject((object as ObjectShape).shape!, object);
    }
  }

  private updateSidebarWidth(width: number) {
    this.coverSnippetSidebarMaxWidth = width;
    this.changeDetector.markForCheck();
  }

  private proccessCoverSnippets(coverSnippets: CoverSnippet[]) {
    this.coverSnippetCategoryObjects = this.coverSnippetService.sortByCategory(coverSnippets);
    this.changeDetector.markForCheck();
  }

  async onCreateCoverSnippetClick(objects: CoverObject | CoverObject[]) {
    if (Array.isArray(objects)) {
      this.coverSnippetObjectsForCreate = [...objects];
    } else {
      this.coverSnippetObjectsForCreate = [objects];
    }
    this.showCoverSnippetPublishingModal();
  }

  async createCoverSnippet(data: CoverSnippetCreateMetaData, newObjectsPreviewColor: Color | undefined = undefined) {
    const copyObjects = this.recursiveOmitShapes(this.coverSnippetObjectsForCreate);
    const containerObjects = plainToInstance(CoverObjectContainer, { objects: copyObjects });

    const objects = containerObjects.objects || [];
    for (const o of objects) {
      if (o instanceof ImageObject) {
        o.needRescale = false;
      }
    }

    const createCoverSnippetData: CoverSnippetCreateDataDto = {
      ...data,
      objects: objects,
      bookId: this.book.id,
    };

    this.loadingService.startLoading({ fullPage: true });
    const imageFile = await this.saveSnippetObjectsToImage(objects, newObjectsPreviewColor);

    // if (objects.length === 1) {
    //   const firstObject = objects[0];
    //   const result = await this.saveImageToFile(firstObject);
    //   if (result) {
    //     imageFile = result;
    //   }
    // }

    const result = await this.coverSnippetService.createCoverSnippet(createCoverSnippetData, imageFile);
    this.loadingService.stopLoading();

    if (result !== "success") {
      this.notificationService.error($localize`:@@cover-editor.create-snippet-modal.create.error.notification:`);
      return;
    }

    this.closeCoverSnippetPublishingModal();
  }

  async saveImageToFile(object: CoverObject) {
    let imageUrl = "";
    if (object instanceof RectangleObject) {
      imageUrl = "/assets/icons/cover/svg-shapes/rectangle-i-01.svg";
    } else if (object instanceof EllipseObject) {
      imageUrl = "/assets/icons/cover/svg-shapes/ellipse-i-01.svg";
    } else if (object instanceof SvgObject) {
      imageUrl = object.imageUrl || "";
    } else {
      return;
    }

    return this.getImageFile(imageUrl);
  }

  async getImageFile(url: string): Promise<File> {
    const ext = url.split(".").pop();
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => DataHelper.blobToFile(blob, `snippet-preview.${ext}`));
  }

  async showCoverSnippetPublishingModal() {
    this.isCoverSnippetPublishingModalVisible = true;
    this.changeDetector.markForCheck();
  }

  async closeCoverSnippetPublishingModal() {
    this.isCoverSnippetPublishingModalVisible = false;
    this.changeDetector.markForCheck();
  }

  recursiveOmitShapes(objects: any[]) {
    return objects.map((o) => {
      const clone = _.cloneDeep(o);
      if (clone.objects) {
        clone.objects = this.recursiveOmitShapes(clone.objects);
      }
      return _.omit(clone, "shape");
    });
  }

  async saveSnippetObjectsToImage(objects: ObjectShape[], newObjectsPreviewColor: Color | undefined = undefined) {
    const copyObjects = this.recursiveOmitShapes(objects);
    const containerObjects = plainToInstance(CoverObjectContainer, { objects: copyObjects });
    const v = await this.createObjectShapesAsync(containerObjects.objects || [], false, false);

    for (const o of v.objects) {
      if (o?.shape?.get("dynamicMinWidth")) {
        o.shape.set("width", o.shape.get("dynamicMinWidth"));
      }
    }

    if (newObjectsPreviewColor) {
      this.recolorSnippetObjects(v.objects, newObjectsPreviewColor);
    }

    const x1 = Math.min(...v.objects.map((o) => o.shape!.left));
    const y1 = Math.min(...v.objects.map((o) => o.shape!.top));
    const x2 = Math.max(...v.objects.map((o) => o.shape!.left + o.shape!.width * o.shape!.scaleX));
    const y2 = Math.max(...v.objects.map((o) => o.shape!.top + o.shape!.height * o.shape!.scaleY));

    const width = x2 - x1;
    const height = y2 - y1;

    let stage: Canvas | undefined = this.coverSnippetCreateService.createCanvas(width, height);

    let sortedObjects = v.objects.sort((a, b) => a.zIndex - b.zIndex);
    sortedObjects = sortedObjects.map((o) => {
      o.shape!.left -= x1;
      o.shape!.top -= y1;
      return o;
    });
    stage.add(...sortedObjects.map((v) => v.shape!));
    stage.renderAll();

    const base64 = this.saveStageToBase64(stage, 5, undefined, undefined, undefined, undefined, 1, "png");
    const blob = DataHelper.base64ToBlob(base64, "image/png");
    const file = DataHelper.blobToFile(blob, "snippet-preview.png");

    stage.destroy();
    stage = undefined;

    return file;
  }

  saveStageToBase64(
    stage: Canvas,
    multiplier: number,
    left: number | undefined = undefined,
    top: number | undefined = undefined,
    width: number | undefined = undefined,
    height: number | undefined = undefined,
    quality: number | undefined = undefined,
    format: ImageFormat | undefined = undefined,
  ) {
    return stage.toDataURL({
      multiplier,
      quality,
      format,
      left,
      top,
      width,
      height,
    });
  }

  protected onScrollPositionChange(coverSnippetScrollPositionState: CoverSnippetScrollPositionState) {
    this.coverSnippetScrollPositionState = coverSnippetScrollPositionState;
  }

  //// Migrations old shapes
  protected async migrateObjectsToSnippets() {
    await this.migrateBaseTextObjectsToSnippets();
    await this.migratePredefinedSvgObjectsToSnippets();
  }

  private async migrateBaseTextObjectsToSnippets() {
    await this.deleteObjectsAsync(this.objects);
    await this.createObjectAsync(
      {
        type: CoverObjectType.Text,
      },
      true,
    );

    const textObject = this.objects.find((o) => o instanceof TextObject);
    if (textObject) {
      textObject.text = $localize`:@@cover-editor.cover-snippet.default.text.title:`;
    }

    if (textObject?.shape) {
      textObject.width = textObject.shape.get("dynamicMinWidth");
    }

    await this.migrateAsSnippet("text", "headersAndText", new Color(255, 255, 255, 1));
  }

  private async migratePredefinedSvgObjectsToSnippets() {
    await this.deleteObjectsAsync(this.objects);

    for (const po of this.predefinedSvgObjects) {
      let subcategory: CoverSnippetSubcategory = "basicShapes";
      if (po.category === 2) {
        subcategory = "ageConstraints";
      }
      if (po.category === 3) {
        subcategory = "arrows";
      }
      if (po.category === 4) {
        subcategory = "splashes";
      }

      if (subcategory === "basicShapes") {
        await this.createObjectAsync(
          {
            type: CoverObjectType.Rectangle,
          },
          true,
        );
        await this.createObjectAsync(
          {
            type: CoverObjectType.Ellipse,
          },
          true,
        );
      }

      for (const svg of po.urls) {
        await this.createObjectAsync(
          {
            type: CoverObjectType.SVG,
            svgData: svg.objectUrl,
          },
          true,
        );
      }

      await this.migrateAsSnippet("objects", subcategory);
    }
  }

  private async migrateAsSnippet(
    category: CoverSnippetCategory,
    subcategory: CoverSnippetSubcategory,
    newObjectsPreviewColor: Color | undefined = undefined,
  ) {
    for (const o of this.objects) {
      this.coverSnippetObjectsForCreate = [o];

      await this.createCoverSnippet(
        {
          category: category,
          subcategory: subcategory,
          isPremium: false,
          isVisibleToOtherCompanies: false,
          isVisibleToUsers: true,
          order: 0,
        },
        newObjectsPreviewColor,
      );
    }

    await this.deleteObjectsAsync(this.objects);
  }

  //// Test cover concept
  protected async generateCoverConceptTest() {
    const titleObject = this.objects.find((o) => o instanceof TextObject && o.id === "title") as TextObject;
    const authorObject = this.objects.find((o) => o instanceof TextObject && o.id === "author") as TextObject;
    const imageObject = this.objects.find((o) => o instanceof ImageObject) as ImageObject;

    const formData = this.coverConceptualFormService.getCoverConceptualStep2FormData();

    const data = {
      bookId: this.book.id,
      coverId: this.cover.id,
      title: titleObject?.text || "",
      author: authorObject?.text || "",
      genre: formData.genre || "",
      imageUrl: imageObject?.imageUrl || "",
      coverSize: {
        width: this.cover.width,
        height: this.cover.height,
      },
    };

    if (!data.imageUrl) {
      console.log("Can't start generate concept. No image data");
      return;
    }

    console.log("Start generate concept with data:", data);

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.textGenerationService.coverConceptualGenerationStep2(data);
    // this.loadingService.stopLoading();

    if (result !== "success") {
      this.loadingService.stopLoading();
      this.textGenerationService.notifyOnTextGenerationError();
    }
  }

  protected async onCoverConceptualGenerationStep2Change(
    coverConceptualGenerationStep2: CoverConceptualGenerationStep2,
  ) {
    console.log("Concept data:", coverConceptualGenerationStep2.resultData);

    this.loadingService.startLoading({ fullPage: true });

    const titleObject = this.objects.find((o) => o instanceof TextObject && o.id === "title") as TextObject;
    const authorObject = this.objects.find((o) => o instanceof TextObject && o.id === "author") as TextObject;
    const imageObject = this.objects.find((o) => o instanceof ImageObject) as ObjectShape;

    const resultData = coverConceptualGenerationStep2.resultData;

    await this.drawSafeZones(resultData.picData);

    if (imageObject) {
      await this.updateImageObject(imageObject, resultData.picSize);
    }

    if (titleObject) {
      // await this.deleteObjectsAsync([titleObject])
      titleObject.isVisible = false;

      await this.updateObjectAsync(titleObject);
    }

    for (const [i, titlePart] of resultData.titleParts.entries()) {
      await this.createTextObject(titlePart, "title", `Title - ${i + 1}`);
    }

    if (resultData.subtitle.text) {
      await this.createTextObject(resultData.subtitle, "subtitle", `Subtitle - ${length}`);
    }

    if (authorObject) {
      await this.updateTextObject(authorObject, resultData.author);
    }

    this.stage.renderAll();

    this.loadingService.stopLoading();

    this.changeDetector.markForCheck();
  }

  async createTextObject(data: TitlePart, id: string, name: string) {
    const textObject = new TextObject();
    textObject.id = id;
    textObject.name = name;
    textObject.x = data.x;
    textObject.y = data.y;
    textObject.rotationAngle = data.rotate;
    textObject.text = data.text;
    textObject.fontFamily = data.font;
    textObject.fontSize = data.fontSize;
    textObject.letterSpacing = data.fontTracking;
    textObject.lineHeight = data.fontLeading;
    textObject.textAlign = data.fontAlign;
    textObject.underline = data.isFontUnderlined || false;
    textObject.bold = data.isFontBold || false;
    textObject.italic = data.isFontItalic || false;
    textObject.zIndex = length + 1;

    if (data.fontReg === "caps") {
      textObject.textCase = TextCase.Upper;
    } else if (data.fontReg === "small") {
      textObject.textCase = TextCase.Lower;
    } else {
      textObject.textCase = TextCase.Auto;
    }

    const rgb = this.colorConverter.hex2rgb(data.fontColor);
    if (rgb) {
      textObject.fill = new SolidFill();
      (textObject.fill as SolidFill).color = new Color(rgb.r, rgb.g, rgb.b, 1);
    }

    if (data.shadow) {
      textObject.shadow = new Shadow({
        blur: 10,
        offsetX: 20,
        offsetY: 20,
        color: "rgba(0,0,0,0.2)",
      });
    }

    this.loadingService.startLoading({ fullPage: true });
    this.cover.objects?.push(textObject);

    const v = await this.createObjectShapesAsync([textObject], true);

    this.objects.push(...v.objects);
    this.addObjectsToStage(v.objects);

    const o = v.objects[0] as ObjectShape;

    if (data.width && data.height) {
      const shape = o.shape!;
      // const scale = this.rescaleToFit(shape, { width: data.width, height: data.height }, "contain");

      const scaleRatioX = data.width / shape.width;
      // const scaleRatioY = data.height / shape.height;

      o.scaleX! = scaleRatioX;
      o.scaleY! = scaleRatioX;

      await this.updateObjectAsync(o);
    }

    if (data.fontAlign === "center") {
      this.alignRelativeTo([o.shape!], this.coverBaseRect, "center");
      this.updateObjectFromShape(textObject, o.shape!);
    }
  }

  async updateTextObject(object: TextObject, data: TitlePart) {
    object.text! = data.text;

    object.x! = data.x;
    object.y! = data.y;
    object.rotationAngle = data.rotate;

    object.fontSize = data.fontSize;
    object.fontFamily = data.font;

    object.letterSpacing = data.fontTracking;
    object.lineHeight = data.fontLeading;

    object.textAlign = data.fontAlign;

    object.underline = data.isFontUnderlined || false;
    object.bold = data.isFontBold || false;
    object.italic = data.isFontItalic || false;

    if (data.fontReg === "caps") {
      object.textCase = TextCase.Upper;
    } else if (data.fontReg === "small") {
      object.textCase = TextCase.Lower;
    } else {
      object.textCase = TextCase.Auto;
    }

    const rgb = this.colorConverter.hex2rgb(data.fontColor);
    if (rgb) {
      object.fill = new SolidFill();
      (object.fill as SolidFill).color = new Color(rgb.r, rgb.g, rgb.b, 1);
    }

    if (data.shadow) {
      object.shadow = new Shadow({
        blur: 10,
        offsetX: 20,
        offsetY: 20,
        color: "rgba(0,0,0,0.2)",
      });
    }

    await this.updateObjectAsync(object);

    if (data.fontAlign === "center") {
      const o = object as ObjectShape;
      this.alignRelativeTo([o.shape!], this.coverBaseRect, "center");
      this.updateObjectFromShape(object, o.shape!);
    }
  }

  async updateImageObject(object: ObjectShape, data: BlockSize) {
    object.x! = data.x;
    object.y! = data.y;

    const shape = (object as ObjectShape).shape!;
    // const scale = this.rescaleToFit(shape, { width: resultData.picSize.width, height: resultData.picSize.height }, "contain");

    const scaleRatioX = data.width / shape.width;
    const scaleRatioY = data.height / shape.height;

    object.scaleX! = scaleRatioX;
    object.scaleY! = scaleRatioY;

    await this.updateObjectAsync(object);
  }

  async drawSafeZones(picData: PicData) {
    const x1 = picData.safeZoneMain.topX;
    const y1 = picData.safeZoneMain.topY;
    const width1 = picData.safeZoneMain.bottomX - x1;
    const height1 = picData.safeZoneMain.bottomY - y1;
    const color1 = new Color(255, 0, 0, 0.5);
    await this.drawSafeZone(x1, y1, width1, height1, color1);

    const x2 = picData.safeZoneElements.topX;
    const y2 = picData.safeZoneElements.topY;
    const width2 = picData.safeZoneElements.bottomX - x2;
    const height2 = picData.safeZoneElements.bottomY - y2;
    const color2 = new Color(0, 255, 0, 0.5);
    await this.drawSafeZone(x2, y2, width2, height2, color2);
  }

  async drawSafeZone(x: number, y: number, width: number, height: number, color: Color) {
    const rectangleObject = new RectangleObject();
    rectangleObject.id = undefined;
    rectangleObject.name = "Safe zone";
    rectangleObject.x = x;
    rectangleObject.y = y;
    rectangleObject.width = width;
    rectangleObject.height = height;
    rectangleObject.strokeWidth = 0;
    rectangleObject.zIndex = length + 1;

    rectangleObject.strokeFill = new SolidFill();
    rectangleObject.fill = new SolidFill();
    (rectangleObject.fill as SolidFill).color = color;

    this.cover.objects?.push(rectangleObject);

    const v = await this.createObjectShapesAsync([rectangleObject], true);

    this.objects.push(...v.objects);
    this.addObjectsToStage(v.objects);
  }

  /**
   * Finds the corresponding array for this object.
   *
   * If object at root level - this.object will be returened
   *
   * If object in group - group.objects will be returned
   */
  private findContainerForObject(object: ObjectShape): ObjectShape[] | undefined {
    const container = this.objects;

    if (container.includes(object)) {
      return container;
    }

    for (let i = 0; i < container.length; i++) {
      const v = container[i];
      if (this.isGroupAlike(v)) {
        const groupContainer = v.objects;
        if (groupContainer.includes(object)) {
          return groupContainer;
        }
      }
    }

    return container;
  }

  private findObjectForShape(shape: FabricObject) {
    return this.objects.find((v) => v.shape === shape);
  }

  private isGroupAlike(object: ObjectShape) {
    return object instanceof GroupObject || object instanceof MaskGroupObject;
  }

  @HostListener("keydown.enter")
  protected handleKeyDown() {
    if (this.currentObject) {
      this.setCurrentObjects([this.currentObject]);
    }
  }
}

function clearFabricFontCache(fontFamily: string) {
  // throw new Error("Function not implemented.");
}
