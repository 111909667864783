import { Routes } from "@angular/router";
import { AuthGuard } from "@metranpage/auth";
import { ImageGenerationPage } from "./pages/image-generation/image-generation.page";
import { PreviewImageModalView } from "./views/preview-image-modal/preview-image-modal.view";

export const imageGenerationRoutes: Routes = [
  {
    path: "image-generation",
    component: ImageGenerationPage,
    canActivate: [AuthGuard],
    data: {
      showTopBar: true,
      showMobileWarning: true,
      welcomeModalVisible: true,
      hasOnboarding: true,
      // breadcrumbs: {
      //   resolver: ImageGenerationBreadcrumbsResolver,
      // },
      hideRePlain: true,
    },
    children: [],
  },
  {
    outlet: "modal",
    path: ":imageGenerationId/:generatedImageId",
    component: PreviewImageModalView,
  },
];
