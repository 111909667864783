import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { CoverObject, GroupAlike, GroupObject, MaskGroupObject } from "@metranpage/book-data";
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragMove,
  CdkDragRelease,
  CdkDragStart,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { DragDropService } from "./drag-drop.service";
import { CoverUiService } from "../../services/cover/cover-ui.service";
import { User } from "@metranpage/user-data";
import { Observable } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "m-cover-object-list",
  templateUrl: "./cover-object-list.component.html",
  styleUrls: ["./cover-object-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverObjectListComponent implements OnChanges {
  @Input() objects!: CoverObject[];
  @Input() currentObject?: CoverObject;
  @Input() selectedObjects: CoverObject[] = [];
  @Input() user!: User;
  @Input() updates!: Observable<void>;

  @Output() reorder = new EventEmitter<CoverObject[]>();
  @Output() current = new EventEmitter<CoverObject[]>();

  @ViewChild(CdkDropList) cdkDropList?: CdkDropList;

  connectedIds: string[] = [];

  isShiftPressed = false;
  reversedObjects!: CoverObject[];

  allowDropPredicate = (_drag: CdkDrag<any>, drop: CdkDropList) => {
    return this.dragDropService.isDropAllowed(_drag, drop);
  };

  constructor(
    protected readonly dragDropService: DragDropService,
    private readonly destroyRef: DestroyRef,
    private readonly coverUiService: CoverUiService,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngAfterViewInit(): void {
    this.dragDropService.register(this.cdkDropList!);
    this.updates.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.objects) {
      this.reversedObjects = this.objects.slice().reverse();
    }
  }

  onObjectSelected(object: CoverObject) {
    let objects = [...this.selectedObjects];
    if (!this.isShiftPressed) {
      this.current.emit([object]);
      return;
    }
    if (objects.some((v) => v === object)) {
      objects = objects.filter((v) => v !== object);
    } else {
      objects.push(object);
    }
    this.current.emit(objects);
  }

  isSelected(object: CoverObject): boolean {
    return this.selectedObjects.some((v) => v === object);
  }

  reorderCoverObjects(event: CdkDragDrop<CoverObject[]>) {
    if (event.previousContainer === event.container) {
      // NOTE: we use event container data, but displays reversed objects, so we use event.container.data.length - event.currentIndex
      moveItemInArray(
        event.container.data,
        event.container.data.length - event.previousIndex - 1,
        event.container.data.length - event.currentIndex - 1,
      );
      this.reorder.emit(this.objects);
      return;
    }

    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousContainer.data.length - event.previousIndex - 1,
      event.container.data.length - event.currentIndex,
    );

    // Parent -> Group
    if (event.previousContainer.id === "parent" && event.container.id !== "parent") {
      const object = event.container.data[event.container.data.length - event.currentIndex - 1];
      const group = this.objects
        .filter((v) => v instanceof GroupObject || v instanceof MaskGroupObject)
        .find((v) => `group-${(<GroupAlike>v).groupId}` === event.container.id);
      if (!group || !object) {
        return;
      }
      this.coverUiService.updateGroup({
        kind: "finalize",
        group: <GroupObject>group,
        objectToAdd: object,
      });
      this.reorder.emit(this.objects);
      return;
    }

    // Group -> Parent
    if (event.previousContainer.id !== "parent" && event.container.id === "parent") {
      const object = event.container.data[event.container.data.length - event.currentIndex - 1];
      const group = this.objects
        .filter((v) => v instanceof GroupObject || v instanceof MaskGroupObject)
        .find((v) => `group-${(<GroupAlike>v).groupId}` === event.previousContainer.id);
      if (!group || !object) {
        return;
      }
      this.coverUiService.updateGroup({
        kind: "finalize",
        group: <GroupObject>group,
        objectToRemove: object,
      });
      this.reorder.emit(this.objects);
      return;
    }

    // Group -> Group
    if (event.previousContainer.id !== "parent" && event.container.id !== "parent") {
      const object = event.container.data[event.container.data.length - event.currentIndex - 1];
      const oldGroup = this.objects
        .filter((v) => v instanceof GroupObject || v instanceof MaskGroupObject)
        .find((v) => `group-${(<GroupAlike>v).groupId}` === event.previousContainer.id);
      const newGroup = this.objects
        .filter((v) => v instanceof GroupObject || v instanceof MaskGroupObject)
        .find((v) => `group-${(<GroupAlike>v).groupId}` === event.container.id);
      if (!oldGroup || !newGroup || !object) {
        return;
      }
      this.coverUiService.updateGroup({
        kind: "finalize",
        group: <GroupObject>oldGroup,
        objectToRemove: object,
      });
      this.coverUiService.updateGroup({
        kind: "finalize",
        group: <GroupObject>newGroup,
        objectToAdd: object,
      });
      this.reorder.emit(this.objects);
      return;
    }
    //   // this._coverUiService.reorder(event);
  }

  isGroup(object: CoverObject): boolean {
    return object instanceof GroupObject;
  }

  isMask(object: CoverObject): boolean {
    return object instanceof MaskGroupObject;
  }

  trackByFn(index: number, item: CoverObject): string {
    return item.name!;
  }

  onDragStarted(event: CdkDragStart<CoverObject[]>) {
    window.document.body.style.cursor = "grabbing";
    this.dragDropService.onDragStarted(event);
  }
  onDragMoved(event: CdkDragMove<CoverObject[]>) {
    this.dragDropService.onDragMoved(event);
  }
  onDragReleased(event: CdkDragRelease) {
    window.document.body.style.cursor = "initial";
    this.dragDropService.dragReleased(event);
  }

  protected castGroup(object: CoverObject): GroupObject {
    return object as GroupObject;
  }

  protected castMask(object: CoverObject): MaskGroupObject {
    return object as MaskGroupObject;
  }

  @HostListener("window:keydown", ["$event"])
  onKeydown(event: KeyboardEvent) {
    if (event.key === "Shift") {
      this.isShiftPressed = true;
    }
  }

  @HostListener("window:keyup", ["$event"])
  onKeyup(event: KeyboardEvent) {
    if (event.key === "Shift") {
      this.isShiftPressed = false;
    }
  }
}
