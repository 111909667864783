<div class="bar-left">
  @if(!hideNavbarLogo) {
  <m-logo logo-style="full" routerLink="/" class="logo"></m-logo>
  }

  <m-breadcrumbs />
</div>

<div *ngIf="user" class="bar-right">
  <a *ngIf="user.isAdmin" routerLink="/admin/users" i18n="@@app-bar.admin-button">app-bar.admin-button</a>

  @if(!hideNavbarLogo) {
  <m-app-bar-menu
    [userBalance]="userBalance"
    [user]="user"
    [isLanguageSwitchAvailable]="isLanguageSwitchAvailable"
    (onThemeToggle)="onThemeToggle.emit()"
    (onLanguageChange)="onLanguageChange.emit($event)"
    (onLogoutClick)="onLogoutClick.emit()"
    (onPromocodeEnter)="onPromocodeEnter.emit($event)"
  />
  }
</div>

<div class="bar-right-mobile">
  @if(user?.isAdmin) {
  <a routerLink="/admin/users" i18n="@@app-bar.admin-button">app-bar.admin-button</a>
  }
  <!--  -->
  @if(!hideNavbarLogo) {
  <m-app-bar-menu-mobile
    [userBalance]="userBalance"
    [user]="user"
    [isLanguageSwitchAvailable]="isLanguageSwitchAvailable"
    (onThemeToggle)="onThemeToggle.emit()"
    (onLanguageChange)="onLanguageChange.emit($event)"
    (onLogoutClick)="onLogoutClick.emit()"
    (onPromocodeEnter)="onPromocodeEnter.emit($event)"
  />
  }
</div>
