import { CdkDrag, CdkDragDrop, CdkDragMove, CdkDragRelease, CdkDragStart, CdkDropList } from "@angular/cdk/drag-drop";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { CoverObject, GroupObject, ImageObject, MaskGroupObject, ShapeObject, TextObject } from "@metranpage/book-data";
import { DragDropService } from "../cover-object-list/drag-drop.service";
import { User } from "@metranpage/user-data";
import { CoverUiService } from "../../services/cover/cover-ui.service";
import { Observable } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "m-cover-object-list-item-mask",
  templateUrl: "./cover-object-list-item-mask.component.html",
  styleUrls: ["./cover-object-list-item-mask.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverObjectListItemMaskComponent implements OnChanges, AfterViewInit {
  @Input() object!: MaskGroupObject;
  @Input() selectedObjects!: CoverObject[];
  @Input() user!: User;
  @Input() level!: number;
  @Input() isMaskSelected = false;
  @Input() updates!: Observable<void>;

  @Output() select = new EventEmitter<CoverObject>();
  @Output() reorder = new EventEmitter<CdkDragDrop<CoverObject[]>>();

  @ViewChild(CdkDropList) cdkDropList?: CdkDropList;

  reversedObjects: CoverObject[] = [];
  hasOnlyMaskObjects = true;

  protected isEditable = false;
  protected layerName = "";

  allowDropPredicate = (_drag: CdkDrag<any>, drop: CdkDropList) => {
    return this.dragDropService.isDropAllowed(_drag, drop);
  };

  constructor(
    protected readonly dragDropService: DragDropService,
    private readonly destroyRef: DestroyRef,
    private readonly coverUiService: CoverUiService,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngAfterViewInit(): void {
    this.dragDropService.register(this.cdkDropList!);

    this.layerName = this.object.name || "";

    this.updates.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      // sometimes ngOnCHanges not working as expected
      this.reversedObjects = this.object.objects.slice().reverse();
      this.hasOnlyMaskObjects = this.checkIfHasOnlyMaskObjects();
      this.cdr.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.object) {
      this.reversedObjects = this.object.objects.slice().reverse();
      this.hasOnlyMaskObjects = this.checkIfHasOnlyMaskObjects();
      this.cdr.detectChanges();
    }
  }

  onDragStarted(event: CdkDragStart<CoverObject[]>) {
    this.dragDropService.onDragStarted(event);
  }
  onDragMoved(event: CdkDragMove<CoverObject[]>) {
    this.dragDropService.onDragMoved(event);
  }
  onDragReleased(event: CdkDragRelease) {
    this.dragDropService.dragReleased(event);
  }

  isSelected(object: CoverObject): boolean {
    return this.selectedObjects.some((v) => v === object);
  }

  onReorder(event: CdkDragDrop<CoverObject[]>) {
    this.reorder.emit(event);
  }

  trackByFn(index: number, item: CoverObject): string {
    return item.name!;
  }

  get objectTypeIconPath(): string {
    if (this.object instanceof TextObject) {
      return "/assets/icons/text-01.svg";
    }
    if (this.object instanceof ImageObject) {
      return "/assets/icons/image-04.svg";
    }
    if (this.object instanceof ShapeObject) {
      return "/assets/icons/shape-01.svg";
    }
    if (this.object instanceof GroupObject) {
      return "/assets/icons/group-01.svg";
    }
    if (this.object instanceof MaskGroupObject) {
      return "/assets/icons/mask-01.svg";
    }
    return "/assets/icons/text-01.svg";
  }

  get visibilityIcon(): string {
    if (this.object.isVisible) {
      return "/assets/icons/eye.svg";
    }
    return "/assets/icons/eye-close.svg";
  }

  get lockIcon(): string {
    return `/assets/icons/${this.object.isLocked ? "locked-01.svg" : "unlocked-01.svg"}`;
  }

  setCurrent(object: CoverObject) {
    this.select.emit(object);
  }

  onObjectClick() {
    this.setCurrent(this.object);
  }

  onObjectSelected(object: CoverObject) {
    this.select.emit(object);
  }

  validateObjectName(name: string | undefined | null): boolean {
    const value = name?.trim();
    if (value?.length) {
      return true;
    }
    return false;
  }

  enableEdit(event: MouseEvent) {
    this.isEditable = true;
    // Focus the element for immediate editing
    if (event.target && event.target instanceof HTMLElement) {
      const t = event.target as HTMLElement;
      setTimeout(() => this.moveCursorToClickEvent(t, event), 0);
    }
  }

  onNameChange() {
    // if (this.validateObjectName(this.object.name)) {
    //   this.coverUiService.renameObject(this.object);
    // }
  }

  onNameBlur() {
    this.isEditable = false;
    if (this.validateObjectName(this.object.name)) {
      this.object.name = this.layerName;
    } else {
      this.object.name = this.object.getDefaultName();
    }
    this.coverUiService.renameObject(this.object);
  }

  onNameEnter(event: Event) {
    event.preventDefault(); // Prevent default behavior for `Enter`

    this.onNameBlur();
  }

  onNameFocus() {
    this.select.emit(this.object);
  }

  moveCursorToClickEvent(element: HTMLElement, event: MouseEvent) {
    element.focus();

    const range = document.createRange();
    const selection = window.getSelection();
    const rect = element.getBoundingClientRect();

    // Calculate the click offset
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // Find the text node and position in the contenteditable element
    let node = element.firstChild;
    let offset = 0;

    if (node) {
      while (node && node.nodeType === Node.TEXT_NODE) {
        const rangeForNode = document.createRange();
        rangeForNode.selectNodeContents(node);

        const boundingBox = rangeForNode.getBoundingClientRect();

        if (x < boundingBox.right && y < boundingBox.bottom) {
          offset = Math.floor((x / boundingBox.width) * node.textContent!.length);
          break;
        }

        node = node.nextSibling;
      }
    }

    // Set the caret position if node is found
    if (node) {
      range.setStart(node, offset);
      range.collapse(true);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  }

  checkIfHasOnlyMaskObjects(): boolean {
    if (!this.reversedObjects) {
      return true;
    }
    return this.reversedObjects.every((v) => v.isMask);
  }
}
