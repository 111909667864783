import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL, FILES_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import {
  ImageGenerationCharacterReferenceData,
  ImageGenerationCharacterReferenceDataDto,
  ImageGenerationCharacterReferenceType,
} from "../../models/image-generation-character-reference/image-generation-character-reference";
import { CreateImageGenerationCharacterReferenceResponse } from "./image-generation-character-reference.service";

export type ImageGenerationCharacterReferenceFilter = {
  type?: ImageGenerationCharacterReferenceType;
};

@Injectable({
  providedIn: "root",
})
export class ImageGenerationCharacterReferenceApi extends Api {
  constructor(
    http: HttpClient,
    @Inject(BASE_URL) baseUrl: string,
    @Inject(FILES_URL) private filesUrl: string,
  ) {
    super(http, baseUrl);
  }

  async getCharacterReferencesPaginated(page: number, filters: ImageGenerationCharacterReferenceFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);
    return this.get<ImageGenerationCharacterReferenceData>("image-generation-character-reference/paginated", {
      page,
      ...clearedFilters,
    });
  }

  async createCharacterReference(data: ImageGenerationCharacterReferenceDataDto, file: File | undefined) {
    const formData: FormData = new FormData();
    if (file) {
      formData.append("file", file, file.name);
    }

    for (const [key, value] of Object.entries(data)) {
      if (typeof value !== "string") {
        formData.append(key, JSON.stringify(value));
        continue;
      }
      formData.append(key, value);
    }

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post<CreateImageGenerationCharacterReferenceResponse>(
      "image-generation-character-reference",
      formData,
      {
        headers,
      },
    );
  }

  async deleteCharacterReference(id: number) {
    return this.delete(`image-generation-character-reference/${id}`);
  }

  getUrlForFile(file: { vid: string }) {
    return `${this.filesUrl}/${file.vid}`;
  }
}
